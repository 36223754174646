import { Helmet } from "react-helmet-async"
import { Routes, Route, Navigate } from "react-router-dom"
import { useApplicationContext } from "../hooks"
import {
  CallbackLogin,
  CallbackLoginFB,
  Landing,
  Product,
  Profil,
  Horaire,
  RouletteGameRouter,
  SignIn,
  Booking,
  Subscribe
} from "../pages"
import { CallbackResponse } from "../pages/CallbackResponse1"
import { NotFound } from "../pages/NotFound"
import { PrivateRoute } from "./PrivateRoute"
import { UnderCatalogue } from "../pages/UnderCatalogue"
import { CartPage } from "../pages/Cart"
import { CommandsListPage, CommandsPage } from "../pages/Commands"
import { Tables } from "../pages/Tables"
import { BGType } from "../types"
import { ReservationRoutes } from "../pages/Booking/ReservationRoutes"
import { ReservationList } from "../pages/Booking/ReservationList"
import { AuthRoute } from "../pages/authentication/ForgotPassword/AuthRoute"
import { PaypalCode } from "../pages/paypalCode/PaypalCode"
import { useMemo } from "react"
import { Box } from "@mui/material"
import { Prize } from "../pages/Prize"
import { CommandListWizi } from "../pages/Commands/CommadListWizi"
import { BookingWizitag } from "../pages/Booking/BookingWizitag"

const PrivateRouters = (): JSX.Element => {
  const { business } = useApplicationContext()
  return (
    <Routes>
      <Route index element={<Landing />} />
      <Route path="categorieSous" element={<UnderCatalogue />} />
      <Route path="account" element={<Profil />} />
      <Route path="booking" element={<Booking />} />
      {
        business?.activeBooking && (
          <Route path="reservation/*" element={<ReservationRoutes />} />
        )
      }
      <Route path="table" element={<Tables />} />
      <Route path="product" element={<Product />} />
      <Route path="horaire" element={<Horaire />} />
      <Route path="cart" element={<CartPage />} />
      <Route path="order" element={<CommandsListPage />} />
      <Route path="order_wizitag" element={< CommandListWizi />} />
      <Route path="booking-list" element={<ReservationList />} />
      <Route path="booking-wizitag" element={<BookingWizitag />} />
      <Route path="paypal" element={<PaypalCode />} />
      <Route path="prize" element={<Prize />} />
      <Route path="subscribe" element={<Subscribe />} />
      <Route path="*" element={< CommandsListPage />} />
    </Routes>
  )
}
export const MainRouter = (): JSX.Element => {
  const { business } = useApplicationContext()

  const updateBackground = useMemo((): string => {
    if (business?.BusinessPalette?.bgType === BGType.COLOR_LINEAR) {
      const bgColor1 = business?.BusinessPalette?.bgColor1
      const bgColor2 = business?.BusinessPalette?.bgColor2
      return `linear-gradient(${bgColor1}, ${bgColor2})`
    } else if (business?.BusinessPalette?.bgType === BGType.COLOR_SINGLE) {
      const bgColor = business?.BusinessPalette?.bgColor
      return `${bgColor}`
    } else if (business?.BusinessPalette?.bgType === BGType.IMAGE) {
      const url = business.BusinessPalette.bgImage
      return `url(${url})`
    } else {
      return "transparent"
    }
  }, [business])

  return (
    <Box
      sx={{
        background: updateBackground,
        backgroundSize:
          business?.BusinessPalette?.bgType === BGType.IMAGE
            ? "cover"
            : undefined,
        backgroundRepeat:
          business?.BusinessPalette?.bgType === BGType.IMAGE
            ? "no-repeat"
            : undefined,
        backgroundPosition:
          business?.BusinessPalette?.bgType === BGType.IMAGE
            ? "center"
            : undefined
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{business?.seoTitle}</title>
        <meta name="description" content={business?.seoDescription || ""} />
      </Helmet>

      <Routes>
        <Route path="/" element={<Navigate to="/tag" replace />} />

        <Route
          path="/callback/facebook/:business/:name"
          element={<CallbackLoginFB />}
        />
        <Route
          path="/callback/google/:business/:name"
          element={<CallbackLogin />}
        />
        <Route path="/response/:user" element={<CallbackResponse />} />
        <Route path="/login" element={<SignIn />} />
        <Route path="/games/*" element={<RouletteGameRouter />} />
        <Route path="/tag/*" element={<PrivateRouters />} />
        <Route path="/auth/*" element={<AuthRoute />} />
        <Route
          path="/tag/commands"
          element={
            <PrivateRoute>
              <Routes>
                <Route path="/" element={<CommandsPage />} />
              </Routes>
            </PrivateRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Box>
  )
}
