/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Card, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import Box from "@mui/material/Box"
import { useParams } from "react-router-dom"
import { GoogleButton, Loading } from "../../components"

export const CallbackLogin = () => {
  const { business, name } = useParams()
  const [loading, setLoading] = useState<boolean>(false)
  const [dataBusiness, setDataBusiness] = useState<any>({})
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSigninGoogleSuccess = async (googleResponse: any): Promise<void> => {
    setLoading(true)
    const {
      profileObj: { email, imageUrl, givenName, familyName }
    } = googleResponse
    const data = {
      email,
      photo: imageUrl,
      firstname: givenName,
      lastname: familyName
    }
    const cryptedUri = await encodeURIComponent(JSON.stringify(data))

    await localStorage.setItem("googledata", JSON.stringify(cryptedUri))
    window.g = JSON.stringify(cryptedUri)
    window.location.replace(
      `https://${business}/response/${JSON.stringify(cryptedUri)}`
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleGoogleError = (error: any): void => {
    window.location.replace(`https://${business}/response/error`)
  }

  const onCancel = () => {
    window.location.replace(`https://${business}/login`)
  }

  useEffect(() => {
    if (name) {
      const flush = name.replace('"{', "{").replace('}"', "}")
      const infos = JSON.parse(flush)
      setDataBusiness(infos)
    }
  }, [name])

  if (loading) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: dataBusiness.photo
            ? `url(${dataBusiness.photo}) no-repeat center`
            : undefined,
          backgroundSize: "cover"
        }}
      >
        <Loading />
      </Box>
    )
  }
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: dataBusiness.photo
          ? `url(${dataBusiness.photo}) no-repeat center`
          : undefined,
        backgroundSize: "cover"
      }}
    >
      <Card sx={{ px: 4, py: 2 }}>
        <Box sx={{ textAlign: "center" }}>
          <Box sx={{ mb: 1 }}>
            <img
              src={dataBusiness.logo || ""}
              alt="chef"
              width="50"
              height="50"
              style={{ objectFit: "cover" }}
            />
          </Box>
          <Box>
            <Typography variant="h3">{dataBusiness.name}</Typography>
            <Typography>{dataBusiness.address}</Typography>
          </Box>
          <Box sx={{ p: 2, textAlign: "center" }}>
            <Typography>
              Pour continuer sur : {`https://${business}`}
            </Typography>
          </Box>
        </Box>
        <Box>
          <GoogleButton
            inCallback
            onSigninSuccess={onSigninGoogleSuccess}
            onSigninError={handleGoogleError}
          />
        </Box>
        <Box sx={{ marginTop: 2 }}>
          <Button fullWidth variant="outlined" onClick={() => onCancel()}>
            Annuler
          </Button>
        </Box>
      </Card>
    </Box>
  )
}
