import { AccessTime, CalendarMonth, Groups } from "@mui/icons-material"
import { Box, Typography } from "@mui/material"
import moment from "moment"
import { useApplicationContext } from "../../../hooks"

type InfoType = {
  name: string
  mail: string
  phone: string | number
}

type ModalProps = {
  infos: InfoType
  date?: Date | string | null
  hour?: string
  nbPrs: number
}

export const ModalBooking = ({ date, hour, nbPrs }: ModalProps) => {
  const { theme } = useApplicationContext()
  return (
    <>
      <Box
        sx={{
          my: 2,
          textAlign: "center"
        }}
      >
        <Typography
          variant="h5"
          component="span"
          sx={{ textTransform: "uppercase", textAlign: "center" }}
        >
          Votre reservation 
        </Typography>
      </Box>
      <Typography component="p" sx={{ mb: 2 }}>
        Voici un résumé de votre réservation:
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 2,
          justifyContent: "start"
        }}
      >
        <Groups sx={{ mr: 1, color: theme?.colorBtn }} />
        <Typography>{nbPrs} personnes</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 2,
          justifyContent: "start"
        }}
      >
        <CalendarMonth sx={{ mr: 1, color: theme?.colorBtn }} />
        {date ? (
          <Typography>
            {moment(date.toString()).format("DD MMM YYYY")}
          </Typography>
        ) : (
          <Typography>TSY METY E</Typography>
        )}
      </Box>
      {hour && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 2,
            justifyContent: "start"
          }}
        >
          <AccessTime sx={{ mr: 1, color: theme?.colorBtn }} />
          <Typography>{hour}</Typography>
        </Box>
      )}
    </>
  )
}
