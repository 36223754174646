import { Route, Routes } from "react-router-dom"
import { PrivateRoute } from "../../routers/PrivateRoute"
import { Events } from "./Reservation/Events"
import { LandingReservation } from "./Reservation/LandingReservation"
import { SampleReservation } from "./Reservation/SampleReservation"

export const ReservationRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route index element={<LandingReservation />} />
      <Route
        path="sample-reservation/*"
        element={
          <PrivateRoute>
            <SampleReservation />
          </PrivateRoute>
        }
      />
      <Route
        path="events/*"
        element={
          <PrivateRoute>
            <Events />
          </PrivateRoute>
        }
      />
    </Routes>
  )
}
