/* eslint-disable react-hooks/exhaustive-deps */
import { Box, styled, Typography } from "@mui/material"
import React, { FC, useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useNavigate, useParams } from "react-router-dom"
import { ContainerWithMenu } from "../../../components"
import { BaseTypography } from "../../../components/Typography/BaseTypography"
import {
  GetQrcode_getQrcode as IQrcode,
  GetQrcode_getQrcode_template as Itemplate,
  ScanQrcode_scanQrcode_lots as ILot,
  ScanQrcode_scanQrcode as IScanner
} from "../../../graphql/qrcode"
import { useApplicationContext } from "../../../hooks"
import { CardCommercant } from "../../Landing/components/CardCommercant"
import { FooterCommercant } from "../../Landing/components/FooterCommercant"
import { useStyles } from "./styles"

const Code: FC<{ code: string }> = ({ code }): JSX.Element => {
  const StyledBox = styled(Box)(({ theme }) => ({
    textAlign: "center",
    padding: "5px 15px",
    margin: "0 5px",
    mx: 1,
    p: 1,
    px: 2,
    background: "white",
    borderRadius: 2,
    "& .MuiTypography-root": {
      fontSize: "3em",
      color: theme.palette.primary.main
    }
  }))
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        mb: 3
      }}
    >
      {code.split("").map((item) => (
        <StyledBox>
          <Typography>{item}</Typography>
        </StyledBox>
      ))}
    </Box>
  )
}
export const GameWin = (): JSX.Element => {
  const { id } = useParams()
  const classes = useStyles()
  const navigate = useNavigate()
  const [code, setCode] = useState<string>("")
  const [lotWin, setLotWin] = useState<ILot>()
  const [templateData, setTemplateData] = useState<Itemplate>()
  const { business, businessLoading, theme } = useApplicationContext()
  const loadLot = () => {
    const lot = localStorage.getItem("lot")
    if (!lot) {
      navigate("/tag")
      return
    }
    const data: IScanner = JSON.parse(lot)
    const cod = data.code.toString()

    setLotWin(data.lots)
    setCode(cod)
  }

  const load = () => {
    const qrcode = localStorage.getItem("qrcode")

    if (qrcode && id) {
      const data = JSON.parse(qrcode) as IQrcode
      const template = data.template

      if (parseInt(id) === data.id) {
        setTemplateData(template || undefined)
      } else {
        localStorage.removeItem("qrcode")
        navigate("/tag")
      }
    } else {
      navigate("/tag")
    }
  }
  useEffect(() => {
    load()
    loadLot()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ContainerWithMenu
      theme={theme}
      padding={1}
      loading={businessLoading}
      noTopNav
    >
      <Helmet>
        <title>{business?.seoTitle || business?.businessName}</title>
        <meta
          name="description"
          content={business?.seoDescription || business?.description || ""}
        />
      </Helmet>
      <Box>
        {business?.id && <CardCommercant theme={theme} business={business} />}
        <Box className={classes.container}>
          <BaseTypography
            theme={theme}
            variant="h3"
            sx={{ textAlign: "center", fontWeight: 700, my: 3 }}
          >
            {templateData?.winMessageTitle}
          </BaseTypography>
          {templateData?.winPictures ? (
            <img
              src={templateData?.winPictures || ""}
              alt={templateData?.winMessageTitle || ""}
              style={{ marginBottom: 30, width: 200, height: 200 }}
            />
          ) : (
            ""
          )}
          <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
            <Code code={code} />
          </Box>
          <BaseTypography
            theme={theme}
            variant="h4"
            sx={{ textAlign: "center", fontWeight: 500 }}
          >
            {templateData?.winMessageCode}
          </BaseTypography>
          {lotWin ? (
            <Box
              sx={{
                p: 2,
                borderRadius: 5,
                my: 4
              }}
            >
              <Box>
                <img
                  src={lotWin?.image || ""}
                  alt={lotWin?.title}
                  width={150}
                  height={150}
                  style={{ objectFit: "cover" }}
                />
              </Box>
              <Box sx={{ p: 2 }}>
                <BaseTypography
                  theme={theme}
                  variant="h3"
                  sx={{ textAlign: "center", fontWeight: 700, my: 3 }}
                >
                  {lotWin?.title}
                </BaseTypography>
              </Box>
            </Box>
          ) : null}
        </Box>
      </Box>
      {business?.id && <FooterCommercant theme={theme} business={business} />}
    </ContainerWithMenu>
  )
}
