import { gql } from "@apollo/client"

export const CREATE_COMMAND = gql`
  mutation CreateCommand($data: CommandInput!) {
    createCommand(data: $data) {
      id
      email
      phone
      name
      address
      description
      status
      code
    }
  }
`
