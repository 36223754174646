import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material"
import { ReactNode } from "react"

interface TableColumnsTypes {
  label: string
  id: string | number
  align?: string
  component: (data: any) => ReactNode
}

interface BaseTableProps {
  columns: TableColumnsTypes[] | []
  data: any[]
}

export const BaseTable = ({ columns, data }: BaseTableProps) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          {columns.map((item) => (
            <TableCell
              key={item.id}
              align={
                (item.align as
                  | "left"
                  | "center"
                  | "right"
                  | "justify"
                  | "inherit"
                  | undefined) || "center"
              }
            >
              <Typography variant="h5" component="span">
                {item.label}
              </Typography>
            </TableCell>
          ))}
        </TableHead>
        <TableBody>
          {data.map((item, index) => {
            const key = `row-${index}`
            return (
              <TableRow key={key}>
                {columns.map((col, i) => {
                  const keyCell = `cell-${i}`
                  return (
                    <TableCell key={keyCell} align="right">
                      {col.component(item)}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
