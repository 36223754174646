import { CalendarMonth } from "@mui/icons-material"
import { Box, Card, CardContent, Typography } from "@mui/material"
import { FC } from "react"
import { useNavigate } from "react-router-dom"
import { DatePickerBase } from "../../../../components"
import { BaseBtn } from "../../../../components/Button/BaseBtn"
import { useApplicationContext } from "../../../../hooks"

interface DateReservationProps {
  values: Date | null
  onChangeDate: (date?: Date | null) => void
}

export const DateReservation: FC<DateReservationProps> = ({
  values,
  onChangeDate
}) => {
  const { theme } = useApplicationContext()
  const navigate = useNavigate()
  return (
    <>
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 2,
              justifyContent: "center"
            }}
          >
            <CalendarMonth sx={{ mr: 1 }} />
            <Typography>
              Séléctionner la date de votre reservation parmis celle disponible
              ci-dessous
            </Typography>
          </Box>
          <DatePickerBase
            value={values || new Date()}
            onChange={onChangeDate}
          />
        </CardContent>
      </Card>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          textAlign: "center"
        }}
      >
        <BaseBtn
          theme={theme}
          variant="contained"
          onClick={(e) => navigate(-1)}
        >
          précedent
        </BaseBtn>
        <BaseBtn
          theme={theme}
          variant="contained"
          onClick={(e) => navigate("/tag/reservation/sample-reservation/hours")}
        >
          suivant
        </BaseBtn>
      </Box>
    </>
  )
}
