import { FC, useState } from "react"
import {
  Box,
  CircularProgress,
  Grid,
  Button,
  styled,
  Typography
} from "@mui/material"
import { Helmet } from "react-helmet-async"
import * as React from "react"
import { useStyles } from "./styles"
import {
  FacebookButton,
  GoogleButton,
  LoginForm,
  InscriptionEmailForm
} from "../../../components"

import { SignInProps } from "./interfaces"
import { useApplicationContext } from "../../../hooks"
import { AuthContainer } from "../../../components/AuthContainer1"
import { BGType } from "../../../types"
import { BusinessForClient_businessForClient_BusinessPalette as IPallete } from "../../../graphql"

const StyledButton = styled(Button)(() => ({
  backgroundColor: "white",
  color: "inherit",
  borderRadius: "50px !important",
  height: 40,
  marginTop: 12,
  textTransform: "none"
}))

export const bg = (palette?: IPallete | null) => {
  switch (palette?.bgType) {
    case BGType.IMAGE:
      return {
        type: BGType.IMAGE,
        bg: palette?.bgImage
      }
    case BGType.COLOR_LINEAR:
      return {
        type: BGType.COLOR_LINEAR,
        bg: `linear-gradient(0deg, ${palette?.bgColor2}, ${palette?.bgColor1})`
      }
    case BGType.VIDEO:
      return {
        type: BGType.VIDEO,
        bg: palette?.bgViedo
      }
    default:
      return undefined
  }
}

export const Login: FC<SignInProps> = ({
  onLoginWithFacebook,
  onSigninGoogleSuccess,
  onSigninGoogleError,
  onSignin,
  onClickBtnGoogle,
  loading,
  path,
  redirection,
  onClickBtnFb
}): JSX.Element => {
  const classes = useStyles()
  const { business } = useApplicationContext()

  const palette = business?.BusinessPalette

  const [open, setOpen] = useState(false)

  const handleClick = (): void => {
    setOpen(!open)
  }

  return (
    <AuthContainer
      redirection={redirection}
      bg={bg(palette)?.bg as string}
      bgType={bg(palette)?.type}
      business={business}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{business?.seoTitle || business?.businessName}</title>
        <meta
          name="description"
          content={business?.seoDescription || business?.description || ""}
        />
      </Helmet>
      <Box sx={{ p: 2 }}>
        <Box>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              <Box sx={{ textAlign: "center" }}>
                <Box sx={{ mb: 1 }}>
                  <img
                    src={business?.logo || ""}
                    alt="chef"
                    width="120"
                    height="120"
                    style={{ objectFit: "cover" }}
                  />
                </Box>
                <Box>
                  <Typography className={classes.welcome} variant="h3">
                    {business?.businessName}
                  </Typography>
                  <Typography>{business?.address}</Typography>
                  <Box
                    sx={{
                      p: 2
                    }}
                  >
                    <Typography variant="body1">
                      {business?.description}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {open ? (
                <Box sx={{ py: 2 }}>
                  <InscriptionEmailForm
                    onClickHasAccount={handleClick}
                    path={path}
                  />
                </Box>
              ) : (
                <Grid container spacing={3} justifyContent="center">
                  <Grid item md={6}>
                    <Box sx={{ py: 2 }}>
                      <Box sx={{ mb: 2 }}>
                        <FacebookButton
                          onSigninFb={onLoginWithFacebook}
                          onClickBtn={onClickBtnFb}
                        />
                      </Box>
                      <Box>
                        <GoogleButton
                          onSigninSuccess={onSigninGoogleSuccess}
                          onSigninError={onSigninGoogleError}
                          onClickBtn={onClickBtnGoogle}
                        />
                      </Box>
                      <Box>
                        <StyledButton
                          fullWidth
                          variant="outlined"
                          color="inherit"
                          onClick={handleClick}
                        >
                          S&apos;inscrire avec Email
                        </StyledButton>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={6}>
                    <Box sx={{ py: 2 }}>
                      <LoginForm onLogin={onSignin} />
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </AuthContainer>
  )
}
