import { Box } from "@mui/material"
import { ContainerWithMenu, HeaderPage } from "../../components"
import { useApplicationContext } from "../../hooks"

export const PaypalCode = (): JSX.Element => {
  const { theme, businessLoading, business } = useApplicationContext()
  return (
    <ContainerWithMenu
      theme={theme}
      noTopNav
      padding={1}
      loading={businessLoading}
    >
      {business?.id && <HeaderPage theme={theme} business={business} />}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {business?.paypalImg && <img src={business?.paypalImg} alt="paypal" />}
      </Box>
    </ContainerWithMenu>
  )
}
