/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import Box from "@mui/material/Box"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Loading } from "../../components"
import { useApplicationContext } from "../../hooks"

// ity ilay loading mameno ecran
export const CallbackResponse = () => {
  const navigate = useNavigate()
  const { user } = useParams()

  const { signIn, dispatchSnack, business } = useApplicationContext()
  const [path, setPath] = useState<string>()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onLoginCallback = async (): Promise<void> => {
    if (!user || user === "error") {
      navigate("/login")
      return
    }

    const flush = user.replace('"{', "{").replace('}"', "}")
    const infos = JSON.parse(flush)

    if (typeof infos === "string") {
      navigate("/login")
      return
    }

    const { email, firstname, lastname, photo } = infos
    if (!email && !firstname) {
      navigate("/login")
      return
    }
    const source = localStorage.getItem("source")
    await new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve("")
      }, 500)
    })
    const login = await signIn({
      email,
      firstname,
      lastname,
      photo,
      tag: "TAG",
      source: source as any
    })
    localStorage.removeItem("source")
    if (login.success) {
      console.log("success login")
      dispatchSnack({
        open: true,
        severity: "success",
        message: "Authentification réussi !"
      })
      navigate(path || "/tag")
    }
    if (login.error) {
      dispatchSnack({
        open: true,
        severity: "error",
        message: "Action refusée . Veuillez rééssayer !"
      })
    }
  }

  useEffect(() => {
    if (business && path) {
      onLoginCallback()
    }
  }, [business, path])

  const getLocal = () => {
    const local = localStorage.getItem("redirection_path")
    if (local) {
      const auth = JSON.parse(local)
      if (auth.auth) {
        setPath(auth.path)
      } else {
        setPath("/tag")
      }
    } else {
      setPath("/tag")
    }
  }
  useEffect(() => {
    getLocal()
  }, [business])
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Loading />
    </Box>
  )
}
