/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useContext, useEffect, useState } from "react"
import { useMutation, useQuery } from "@apollo/client"
import { ApplicationContext } from "../contexts/application/ApplicationContext"
import { AuthStorage, getCurrentLoction } from "../utils"
import { UserAuthStateEnum, StatusFetch } from "./interfaces"
import { CREATE_CLIENT, LOGIN } from "../graphql"
import { SnackbarContext } from "../contexts/snackbar"
import {
  BusinessForClient,
  BusinessForClientVariables,
  GetSubscriptionList,
  GetSubscriptionListVariables,
  GET_BUSINESS,
  INFO_ABONNEMENT,
  INFO_SUBSCRIPTION,
  OneAbonnement,
  OneAbonnementVariables
} from "../graphql/business"
import {
  CreateClient,
  CreateClientVariables,
  LoginClient,
  LoginClientVariables,
  LoginClient_loginClient as IUser
} from "../graphql/user/types"
import { COUNT_VISITOR } from "../graphql/user"
import { BSType, CreateClientInput, LoginClientInput } from "../types"

export const useApplicationContext = () => {
  const contexts = useContext(ApplicationContext)
  const snackContext = useContext(SnackbarContext)
  const [userAuthStatus, setUserAuthStatus] = useState<UserAuthStateEnum>(
    UserAuthStateEnum.WAITING
  )
  const {
    setTheme,
    user,
    business,
    dispatchUser,
    setBusiness,
    setNoBusiness,
    setAbonnementCounter
  } = contexts
  const [countVisitor] = useMutation(COUNT_VISITOR)
  useEffect(() => {
    if (user?.client?.id && user.token) {
      setUserAuthStatus(UserAuthStateEnum.AUTHENTICATED)
    }
    if (!user?.client?.id || !user.token) {
      const checkuser = AuthStorage.isAuth()
      if (checkuser) {
        dispatchUser({
          type: "login",
          user: checkuser
        })
        setUserAuthStatus(UserAuthStateEnum.AUTHENTICATED)
      } else {
        setUserAuthStatus(UserAuthStateEnum.UNAUTHENTICATED)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [login, { loading: loginLoading, error: loginError }] = useMutation<
    LoginClient,
    LoginClientVariables
  >(LOGIN)

  const [signupExec, { loading: signupLoading, error: signupError }] =
    useMutation<CreateClient, CreateClientVariables>(CREATE_CLIENT)

  const signIn = async (dataInput: LoginClientInput): Promise<StatusFetch> => {
    try {
      const log = await login({
        variables: {
          businessId: business?.id as number,
          data: {
            ...dataInput
          }
        }
      })
      if (log.data) {
        setUserAuthStatus(UserAuthStateEnum.AUTHENTICATED)
        dispatchUser({
          type: "login",
          user: log.data.loginClient || ({} as IUser)
        })
        return { success: "success" }
      }
      if (log.errors) {
        return { error: loginError?.message }
      }
      return { error: loginError?.message }
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return { error: (error as any)?.graphQLErrors[0]?.message }
    }
  }
  const signup = async (dataInput: CreateClientInput): Promise<StatusFetch> => {
    const log = await signupExec({
      variables: {
        businessId: business?.id as number,
        data: {
          ...dataInput
        }
      }
    })
    if (log.data) {
      setUserAuthStatus(UserAuthStateEnum.AUTHENTICATED)
      dispatchUser({
        type: "login",
        user: log.data.createClient || ({} as IUser)
      })
      return { success: "success" }
    }
    if (log.errors) {
      return { error: loginError?.message }
    }
    return { error: "Something went wrong !" }
  }

  const url = getCurrentLoction()

  const { data, loading: businessLoading } = useQuery<
    BusinessForClient,
    BusinessForClientVariables
  >(GET_BUSINESS, {
    skip: !url || url.split(".")[0] === "login",
    variables: {
      url: url as string
    }
  })

  const { data: dataSubscription } = useQuery<
    GetSubscriptionList,
    GetSubscriptionListVariables
  >(INFO_SUBSCRIPTION, {
    variables: {
      businessId: business?.id || 0 // TODO : change to default "freemium"
    },
    skip: !business
  })

  const abonnemenInfo = dataSubscription?.getSubscriptionList[0]

  const {
    data: dataAbonnement,
    loading: loadingAbonnement,
    refetch: refetchAbonnement
  } = useQuery<OneAbonnement, OneAbonnementVariables>(INFO_ABONNEMENT, {
    variables: {
      abonnementId: abonnemenInfo?.Abonnements
        ? abonnemenInfo?.Abonnements[0]?.abonnementId
        : 0
    },
    skip: !abonnemenInfo
  })

  const setTokenVisitor = () => {
    const current = new Date().toISOString().split("T")[0]

    if (!localStorage.getItem(`visited_${business?.id}`)) {
      localStorage.setItem(`visited_${business?.id}`, current)
      countVisitor({
        variables: {
          businessId: business?.id
        }
      })

      return
    } else if (localStorage.getItem(`visited_${business?.id}`) !== current) {
      localStorage.setItem(`visited_${business?.id}`, current)
      countVisitor({
        variables: {
          businessId: business?.id
        }
      })

      return
    }
  }
  const retrieveBusiness = useCallback((): void => {
    if (data?.businessForClient?.id) {
      setBusiness(data.businessForClient)
      if (data.businessForClient.BusinessTheme?.id) {
        setTheme({
          colorBtn: data.businessForClient.BusinessTheme?.colorBtn || "#FE724C",
          colorBtnText:
            data.businessForClient.BusinessTheme?.colorBtnText || "#FFFFFF",
          colorText:
            data?.businessForClient.BusinessTheme.colorText || "#FFFFFF",
          police: data?.businessForClient.BusinessTheme.police || "Poppins",
          colorCardProduct:
            data?.businessForClient.BusinessTheme.colorCardProduct || "#FFFFFF",
          colorTextCardProduct:
            data?.businessForClient.BusinessTheme.colorTextCardProduct ||
            "#000000",
          mediaColor:
            data?.businessForClient.BusinessTheme.mediaColor || "#e63946",
          bsType:
            data?.businessForClient.BusinessTheme.bsType || BSType.CIRCULAR
        })
      }
      setNoBusiness(false)
    }
    if (!businessLoading && !data) {
      setNoBusiness(true)
    }
    if (!loadingAbonnement && dataAbonnement) {
      setAbonnementCounter(dataAbonnement?.oneAbonnement || undefined)
    }
  }, [
    businessLoading,
    data,
    dataAbonnement,
    loadingAbonnement,
    setAbonnementCounter,
    setBusiness,
    setNoBusiness,
    setTheme
  ])
  useEffect(() => {
    retrieveBusiness()
  }, [retrieveBusiness])

  useEffect(() => {
    setTokenVisitor()
  }, [setTokenVisitor])

  const logout = () => {
    localStorage.clear()
    dispatchUser({
      type: "login",
      user: {} as IUser
    })
    setUserAuthStatus(UserAuthStateEnum.UNAUTHENTICATED)
  }
  return {
    loginLoading,
    loginError,
    userAuthStatus,
    businessLoading,
    signupError,
    signupLoading,
    signIn,
    signup,
    logout,
    refetchAbonnement,
    ...contexts,

    ...snackContext
  }
}
