/* eslint-disable react/no-unescaped-entities */
import React from "react"
import { Box, Button, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useStyles } from "./styles"

export const AlreadyUsed = (): JSX.Element => {
  const navigate = useNavigate()
  const classes = useStyles()
  const handleGoRestoHome = (event: React.SyntheticEvent): void => {
    event.preventDefault()
    navigate(`/tag`)
  }

  return (
    <Box className={classes.container}>
      <Box>
        <Box className={classes.imageContainer}>
          {/* <img src={onboardingImg} alt="" /> */}
        </Box>
        <Typography variant="h3" className={classes.title} sx={{ mb: 2 }}>
          Vous avez déjà scannés ce Code QR <br /> Aujourd'hui
        </Typography>
        <Typography sx={{ textAlign: "center" }}>
          Vous pouvez scanner un code qr une fois par jour !
        </Typography>
        <Box sx={{ mt: 3, textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleGoRestoHome}
          >
            Aller au page du commerçant !
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
