import {
  Alert,
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material"
import { ChangeEvent, FC, useEffect, useMemo, useState } from "react"
import PhoneInput from "react-phone-input-2"
import { useNavigate } from "react-router-dom"
import { BaseBtn } from "../../../../components/Button/BaseBtn"
import { useApplicationContext } from "../../../../hooks"
import { useQuery } from "@apollo/client"
import {
  GetSubscriptionList,
  GetSubscriptionListVariables,
  INFO_SUBSCRIPTION
} from "../../../../graphql"

interface HoursReservationProps {
  values: string
  onChange: (e: ChangeEvent<HTMLInputElement>, value?: string) => void
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onGetEmail: (value: string) => void
}

export const HoursReservation: FC<HoursReservationProps> = ({
  onChange,
  onClick,
  onGetEmail
}) => {
  const [close, setClose] = useState(false)
  const { theme, business, abonnementCounter, dispatchSnack } =
    useApplicationContext()

  const navigate = useNavigate()
  const getPhone = (value: string, e: ChangeEvent<HTMLInputElement>) => {
    onChange(e, value)
  }
  const getEmail = (e: ChangeEvent<HTMLInputElement>) => {
    onGetEmail(e.target.value)
  }
  const [selected, isSelected] = useState<boolean>(false)
  const [error, isError] = useState<boolean>(true)
  const [email, setEmail] = useState<string>("")

  const getArrayStringHour = (
    intervalle: number,
    start?: any,
    end?: any
  ): string[] => {
    const arrayHour: string[] = []
    if (start && end) {
      const startDate = new Date(start)
      const endDate = new Date(end)
      const minuteStart = new Date(start).getMinutes()
      const minuteEnd = new Date(end).getMinutes()
      for (
        let temp = startDate.getHours(), minute = minuteStart;
        temp <= endDate.getHours();
        minute += intervalle
      ) {
        if (temp === endDate.getHours() && minute > minuteEnd) {
          break
        }
        if (minute !== 0 && minute >= 60) {
          minute = 0
          temp = temp + 1
        }
        const hour = `${temp}h${minute === 0 ? "00" : minute}`
        arrayHour.push(hour)
      }
      arrayHour[0] = `${startDate.getHours().toString()}h${
        minuteStart < 10 ? "0" + minuteStart.toString() : minuteStart.toString()
      }`

      arrayHour[arrayHour.length - 1] = `${endDate.getHours().toString()}h${
        minuteEnd === 0 ? "00" : minuteEnd.toString()
      }`
    }
    return arrayHour
  }

  const morningHours: string[] | null = useMemo(() => {
    if (business?.ReservationConfiguration) {
      return getArrayStringHour(
        business.ReservationConfiguration.minutesIntervalle,
        business.ReservationConfiguration.startHourMorning,
        business.ReservationConfiguration.endHourMorning
      )
    }
    return null
  }, [business?.ReservationConfiguration])

  const afterningHours: string[] | null = useMemo(() => {
    if (business?.ReservationConfiguration) {
      return getArrayStringHour(
        business.ReservationConfiguration.minutesIntervalle,
        business.ReservationConfiguration.startHourAfternoon,
        business.ReservationConfiguration.endHourAfternoon
      )
    }
    return null
  }, [business?.ReservationConfiguration])

  const nightHours: string[] | null = useMemo(() => {
    if (business?.ReservationConfiguration) {
      return getArrayStringHour(
        business.ReservationConfiguration.minutesIntervalle,
        business.ReservationConfiguration.startHourNight,
        business.ReservationConfiguration.endHourNight
      )
    }
    return null
  }, [business?.ReservationConfiguration])

  const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    const { value } = e.target
    const regex = /^(\+?\d{1,3}|\d{1,4})$/
    if (value === "+" || regex.test(value) || !value) {
      isError(true)
      isSelected(true)
    } else {
      isError(false)
      isSelected(false)
    }
  }
  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    getEmail(e)
    handleEmailChange(e)
  }

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!email) {
      dispatchSnack({
        open: true,
        severity: "error",
        message: "Veuillez compléter les champs obligatoires"
      })
    } else if (!error) {
      onClick(e)
    } else {
      isSelected(true)
    }
  }

  const {
    data,
    loading: loadingSubscribe,
    refetch: refetchSubscribe
  } = useQuery<GetSubscriptionList, GetSubscriptionListVariables>(
    INFO_SUBSCRIPTION,
    {
      variables: {
        businessId: business?.id || 0
      },
      skip: !business
    }
  )

  useEffect(() => {
    refetchSubscribe()

    if (data && abonnementCounter?.numberBooking) {
      if (
        data?.getSubscriptionList[0]?.Abonnements &&
        data?.getSubscriptionList[0]?.Abonnements[0].bookingCount !== null &&
        abonnementCounter?.numberBooking !== null
      ) {
        if (
          data?.getSubscriptionList[0]?.Abonnements[0].bookingCount >=
          abonnementCounter?.numberBooking
        ) {
          setClose(true)
        }
      }
    }
  }, [abonnementCounter, data, loadingSubscribe, refetchSubscribe])

  return (
    <>
      <Card sx={{ mb: 2, overflow: "visible" }}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 2,
              justifyContent: "center"
            }}
          >
            <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
              Selectionner l&apos;heure de votre reservation parmis celle
              disponible ci-dessous
            </Typography>
          </Box>
          <Box sx={{ mb: 2, textAlign: "center" }}>
            <RadioGroup
              name="hours"
              onChange={onChange}
              sx={{ display: "block" }}
            >
              {business?.ReservationConfiguration?.morning &&
                morningHours &&
                morningHours.length > 0 && (
                  <>
                    <Typography
                      variant="h5"
                      sx={{ fontWeigh: "bold", m: 1, mb: 3 }}
                    >
                      Matinée
                    </Typography>
                    <Grid container spacing={2}>
                      {morningHours.map((hour, i) => (
                        <Grid key={hour + i} item xs={4} md={2}>
                          <FormControlLabel
                            value={hour}
                            control={
                              <Radio
                                sx={{
                                  color: theme?.colorBtn + "!important"
                                }}
                              />
                            }
                            label={hour}
                            sx={{
                              border: "1px solid",
                              m: 0,
                              mb: 1,
                              borderRadius: 50,
                              width: 90
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}
              {business?.ReservationConfiguration?.afternoon &&
                afterningHours &&
                afterningHours.length > 0 && (
                  <>
                    <Typography
                      variant="h5"
                      sx={{ fontWeigh: "bold", m: 1, mb: 3 }}
                    >
                      Après-midi
                    </Typography>
                    <Grid container spacing={2}>
                      {afterningHours.map((hour, i) => (
                        <Grid item xs={4} md={2} key={hour + i}>
                          <FormControlLabel
                            value={hour}
                            control={
                              <Radio
                                sx={{
                                  color: theme?.colorBtn + "!important"
                                }}
                              />
                            }
                            label={hour}
                            sx={{
                              border: "1px solid",
                              borderRadius: 50,
                              m: 0,
                              mb: 1,
                              width: 90
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}
              {business?.ReservationConfiguration?.night &&
                nightHours &&
                nightHours.length > 0 && (
                  <>
                    <Typography
                      variant="h5"
                      sx={{ fontWeigh: "bold", m: 1, mb: 3 }}
                    >
                      Soirée
                    </Typography>
                    <Grid container spacing={2}>
                      {nightHours.map((hour, i) => (
                        <Grid item xs={4} md={2} key={hour + i}>
                          <FormControlLabel
                            value={hour}
                            control={
                              <Radio
                                sx={{
                                  color: theme?.colorBtn + "!important"
                                }}
                              />
                            }
                            label={hour}
                            sx={{
                              border: "1px solid",
                              borderRadius: 50,
                              m: 0,
                              mb: 1,
                              width: 90
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}
            </RadioGroup>
          </Box>
          <Box my={2}>
            <Typography variant="subtitle1" sx={{ textAlign: "center" }} mb={2}>
              Merci de laisser un contact rapide dans le cas d'un changement
            </Typography>
            <Box sx={{ maxWidth: 250, margin: "auto" }}>
              <Box
                sx={{
                  display: "block",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Box pr={1}>
                  Téléphone<span style={{ color: "red" }}>*</span> :
                </Box>
                <PhoneInput
                  enableSearch
                  containerStyle={{
                    width: "auto"
                  }}
                  specialLabel=""
                  country={business?.countryCode || "fr"}
                  preferredCountries={["ch", "fr"]}
                  inputStyle={{
                    backgroundColor: "#ffffff",
                    borderRadius: 1,
                    borderColor: error && selected ? "red" : undefined,
                    width: "250px",
                    height: "40px",
                    margin: "auto"
                  }}
                  onChange={getPhone}
                  onBlur={handleBlur}
                />
              </Box>
              {error && selected ? (
                <Typography sx={{ color: "red", textAlign: "center" }}>
                  ce champ est requis
                </Typography>
              ) : null}
              <Box sx={{ mt: 2 }}>
                <Box>E-mail:</Box>
                <TextField
                  value={email}
                  onChange={handleChange}
                  fullWidth
                  type="mail"
                />
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
      {!business?.ReservationConfiguration?.morning &&
      !business?.ReservationConfiguration?.afternoon &&
      !business?.ReservationConfiguration?.night ? (
        <Alert severity="info" sx={{ mb: 3 }}>
          Nos réservations sont temporairement indisponibles!
        </Alert>
      ) : (
        ""
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          textAlign: "center"
        }}
      >
        <BaseBtn
          theme={theme}
          variant="contained"
          onClick={(e) => navigate(-1)}
        >
          Précedent
        </BaseBtn>

        {!close ? (
          <BaseBtn theme={theme} variant="contained" onClick={handleClick}>
            Réserver
          </BaseBtn>
        ) : (
          ""
        )}
      </Box>
    </>
  )
}
