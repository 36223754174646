import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Container,
  Tab,
  Tabs,
  Typography
} from "@mui/material"
import { SyntheticEvent, useEffect, useState } from "react"
import { ContainerWithMenu, HeaderPage, QueryResult } from "../../components"
import { useApplicationContext } from "../../hooks"
import {
  COMMANDS_BUSINESS
} from "../../graphql/commands"
import { CommandStatusType } from "../../types"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import moment from "moment"
import { useQuery } from "@apollo/client"
import {
  getAllProduct_getAllProduct,
  GET_PRODUCT_BUSINESS,
  GET_ALL_PRODUCT
} from "../../graphql"
import { ProductListComponent } from "./components/ProductListComponent"
import { BaseTypography } from "../../components/Typography/BaseTypography"
import { useCommands } from "../../hooks/useCommands"

interface ProductType extends getAllProduct_getAllProduct {
  totalCount: number
  productPrice: number
}

export const CommandsListPage = () => {
  const [tabValue, setTabValue] = useState(1)
  const { user, business, businessLoading, theme } = useApplicationContext()
  const { commands: wiziCommands, loading: wiziLoading, refetch: wiziRefetch } = useCommands()
  const { data: commands, loading, refetch } = useQuery(COMMANDS_BUSINESS, {
    variables: {
      clientId: user?.client?.id || 0,
      businessId: business?.id
    }
  })
  const { data: allProduct } = useQuery(GET_ALL_PRODUCT)
  const { data: productList } = useQuery(GET_PRODUCT_BUSINESS, {
    variables: {
      businessId: business?.id
    },
    skip: !business?.id
  })
  const [currentProduct, setCurrentProduct] = useState<ProductType[]>([])
  const [currentWizi, setCurrentWizi] = useState<ProductType[]>([])
  const [expanded, setExpanded] = useState<number | false>(false)
  const Status = (status: CommandStatusType) => {
    switch (status) {
      case CommandStatusType.PENDING:
        return <Chip color="warning" label="En cours" />
      case CommandStatusType.VALID:
        return <Chip color="success" label="Validé" />
      case CommandStatusType.DONE:
        return <Chip color="primary" label="Fini" />
      case CommandStatusType.REJECT:
        return <Chip color="error" label="Réjété" />

      default:
        return null
    }
  }
  const handleChange =
    (panel: number, item: any) =>
      (event: SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false)
        displayProduct(isExpanded, item)
      }
  const displayProduct = (expand: boolean, item: any) => {
    const productArrId = item.products.map(
      (item: { productId: any }) => item.productId
    )
    if (expand && productList) {
      const product = productList.productByBusiness.filter(
        (product: { id: number }) => productArrId.includes(product.id)
      )
      const processedProduct = product.map((i: any, index: number) => {
        return {
          ...i,
          ...item.products[index]
        }
      })

      setCurrentProduct(processedProduct)
    }

    if (expand && allProduct) {
      const product = allProduct.getAllProduct.filter(
        (product: { id: number }) => productArrId.includes(product.id)
      )
      const processedProduct = product.map((i: any, index: number) => {
        return {
          ...i,
          ...item.products[index]
        }
      })

      setCurrentWizi(processedProduct)
    }
  }
  useEffect(() => {
    if (user?.client) {
      refetch()
    }
    if (commands && commands?.clientCommandBusiness?.length !== 0) {
      setExpanded(commands?.clientCommandBusiness[commands?.clientCommandBusiness?.length - 1].id)
      const current = commands?.clientCommandBusiness[commands?.clientCommandBusiness?.length - 1]
      const productArrId = current.products.map(
        (item: { productId: any }) => item.productId
      )
      if (productList) {
        const product = productList.productByBusiness.filter(
          (product: { id: number }) => productArrId.includes(product.id)
        )
        const processedProduct = product.map((i: any, index: number) => {
          return {
            ...i,
            ...current.products[index]
          }
        })

        setCurrentProduct(processedProduct)
      }
    }
  }, [commands, productList, refetch, user?.client])

  useEffect(() => {
    if (user?.client) {
      wiziRefetch()
    }
    if (wiziCommands && wiziCommands?.length !== 0) {
      setExpanded(wiziCommands[wiziCommands?.length - 1].id)
      const current = wiziCommands[wiziCommands?.length - 1]
      const productArrId = current.products.map(
        (item: { productId: any }) => item.productId
      )
      if (productList) {
        const product = allProduct?.getAllProduct.filter(
          (product: { id: number }) => productArrId.includes(product.id)
        )
        if (product) {
          const processedProduct = product.map((i: any, index: number) => {
            return {
              ...i,
              ...current.products[index]
            }
          })
          setCurrentWizi(processedProduct)
        }
      }
    }
  }, [wiziCommands, productList, wiziRefetch, user?.client])

  return (
    <ContainerWithMenu
      theme={theme}
      noTopNav
      padding={1}
      loading={businessLoading}
    >
      {business?.id && <HeaderPage business={business} theme={theme} />}
      <BaseTypography
        theme={theme}
        variant="h1"
        textAlign="center"
        sx={{ mb: 4, fontWeight: 400 }}
      >
        Liste de mes commandes
      </BaseTypography>
      <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
        <Tabs value={tabValue} onChange={(e, newVal) => setTabValue(newVal)}>
          <Tab value={1} label={`Mes commandes sur ${business?.businessName}`} />
          <Tab value={2} label="Mes commandes sur WIZITAG" />
        </Tabs>
      </Box>
      <Container>
        {tabValue === 1 && (<QueryResult data={commands} loading={loading}>
          <>
            {commands?.clientCommandBusiness
              ?.map((item: any) => (
                <Accordion
                  key={item.id}
                  expanded={expanded === item.id}
                  onChange={handleChange(item.id, item)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        mr: 2,
                        alignItems: "center"
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        <Box
                          sx={{
                            border: "1px solid rgba(224, 224, 224, 1)",
                            borderRadius: 1,
                            px: 1,
                            mr: 2
                          }}
                        >
                          <Typography component="div">N°: {item.id}</Typography>
                        </Box>
                        <Typography component="div">
                          {moment(item.createdAt).format("DD MMM YYYY hh:mm")}
                        </Typography>
                      </Box>
                      <Typography component="div">
                        {Status(item.status)}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ProductListComponent dataTable={currentProduct} />
                  </AccordionDetails>
                </Accordion>
              ))
              .reverse()}
          </>
        </QueryResult>
        )}
        {tabValue === 2 && (
          <QueryResult data={wiziCommands} loading={wiziLoading}>
            <>
              {wiziCommands
                ?.map((item) => (
                  <Accordion
                    key={item.id}
                    expanded={expanded === item.id}
                    onChange={handleChange(item.id, item)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          mr: 2,
                          alignItems: "center"
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                          <Box
                            sx={{
                              border: "1px solid rgba(224, 224, 224, 1)",
                              borderRadius: 1,
                              px: 1,
                              mr: 2
                            }}
                          >
                            <Typography component="div">N°: {item.id}</Typography>
                          </Box>
                          <Typography component="div">
                            {moment(item.createdAt).format("DD MMM YYYY hh:mm")}
                          </Typography>
                        </Box>
                        <Typography component="div">
                          {Status(item.status)}
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ProductListComponent dataTable={currentWizi} />
                    </AccordionDetails>
                  </Accordion>
                ))
                .reverse()}
            </>
          </QueryResult>
        )}
      </Container>
    </ContainerWithMenu>
  )
}
