import { Button, styled } from "@mui/material"
import { FC, ReactNode } from "react"

import { ThemeType } from "../../types/ThemeType"

interface StyledBtnProps {
  colorBtn: string | undefined
  colorcustom: string | undefined
  police: string | undefined
  fontSize: string | undefined
}

const StyledBtn = styled(Button, {
  shouldForwardProp: (props) =>
    props !== "colorBtn" &&
    props !== "colorText" &&
    props !== "police" &&
    props !== "fontSize"
})<StyledBtnProps>(({ colorBtn, colorcustom, police, fontSize }) => ({
  textTransform: "none",
  backgroundColor: `${colorBtn} !important`,
  color: `${colorcustom} !important`,
  fontFamily: `${police} !important`,
  fontSize: `${fontSize} !important`,
  "span::before": {
    color: `${colorcustom}`
  }
}))

interface BaseBtnProps {
  disabled?: boolean
  children?: ReactNode
  className?: string
  theme?: ThemeType
  variant?: "text" | "outlined" | "contained"
  size?: "small" | "medium" | "large"
  fullWidth?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
  sx?: any
}

export const BaseBtn: FC<BaseBtnProps> = ({
  children,
  disabled,
  onClick,
  sx,
  theme,
  size,
  className,
  fullWidth,
  variant
}) => {
  return (
    <StyledBtn
      disabled={disabled}
      variant={variant}
      colorBtn={theme?.colorBtn}
      colorcustom={theme?.colorBtnText}
      police={theme?.police}
      fontSize={theme?.fontSize}
      size={size}
      sx={sx}
      onClick={onClick}
      className={className}
      fullWidth={fullWidth}
    >
      {children}
    </StyledBtn>
  )
}
