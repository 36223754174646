import { useMutation } from "@apollo/client"
import {
  CreateCommand,
  CreateCommandVariables,
  CREATE_COMMAND
} from "../graphql/commands"
import { CommandInput } from "../types"

export const useCreateCommand = () => {
  const [createExecution, { loading, error }] = useMutation<
    CreateCommand,
    CreateCommandVariables
  >(CREATE_COMMAND)
  const createCommand = async (data: CommandInput) => {
    try {
      const create = await createExecution({
        variables: {
          data
        }
      })
      return create
    } catch (error) {
      return undefined
    }
  }

  return { loading, error, createCommand }
}
