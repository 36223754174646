import { ApolloError } from "@apollo/client"
import { CircularProgress, Grid } from "@mui/material"

type MyProps = {
  data: object | undefined | null
  loading: boolean
  error?: ApolloError | undefined
  children: JSX.Element
  displayChild?: boolean
}

export const QueryResult = ({
  loading,
  data,
  error,
  children,
  displayChild
}: MyProps) => {
  if (loading)
    return (
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "center", my: 3 }}
        >
          <CircularProgress />
        </Grid>
      </Grid>
    )
  if (error) return null
  if (!data) {
    if (displayChild) {
      return children
    }

    return <p>Rien à afficher ici...</p>
  }
  if (data) {
    return children
  }

  return null
}
