import { LoginClient_loginClient as IUser } from "../../graphql/user/types"
import { AuthStorage } from "../../utils"
import { IUserAction } from "./Interfaces"

export function reducerUser(state: IUser, action: IUserAction): IUser {
  switch (action.type) {
    case "login":
      if (action.user.token) {
        AuthStorage.authenticate(action.user)
      }
      return action.user
    case "update":
      return action.user
    default:
      return state
  }
}
