/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { Cancel } from "@mui/icons-material"
import Edit from "@mui/icons-material/Edit"
import {
  Box,
  TextField,
  styled,
  TextFieldProps,
  BoxProps,
  IconButton,
  Typography,
  Divider
} from "@mui/material"
import { ChangeEvent, FC, ReactNode, SyntheticEvent, useState } from "react"
import { Loading } from "../loading"
import { ButtonLittleIcon } from "./Button"

interface CustomTextInputProps {
  isUrl?: boolean
  label?: string
  editable?: boolean
  containerProps?: BoxProps
  renderComponent?: (text: string) => ReactNode
  loading?: boolean
  nodivider?: boolean
  onSave?: (value: string, name: string) => void
}
const CustomTextField = styled(TextField)(() => ({
  borderRadius: 10,
  "& input": {
    paddingRight: "80px",
    overflow: "scroll"
  }
}))
export const CustomTextInput: FC<CustomTextInputProps & TextFieldProps> = ({
  containerProps,
  editable = true,
  loading,
  nodivider,
  isUrl,
  onSave,
  renderComponent,
  ...props
}): JSX.Element => {
  const { defaultValue: value, label, name, placeholder } = props
  const [values, setValues] = useState<string>(
    (value as string) || (placeholder as string)
  )
  const [edit, setEdit] = useState<boolean>(false)
  const handleSave = (e: SyntheticEvent): void => {
    e.preventDefault()
    if (!name) return
    onSave && onSave(values, name)
    setEdit(false)
  }
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setValues(e.target.value)
  }
  return (
    <Box {...containerProps}>
      <form onSubmit={handleSave}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {edit ? (
            <CustomTextField
              fullWidth
              onChange={handleChange}
              variant="outlined"
              {...props}
            />
          ) : (
            <Box sx={{ flexGrow: 1 }}>
              <Typography component="span" variant="subtitle1">
                {label}
              </Typography>
              {renderComponent ? (
                renderComponent(value as string)
              ) : (
                <>
                  {isUrl ? (
                    <a
                      href={value as string}
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography component="span" variant="subtitle2">
                        {value as string}
                      </Typography>
                    </a>
                  ) : (
                    <Typography component="span" variant="subtitle2">
                      {value as string}
                    </Typography>
                  )}
                </>
              )}
            </Box>
          )}
          <Box sx={{ display: "flex", width: 0, justifyContent: "flex-end" }}>
            {!edit && !loading && editable ? (
              <ButtonLittleIcon
                disabled={!editable}
                onClick={() => editable && setEdit(true)}
                sx={{ padding: "0 !important" }}
                icon={<Edit />}
              />
            ) : null}
            {loading ? (
              <IconButton>
                <Loading />
              </IconButton>
            ) : null}
            {edit ? (
              <>
                <IconButton type="submit">
                  <CheckCircleIcon color="success" />
                </IconButton>
                <IconButton onClick={() => setEdit(false)}>
                  <Cancel color="error" />
                </IconButton>
              </>
            ) : null}
          </Box>
        </Box>
        {nodivider ? null : <Divider />}
      </form>
    </Box>
  )
}
