import { gql } from "@apollo/client"

export const SCAN_QRCODE = gql`
  mutation ScanQrcode(
    $businessId: Float!
    $dataPlayer: PlayerInput!
    $qrcodeId: Float!
    $lotWinId: Float
  ) {
    scanQrcode(
      businessId: $businessId
      dataPlayer: $dataPlayer
      qrcodeId: $qrcodeId
      lotWinId: $lotWinId
    ) {
      id
      code
      active
      createdAt
      qRCodeId
      lotsId
      clientId
      lots {
        id
        image
        title
        description
      }
      qrcode {
        id
        qrcodeImage
      }
    }
  }
`
