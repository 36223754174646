import { makeStyles } from "@mui/styles"

export const useStyles = makeStyles(() => ({
  container: {
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  title: {
    textAlign: "center",
    lineHeight: "1em"
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20
  }
}))
