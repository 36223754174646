import { Box, Button, Typography } from "@mui/material"
import { ChangeEvent, FC, SyntheticEvent, useState } from "react"
import { TextInputBase } from "../../components"
import { DateTimePickerBase } from "../../components/forms/DateTimePickerBase"
import { ReservationInput } from "../../types"

interface ReservationFormProps {
  disabledBtn?: boolean
  onSave: (values: Partial<ReservationInput>) => void
}
export const ReservationForm: FC<ReservationFormProps> = ({
  disabledBtn,
  onSave
}) => {
  const [values, setValues] = useState<Partial<ReservationInput>>({
    date: new Date(),
    email: "",
    message: "",
    name: "",
    person: 2,
    phone: ""
  })

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setValues((prev) => ({
      ...prev,
      [name]: value
    }))
  }
  const handleChangeDate = (dt: Date | null) => {
    setValues((prev) => ({
      ...prev,
      date: dt
    }))
  }
  const handClick = (e: SyntheticEvent) => {
    e.preventDefault()
    onSave(values)
  }
  return (
    <Box>
      <TextInputBase
        name="name"
        value={values.name}
        onChange={handleChange}
        label="Votre nom"
      />
      <TextInputBase
        name="email"
        value={values.email}
        onChange={handleChange}
        label="Votre email"
      />
      <TextInputBase
        name="phone"
        value={values.phone}
        onChange={handleChange}
        label="Votre téléphone"
      />
      <TextInputBase
        name="person"
        type="number"
        value={values.person}
        onChange={handleChange}
        label="Nombre de personnes"
      />
      <TextInputBase
        name="message"
        value={values.message}
        onChange={handleChange}
        multiline
        label="Message ou description"
      />
      <Box sx={{ my: 3 }} id="timepickerboooking">
        <Typography>Choisir date</Typography>
        <DateTimePickerBase
          format="DD MMMM YYYY à HH:mm"
          label="Choisir date"
          value={values.date}
          onChange={handleChangeDate}
        />
      </Box>
      <Box sx={{ my: 3, textAlign: "center" }} onClick={handClick}>
        <Button disabled={disabledBtn} variant="contained">
          Réserver
        </Button>
      </Box>
    </Box>
  )
}
