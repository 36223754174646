/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react"
import { styled } from "@mui/material"
import moment from "moment"
import { TextInputBase } from "./TextInputBase"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider, StaticDateTimePicker } from "@mui/x-date-pickers"

interface DateTimePickerBaseProps {
  onChange: (date: Date | null) => void
  label?: string
  value?: Date
  format?: string
}
export const DateTimePickerBase: React.FC<DateTimePickerBaseProps> = ({
  onChange,
  label,
  value,
  format
}): JSX.Element => {
  const StyledInput = styled(TextInputBase)(() => ({
    "& input": {
      textTransform: "capitalize"
    }
  }))
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
      <StaticDateTimePicker
        renderInput={(props: any) => <StyledInput {...props} />}
        label={label}
        value={value || new Date()}
        inputFormat={format || "DD MMMM YYYY - HH:mm"}
        onChange={onChange}
        minDate={moment(new Date()) as any}
      />
    </LocalizationProvider>
  )
}
