import * as React from "react"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { AppBar, IconButton, Toolbar } from "@mui/material"
import { CancelOutlined } from "@mui/icons-material"
import { ThemeType } from "../../../types/ThemeType"
import { BaseBtn } from "../../Button/BaseBtn"

interface CustomModalProps {
  btnClose?: boolean
  fullscreen?: boolean
  noConfirmBtn?: boolean
  theme?: ThemeType
  open: boolean
  title?: string
  confirmLabel?: string
  cancelLabel?: string
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  onConfirm?: () => void
  noAction?: boolean
}
export const CustomModal: React.FC<CustomModalProps> = ({
  title,
  noConfirmBtn,
  btnClose,
  open,
  confirmLabel,
  theme,
  fullscreen,
  cancelLabel,
  noAction,
  setOpen,
  onConfirm,
  children
}): JSX.Element => {
  const handleClose = (): void => {
    setOpen(false)
  }

  const handleConfirm = (event: React.SyntheticEvent): void => {
    event.preventDefault()
    setOpen(false)
    onConfirm && onConfirm()
  }
  return (
    <div>
      <Dialog
        fullScreen={fullscreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {title || btnClose ? (
          <AppBar position="relative" color="default">
            <Toolbar sx={{ justifyContent: "space-between" }}>
              {title && (
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
              )}
              {btnClose ? (
                <IconButton onClick={handleClose}>
                  <CancelOutlined fontSize="large" />
                </IconButton>
              ) : null}
            </Toolbar>
          </AppBar>
        ) : (
          ""
        )}
        <DialogContent>{children}</DialogContent>
        {!noAction ? (
          <AppBar position="relative" color="default">
            <DialogActions>
              <BaseBtn theme={theme} variant="outlined" onClick={handleClose}>
                {cancelLabel || "Annuler"}
              </BaseBtn>
              {!noConfirmBtn && (
                <BaseBtn
                  theme={theme}
                  variant="contained"
                  onClick={handleConfirm}
                >
                  {confirmLabel || "Confirmer"}
                </BaseBtn>
              )}
            </DialogActions>
          </AppBar>
        ) : null}
      </Dialog>
    </div>
  )
}
