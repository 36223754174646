import { gql } from "@apollo/client"

export const UP_CLICK_COUNT = gql`
  mutation UpClickCount($businessId: Float) {
    upClickCount(businessId: $businessId)
  }
`

export const UP_BOOKING_COUNT = gql`
  mutation UpBookingCount($businessId: Float) {
    upBookingCount(businessId: $businessId)
  }
`

export const UP_SUBSCRIBERS_COUNT = gql`
  mutation UpSubscibersCount($businessId: Float) {
    upSubscibersCount(businessId: $businessId)
  }
`
