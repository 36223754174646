import { useLazyQuery } from "@apollo/client"
import {
  getQrcodewithnotuser,
  getQrcodewithnotuserVariables,
  GET_QRCODEWITHNOTUSER
} from "../graphql/qrcode"

export const useQRCode = () => {
  const [getQrcodewithnotuserExec, { loading, error }] = useLazyQuery<
    getQrcodewithnotuser,
    getQrcodewithnotuserVariables
  >(GET_QRCODEWITHNOTUSER)
  const getQrcode = async (id: number) => {
    try {
      const qrcode = await getQrcodewithnotuserExec({
        variables: {
          qrcodeId: id
        }
      })
      return qrcode
    } catch (errors) {
      return undefined
    }
  }

  return {
    getQrcode,
    loading,
    error
  }
}
