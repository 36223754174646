import { gql } from "@apollo/client"

export const LOGIN = gql`
  mutation LoginClient($data: LoginClientInput!, $businessId: Float!) {
    loginClient(data: $data, businessId: $businessId) {
      client {
        id
        email
        phone
        auth
        firstname
        lastname
        password
        photo
        createdAt
        acceptSms
        acceptEmail
        acceptBusinessSms
        acceptBusinessEmail
      }
      token
    }
  }
`
export const CREATE_CLIENT = gql`
  mutation CreateClient($data: CreateClientInput!, $businessId: Float!) {
    createClient(data: $data, businessId: $businessId) {
      client {
        id
        email
        phone
        auth
        firstname
        lastname
        password
        photo
        createdAt
        acceptSms
        acceptEmail
        acceptBusinessSms
        acceptBusinessEmail
      }
      token
    }
  }
`

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword(
    $businessId: Float!
    $clientInput: LoginClientInput!
  ) {
    forgotPassword(businessId: $businessId, clientInput: $clientInput)
  }
`

export const RESET_PASSWORD = gql`
  mutation ResetPassword($token: String!, $clientInput: LoginClientInput!) {
    resetPassword(token: $token, clientInput: $clientInput)
  }
`

export const UPDATE_CLIENT = gql`
  mutation UpdateClient($clientId: Float!, $data: CreateClientInput!) {
    updateClient(clientId: $clientId, data: $data) {
        id
        email
        phone
        auth
        firstname
        lastname
        password
        photo
        createdAt
        acceptSms
        acceptEmail
        acceptBusinessSms
        acceptBusinessEmail
    }
  }
`

export const COUNT_VISITOR = gql`
  mutation VisitorCountBusiness($businessId: Float!) {
    VisitorCountBusiness(businessId: $businessId)
  }
`
