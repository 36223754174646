import { useQuery } from "@apollo/client"
import { Box, Button, Divider, Grid, Typography } from "@mui/material"
import parse from "html-react-parser"
import {
  Add,
  Close,
  Remove,
  ShoppingCartOutlined,
  Delete,
  SettingsPower
} from "@mui/icons-material"
import { QueryResult } from ".."
import {
  GET_PRODUCT_ID,
  getProductById,
  getProductByIdVariables
} from "../../graphql"
import { FormatPrice } from "../FormatPriceBusiness"
import { BSType, CartType } from "../../types"
import { useApplicationContext } from "../../hooks"
import { ButtonClose } from "../Button/ButtonClose"
import { ButtonShop } from "../Button/ButtonShop"
import { ThemeType } from "../../types/ThemeType"
import { BaseBtn } from "../Button/BaseBtn"
import tag_logo from "../../assets/finaltag_logo.png"
import { useEffect, useState } from "react"

type ProductType = {
  id: number
  add: boolean
  theme?: ThemeType
  stock?: number
  onclose: () => void
  onAddCart: (item: CartType) => void
  onPopCart: (id: number) => void
}

export const ProductDetails = ({
  id,
  stock,
  add,
  theme,
  onclose,
  onAddCart,
  onPopCart
}: ProductType) => {
  const { data, loading } = useQuery<getProductById, getProductByIdVariables>(
    GET_PRODUCT_ID,
    {
      variables: {
        id: Number(id)
      },
      skip: !id
    }
  )
  const product = data?.getproductById
  const { cart, setCart, count, setCount } = useApplicationContext()
  const currentCart = cart || []
  const [productCount, setProductCount] = useState<number>(0)
  const incrementCount = () => {
    if (add) {
      currentCart.forEach((car) => {
        if (car.id === id) {
          car.stock = productCount + 1
        }
      })
      setCart([...currentCart])
      setCount(count + 1)
    } else {
      setProductCount(productCount + 1)
    }
  }
  const decrementCount = () => {
    if (add) {
      currentCart.forEach((car) => {
        if (car.id === id) {
          car.stock = productCount - 1
        }
      })
      setCart([...currentCart])
      setCount(count - 1)
    } else {
      setProductCount(productCount - 1)
    }
  }

  const handleAdd = () => {
    onAddCart({
      id: product?.id || "",
      name: product?.name || "",
      image: product?.image || "",
      price: product?.prix || 0,
      stock: count === 0 ? productCount : count,
      totalPrice: product?.prix ? product?.prix * count : 0,
      productId: parseInt(`${id}`) as any
    })
    if (count === 0) {
      setCount(1)
    }
  }

  useEffect(() => {
    const item = currentCart.find((item) => item.id === id)
    if (item) {
      setProductCount(item?.stock || 0)
    }
  }, [currentCart])

  return (
    <QueryResult data={data} loading={loading}>
      <Box
        sx={{
          maxWidth: 375,
          width: "100%",
          p: 2,
          backgroundColor: theme?.colorCardProduct,
          borderRadius: 10,
          position: "absolute" as const,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)"
        }}
      >
        <Box
          sx={{
            position: "absolute" as const,
            top: 20,
            right: 20,
            backgroundColor: theme?.colorCardProduct,
            borderRadius: "50%"
          }}
        >
          <Button
            sx={{ minWidth: 36, p: 1, border: `1px solid ${theme?.colorBtn}` }}
            variant="outlined"
            size="medium"
            onClick={onclose}
          >
            <Close sx={{ color: theme?.colorBtn }} />
          </Button>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: 340,
            borderRadius: 7,
            overflow: "hidden",
            mb: 2
          }}
        >
          <img
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain"
            }}
            src={product?.image || tag_logo}
            alt={product?.name}
          />
        </Box>
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{
            mb: 1,
            color: theme?.colorTextCardProduct,
            fontFamily: theme?.police
          }}
        >
          {product?.name}
        </Typography>
        {
          product?.preparationTime !== 0 && product?.preparationTime !== null && (
            <Typography
              variant="subtitle2"
              sx={{ color: theme?.colorTextCardProduct, fontFamily: theme?.police }}
            >
              Temps de préparation :{" "}
              <Typography
                fontWeight="bold"
                sx={{
                  color: theme?.colorTextCardProduct,
                  fontFamily: theme?.police
                }}
              >
                {product?.preparationTime} min
              </Typography>{" "}
            </Typography>
          )
        }
        <Divider />
        <Box
          sx={{
            "& p": {
              color: theme?.colorTextCardProduct
            }
          }}
        >
          {parse(product?.descriptionLong || "")}
        </Box>
        <Divider sx={{ my: 2 }} />
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item sx={{ mb: 1, display: "flex" }}>
            {theme?.bsType === BSType.CIRCULAR ? (
              <BaseBtn
                theme={theme}
                sx={{ minWidth: 20, p: 1, fontSize: "1.5em" }}
                size="small"
                disabled={productCount === 0}
                onClick={decrementCount}
              >
                <Remove />
              </BaseBtn>
            ) : (
              <Button
                sx={{
                  minWidth: 20,
                  p: 1,
                  fontSize: "1.5em",
                  "& span": {
                    "::before": {
                      width: 20,
                      height: 20,
                      color: `${theme?.colorBtn} !important`
                    }
                  }
                }}
                size="small"
                disabled={productCount === 0}
                onClick={decrementCount}
              >
                <span className="icon-Illustration-10"></span>
              </Button>
            )}
            <Typography
              variant="h5"
              sx={{
                display: "flex",
                alignItems: "center",
                mx: 1,
                color: theme?.colorTextCardProduct
              }}
            >
              {productCount}
            </Typography>
            {theme?.bsType === BSType.CIRCULAR ? (
              <BaseBtn
                theme={theme}
                sx={{ minWidth: 20, p: 1, fontSize: "1.5em" }}
                size="small"
                onClick={incrementCount}
              >
                <Add />
              </BaseBtn>
            ) : (
              <Button
                sx={{
                  minWidth: 20,
                  p: 1,
                  fontSize: "1.5em",
                  "& span": {
                    "::before": {
                      width: 20,
                      height: 20,
                      color: `${theme?.colorBtn} !important`
                    }
                  }
                }}
                size="small"
                onClick={incrementCount}
              >
                <span className="icon-Illustration-11"></span>
              </Button>
            )}
          </Grid>
          <Grid item>
            <FormatPrice
              variant="h5"
              sx={{ mx: 1, color: theme?.colorTextCardProduct }}
              priceItem={product?.prix || 0}
            />
            {product?.prix !== product?.prixTTC ? (
              <FormatPrice
                sx={{
                  mx: 1,
                  display: "inline-block",
                  textDecorationLine: "line-through",
                  color: theme?.colorTextCardProduct
                }}
                variant="body2"
                priceItem={product?.prixTTC || ""}
              />
            ) : (
              ""
            )}
          </Grid>
          <Grid item>
            {add ? (
              <ButtonClose
                theme={theme}
                onClick={() => {
                  onPopCart(Number(id))
                  setCount(0)
                }}
              >
                {/* <Close sx={{ color: theme?.colorBtnText }} /> */}
                <Delete sx={{ color: theme?.colorBtnText }} />
              </ButtonClose>
            ) : (
              <ButtonShop theme={theme} onClick={handleAdd}>
                <span
                  style={{
                    textTransform: "none",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "13px"
                  }}
                >
                  <ShoppingCartOutlined />
                </span>
              </ButtonShop>
            )}
          </Grid>
        </Grid>
      </Box>
    </QueryResult>
  )
}
