import { SyntheticEvent, useState } from "react"
import { useQuery } from "@apollo/client"
import {
  Container,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Typography,
  Grid
} from "@mui/material"
import { ExpandMore } from "@mui/icons-material"
import { ContainerWithMenu, HeaderPage, QueryResult } from "../../components"
import { BaseTypography } from "../../components/Typography/BaseTypography"
import { useApplicationContext } from "../../hooks"
import { WINNER_CLIENT } from "../../graphql/business/query"
import moment from "moment"

export const Prize = () => {
  const { business, businessLoading, theme, user } = useApplicationContext()
  const [expanded, setExpanded] = useState<number | false>(false)
  const { data, loading } = useQuery(WINNER_CLIENT, {
    variables: {
      clientId: user?.client?.id
    }
  })
  const Status = (status: any) => {
    switch (status) {
      case false:
        return <Chip color="warning" label="Non récupéré" />
      case true:
        return <Chip color="success" label="Récupéré" />

      default:
        return null
    }
  }
  const handleChange = (panel: number, item: any) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }
  return (
    <ContainerWithMenu
      theme={theme}
      noTopNav
      padding={1}
      loading={businessLoading}
    >
      {business?.id && <HeaderPage business={business} theme={theme} />}
      <BaseTypography
        theme={theme}
        variant="h1"
        textAlign="center"
        sx={{ mb: 4, fontWeight: 400 }}
      >
        Liste de vos gains
      </BaseTypography>
      <Container>
        <QueryResult data={data} loading={loading}>
          <>
            {data?.winnerPerUser
              ?.map((item: any) => (
                <Accordion
                  key={item.id}
                  expanded={expanded === item.id}
                  onChange={handleChange(item.id, item)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        mr: 2,
                        alignItems: "center"
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        <Box
                          sx={{
                            border: "1px solid rgba(224, 224, 224, 1)",
                            borderRadius: 1,
                            px: 1,
                            mr: 2
                          }}
                        >
                          <Typography component="div">N°: {item.id}</Typography>
                        </Box>
                      </Box>
                      <Typography component="div">
                        {Status(item.retrieve)}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box mb={2}>
                          <Typography variant="body2" fontWeight="bold">
                            Date de scan :
                          </Typography>
                          {moment(item.createdAt).format("DD MMMM YYYY")}
                        </Box>
                        {
                          item.clients && (
                            <Box mb={2}>
                              <Typography variant="body2" fontWeight="bold">
                                Informations enregistré :
                              </Typography>
                              <Box>
                                E-mail: {item?.clients.email}
                              </Box>
                              <Box>
                                Téléphone: {item?.clients.phone}
                              </Box>
                            </Box>
                          )
                        }
                        <Box mb={2}>
                          <Typography variant="body2" fontWeight="bold">
                            Lots :
                          </Typography>
                          {item.lots.title}
                        </Box>
                        <Box
                          sx={{
                            textAlign: "center"
                          }}
                        >
                          <img alt="item.lots.title" src={item.lots.image} />
                        </Box>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))
              .reverse()}
          </>
        </QueryResult>
      </Container>
    </ContainerWithMenu>
  )
}
