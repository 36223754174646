import { Add, Close, Remove, ShoppingCartOutlined } from "@mui/icons-material"
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography
} from "@mui/material"

import { useState } from "react"
import { BSType, CartType } from "../../types"
import { FormatPrice } from "../FormatPriceBusiness"
import { useApplicationContext } from "../../hooks"
import { ButtonShop } from "../Button/ButtonShop"
import { ButtonClose } from "../Button/ButtonClose"
import { BaseBtn } from "../Button/BaseBtn"
import { ThemeType } from "../../types/ThemeType"

type ProductType = {
  id: string | number
  image?: string
  name: string
  theme?: ThemeType
  description?: string
  prix: number | null
  prixTTC: number | null
  add: boolean
  stock: number
  onNavigate: (item: number | string) => void
  onAddCart: (item: CartType) => void
  onPopCart: (id: number) => void
}

export const CardProduct = ({
  id,
  image,
  name,
  description,
  prix,
  theme,
  prixTTC,
  stock,
  add,
  onNavigate,
  onAddCart,
  onPopCart
}: ProductType) => {
  const [cartStock, setCartStock] = useState(stock || 0)
  const { cart, setCart, count, setCount } = useApplicationContext()
  const currentCart = cart || []
  const incrementCount = () => {
    if (add) {
      currentCart.forEach((car) => {
        if (car.id === id) {
          car.stock = cartStock + 1
        }
      })
      localStorage.setItem("mycart", JSON.stringify(currentCart))
      setCart([...currentCart])
    }
    setCartStock(cartStock + 1)
    setCount(count + 1)
  }
  const decrementCount = () => {
    if (cartStock <= 0) {
      setCount(0)
      setCartStock(0)
      return
    }
    if (add) {
      currentCart.forEach((car) => {
        if (car.id === id) {
          car.stock = cartStock - 1
        }
      })
      localStorage.setItem("mycart", JSON.stringify(currentCart))
      setCart([...currentCart])
      if (cartStock === 1) {
        onPopCart(Number(id))
      }
    }
    setCartStock(cartStock - 1)
    setCount(count - 1)
  }

  const handleAdd = () => {
    onAddCart({
      id,
      name,
      image: image || "",
      price: prix || 0,
      stock: cartStock || 1,
      totalPrice: prix ? prix * cartStock : 0,
      productId: parseInt(`${id}`) as any
    })
    if (cartStock === 0) {
      setCartStock(1)
      setCount(1)
    }
  }

  return (
    <Card
      sx={{
        borderRadius: 5,
        boxShadow: "none",
        width: "100%",
        background: theme?.colorCardProduct,
        height: "330px",
        position: "relative",
        overflow: "visible"
      }}
    >
      <CardActionArea
        sx={{
          ":hover .MuiCardActionArea-focusHighlight": {
            opacity: 0
          }
        }}
        onClick={() => onNavigate(id)}
      >
        <CardMedia
          alt={name}
          component="img"
          image={image}
          sx={{
            p: 1,
            borderRadius: "30px",
            objectFit: "cover",
            backgroundSize: "auto",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom",
            width: "100%",
            height: "150px"
          }}
        />
        <CardContent
          sx={{
            p: 1,
            maxHeight: 110,
            height: "70px",
            overflow: "hidden"
          }}
        >
          <Typography
            variant="h5"
            sx={{
              textAlign: "center",
              textOverflow: "ellipsis",
              color: theme?.colorTextCardProduct,
              fontFamily: theme?.police,
              width: "100%",
              overflow: "hidden"
            }}
          >
            {name}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              width: "100%",
              color: theme?.colorTextCardProduct,
              fontFamily: theme?.police,
              textAlign: "center",
              overflow: "hidden",
              textShadow: "1px 1px rgba(0,0,0, 0.4)"
            }}
          >
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions sx={{ mt: 0, justifyContent: "center" }}>
        <Grid>
          <Box
            sx={{
              minHeight: "45px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              justifyContent: "center"
            }}
          >
            {
              prix !== prixTTC ? (
                <FormatPrice
                  sx={{
                    display: "inline-block",
                    fontWeight: "bold",
                    width: "max-content",
                    color: theme?.colorTextCardProduct,
                    fontFamily: theme?.police
                  }}
                  variant="body2"
                  priceItem={prix}
                />
              ) : (
                <FormatPrice
                  sx={{
                    display: "inline-block",
                    fontWeight: "bold",
                    width: "max-content",
                    color: theme?.colorTextCardProduct,
                    fontFamily: theme?.police
                  }}
                  variant="body2"
                  priceItem={prixTTC}
                />
              )
            }
            <Box sx={{ display: "flex" }}>
              {theme?.bsType === BSType.CIRCULAR ? (
                <BaseBtn
                  theme={theme}
                  sx={{ minWidth: 20, p: 1, fontSize: "1.5em" }}
                  size="small"
                  onClick={decrementCount}
                >
                  <Remove />
                </BaseBtn>
              ) : (
                <Button
                  sx={{
                    minWidth: 20,
                    p: 1,
                    fontSize: "1.5em",
                    "& span": {
                      "::before": {
                        width: 20,
                        height: 20,
                        color: `${theme?.colorBtn} !important`
                      }
                    }
                  }}
                  size="small"
                  onClick={decrementCount}
                >
                  <span className="icon-Illustration-10"></span>
                </Button>
              )}
              <Typography
                variant="h5"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: theme?.colorTextCardProduct,
                  fontFamily: theme?.police,
                  mx: 1
                }}
              >
                {cartStock}
              </Typography>
              {theme?.bsType === BSType.CIRCULAR ? (
                <BaseBtn
                  theme={theme}
                  sx={{ minWidth: 20, p: 1, fontSize: "1.5em" }}
                  size="small"
                  onClick={incrementCount}
                >
                  <Add />
                </BaseBtn>
              ) : (
                <Button
                  sx={{
                    minWidth: 20,
                    p: 1,
                    fontSize: "1.5em",
                    "& span": {
                      "::before": {
                        width: 20,
                        height: 20,
                        color: `${theme?.colorBtn} !important`
                      }
                    }
                  }}
                  size="small"
                  onClick={incrementCount}
                >
                  <span className="icon-Illustration-11"></span>
                </Button>
              )}
            </Box>
          </Box>
          <Grid
            sx={{
              width: "60px",
              height: "60px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              backgroundColor: theme?.colorCardProduct,
              border: `1px solid ${theme?.colorBtn}`,
              position: "absolute",
              bottom: "-25px",
              left: 0,
              right: 0,
              marginLeft: "auto",
              marginRight: "auto"
            }}
          >
            {add ? (
              <ButtonClose
                theme={theme}
                onClick={() => {
                  onPopCart(Number(id))
                  setCartStock(0)
                  setCount(0)
                }}
              >
                <Close sx={{ color: theme?.colorCardProduct }} />
              </ButtonClose>
            ) : (
              <ButtonShop theme={theme} onClick={() => handleAdd()}>
                <span
                  style={{
                    textTransform: "none",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "13px"
                  }}
                >
                  <ShoppingCartOutlined
                    sx={{ color: theme?.colorCardProduct }}
                  />
                </span>
              </ButtonShop>
            )}
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  )
}
