/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from "react"
import { Box, Button, Typography } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import onboardingImg from "../../../assets/onboarding1.png"
import { useStyles } from "./styles"

export const Onboarding = (): JSX.Element => {
  const { id } = useParams()
  const navigate = useNavigate()
  const classes = useStyles()

  const handleGo = (event: React.SyntheticEvent): void => {
    event.preventDefault()
    navigate(`/tag/games/${id}/spin`)
  }
  useEffect(() => {
    const qrcode = localStorage.getItem("qrcode")
    if (!qrcode) {
      navigate(`/tag`)
    }
  }, [navigate])

  return (
    <Box className={classes.container} sx={{ zIndex: 100 }}>
      <Box sx={{ background: "#0008", borderRadius: 3 }}>
        <Box className={classes.imageContainer} sx={{ p: 2 }}>
          <img src={onboardingImg} alt="" />
        </Box>
        <Box sx={{ background: "#0009", p: 3, borderRadius: 3 }}>
          <Typography
            variant="h3"
            color="white"
            className={classes.title}
            sx={{ mb: 2 }}
          >
            Jouer et gagner
          </Typography>
          <Typography color="white" sx={{ textAlign: "center" }}>
            Cliquer sur 'COMMENCER' pour accèder au Jeux.
          </Typography>
          <Typography sx={{ textAlign: "center" }} color="white">
            Bonne chance !
          </Typography>
          <Box sx={{ mt: 3, textAlign: "center" }}>
            <Button variant="contained" color="primary" onClick={handleGo}>
              COMMENCER
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
