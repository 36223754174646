import React, { useState } from "react"
import {
  Box,
  Typography,
  Card,
  Button,
  CardContent,
  Container,
  Switch
} from "@mui/material"
import TableBarIcon from "@mui/icons-material/TableBar"
import { Groups } from "@mui/icons-material"

import { ContainerWithMenu, CustomModal } from "../../components"
import { useTables } from "../../hooks/useTablesList"
import DescriptionIcon from "@mui/icons-material/Description"
import { ReservationForm } from "./ReservationForm"
import { ReservationInput } from "../../types"
import { useApplicationContext } from "../../hooks"
import { useReservation } from "../../hooks/useReservation"
import { useNavigate } from "react-router-dom"

export const Tables = () => {
  const [open, setOpen] = useState(false)
  const { tables } = useTables()
  const navigation = useNavigate()
  const [tableId, setTableId] = useState<number>()
  const { business, user } = useApplicationContext()
  const { bookTable, loadingBooking } = useReservation()
  const handleClick = (id: number) => {
    setTableId(id)
    setOpen(!open)
  }
  const handleReservation = async (values: Partial<ReservationInput>) => {
    if (!tableId || !business?.id || !user?.client?.id) {
      setOpen(false)
      return
    }
    await bookTable({
      ...values,
      person: parseInt((values.person as any) || "1"),
      businessId: business.id,
      clientId: user.client.id,
      tableId: tableId
    } as any)
    setOpen(false)
    navigation("/tag/booking")
  }
  return (
    <ContainerWithMenu padding={1} title="Liste de tables">
      <Container>
        {tables?.map((table) => (
          <Card sx={{ mb: 2 }}>
            <CardContent>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <TableBarIcon sx={{ mr: 1 }} />
                <Typography>{table.name}</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <Groups sx={{ mr: 1 }} />
                <Typography>{table.place} places</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <DescriptionIcon sx={{ mr: 1 }} />
                <Typography>{table.description}</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <Switch checked={table.disponible} />
                <Typography>
                  {table.disponible
                    ? "disponible pour une réservation"
                    : "indisponible"}
                </Typography>
              </Box>
              <Box sx={{ textAlign: "right" }}>
                <Button
                  variant="contained"
                  onClick={() => handleClick(table.id)}
                >
                  Réserver
                </Button>
              </Box>
            </CardContent>
          </Card>
        ))}
        <CustomModal
          title="Réservation table"
          open={open}
          setOpen={setOpen}
          noAction
        >
          <ReservationForm
            disabledBtn={loadingBooking}
            onSave={handleReservation}
          />
        </CustomModal>
      </Container>
    </ContainerWithMenu>
  )
}
