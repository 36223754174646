/* eslint-disable react/jsx-no-useless-fragment */
import { Box, Typography } from "@mui/material"
import { FC } from "react"
import { Loading } from "../../components"
import { useApplicationContext } from "../../hooks"
import { getCurrentLoction } from "../../utils"

export const PageProvider: FC = ({ children }) => {
  const { business, businessLoading } = useApplicationContext()
  const url = getCurrentLoction()
  const isLogin = url?.split(".")[0] === "login"
  return (
    <Box>
      {businessLoading ? (
        <Box
          sx={{
            marginTop: "25%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Loading />
        </Box>
      ) : (
        <>
          {business || isLogin ? (
            children
          ) : (
            <Box>
              <Typography>No business</Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  )
}
