/* eslint-disable react/jsx-props-no-spreading */
import Snackbar from "@mui/material/Snackbar"
import React, { createContext, FC, Reducer, useReducer } from "react"
import MuiAlert, { AlertColor, AlertProps } from "@mui/material/Alert"

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export type ISnackContext = {
  dispatchSnack: React.Dispatch<ReducerActionType>
}
export const SnackbarContext = createContext<ISnackContext>({} as ISnackContext)
const initialState = {
  open: false,
  severity: undefined,
  message: ""
}

interface ReducerStateType {
  open: boolean
  severity?: AlertColor
  message?: string
}
export interface ReducerActionType {
  open: boolean
  severity?: AlertColor
  message?: string
}
function reducer(
  state: ReducerStateType,
  action: ReducerActionType
): ReducerStateType {
  switch (action.open) {
    case true:
      return { open: true, severity: action.severity, message: action.message }
    case false:
      return { open: false, severity: undefined, message: "" }
    default:
      throw new Error()
  }
}

export const SnackbarProvider: FC = ({ children }): JSX.Element => {
  const [{ open, severity, message }, dispatchSnack] = useReducer<
    Reducer<ReducerStateType, ReducerActionType>
  >(reducer, initialState)

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ): void => {
    event?.preventDefault()
    if (reason === "clickaway") {
      return
    }

    dispatchSnack({
      open: false,
      severity: undefined
    })
  }

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <SnackbarContext.Provider value={{ dispatchSnack }}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          horizontal: "right",
          vertical: "top"
        }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  )
}
