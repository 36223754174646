import { gql } from "@apollo/client"

export const COMMANDS = gql`
  query ClientCommand($clientId: Float!) {
    clientCommand(clientId: $clientId) {
      id
      code
      clientId
      address
      createdAt
      status
      email
      description
      name
      products {
        commandsId
        id
        productId
        totalCount
        productPrice
      }
      _count {
        products
      }
    }
  }
`

export const COMMANDS_BUSINESS = gql`
  query ClientCommandBusiness($businessId: Float!, $clientId: Float!) {
    clientCommandBusiness(businessId: $businessId, clientId: $clientId) {
      id
      code
      clientId
      address
      createdAt
      status
      email
      description
      name
      products {
        commandsId
        id
        productId
        totalCount
        productPrice
      }
      _count {
        products
      }
    }
  }
`
