/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, styled } from "@mui/material"
import { FC } from "react"
import {
  GoogleLogin,
  GoogleLoginResponse,
  GoogleLoginResponseOffline
} from "react-google-login"
import { GoogleIcon } from "../../icons/icons"
const StyledButton = styled(Button)(() => ({
}))
interface GoogleButtonProps {
  inCallback?: boolean
  onSigninSuccess: (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => void
  onSigninError: (error: any) => void
  onClickBtn?: () => void
}
export const GoogleButton: FC<GoogleButtonProps> = ({
  inCallback,
  onSigninSuccess,
  onSigninError,
  onClickBtn
}): JSX.Element => {
  return (
    <GoogleLogin
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}
      buttonText="Login"
      onSuccess={onSigninSuccess}
      onFailure={onSigninError}
      cookiePolicy="single_host_origin"
      render={({ onClick }) => (
        <StyledButton
          fullWidth
          variant="outlined"
          color="inherit"
          startIcon={<GoogleIcon />}
          onClick={() => {
            return inCallback
              ? onClick()
              : onClickBtn
              ? onClickBtn()
              : undefined
          }}
        >
          {inCallback
            ? "Cliquer pour continuer avec google"
            : "Continuer avec Google"}
        </StyledButton>
      )}
    />
  )
}
