import { Chip, Typography } from "@mui/material"
import { Box } from "@mui/system"
import moment from "moment"
import { TableColProps } from "../../components/Table"
import { OrdreStatusType } from "../../types"
import { ClientResevation_clientResevation as IReservation } from "../../graphql/reservations/types/ClientResevation"

export const useBookingColumns = () => {
  const Status = (status: OrdreStatusType) => {
    switch (status) {
      case OrdreStatusType.PENDING:
        return <Chip color="warning" label="En cours" />
      case OrdreStatusType.VALID:
        return <Chip color="success" label="Validé" />
      case OrdreStatusType.DONE:
        return <Chip color="primary" label="Fini" />
      case OrdreStatusType.REJECT:
        return <Chip color="error" label="Réjété" />

      default:
        return null
    }
  }
  const columns: TableColProps[] = [
    {
      id: "name",
      label: "Date",
      component: (data: IReservation) => (
        <div>
          <Typography textAlign="center">
            {moment(data.date).format("DD MMMM YYYY à HH:mm")}
          </Typography>
        </div>
      )
    },
    {
      id: "etbs",
      label: "Etablissement",
      component: (data: IReservation) => (
        <div>
          <Typography textAlign="center">
            {data.business.businessName}
          </Typography>
        </div>
      )
    },
    {
      id: "status",
      label: "Status",
      component: (data: IReservation) => (
        <Box sx={{ textAlign: "center" }}>{Status(data.status)}</Box>
      )
    }
  ]

  return { columns }
}
