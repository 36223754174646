import { Box, BoxProps, styled } from "@mui/material"
import ReactPlayer from "react-player"
import { Routes, Route } from "react-router-dom"
import { useApplicationContext } from "../../hooks"
import { BGType } from "../../types"
import { AlreadyUsed } from "./Errors/AlreadyScanned"
import { GeneralError } from "./Errors/GeneralError"
import { NotFoundGame } from "./Errors/NotFound"
import { LoadGame } from "./LoadGame"
import { Onboarding } from "./onboarding"
import { GameLost } from "./pages/GameLost"
import { GameWin } from "./pages/GameWin"
import { RouletteGame } from "./RouletteGame"

interface BoxContainerProps extends BoxProps {
  image?: string
  color?: string
  type?: BGType
}
const StyledBox = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "image" && prop !== "color" && prop !== "type"
})<BoxContainerProps>(({ image, color, type }) => ({
  ...(type === BGType.IMAGE
    ? { background: `url(${image}) no-repeat center` }
    : {}),
  ...(type === BGType.COLOR_LINEAR ? { background: `${color}` } : {}),
  backgroundSize: "cover",
  width: "100%",
  minHeight: "100vh"
}))

const StyledBoxVideo = styled(Box)(() => ({
  zIndex: 0,
  width: "100%",
  height: "100vh",
  position: "fixed",
  top: 0,
  left: 0
}))

export const RouletteGameRouter = (): JSX.Element => {
  const { business } = useApplicationContext()

  return (
    <StyledBox
      image={business?.BusinessPalette?.bgImage || ""}
      type={business?.BusinessPalette?.bgType as BGType}
      color={`linear-gradient(0deg, ${business?.BusinessPalette?.bgColor2}, ${business?.BusinessPalette?.bgColor1})`}
    >
      {business?.BusinessPalette?.bgType === BGType.VIDEO ? (
        <StyledBoxVideo>
          <ReactPlayer
            playing
            url={business?.BusinessPalette?.bgViedo || ""}
            height="100vh"
            width="100%"
          />
        </StyledBoxVideo>
      ) : null}
      <Box
        sx={{
          zIndex: 10,
          height: "100%",
          width: { xs: "100%", sm: "auto" },
          top: 0,
          padding: {
            sx: 2,
            sm: 0
          },
          pointerEvents: "all"
        }}
      >
        <Routes>
          <Route index element={<Box />} />
          <Route path=":id" element={<LoadGame />} />
          <Route path=":id/already_used" element={<AlreadyUsed />} />
          <Route path=":id/not_found" element={<NotFoundGame />} />
          <Route path=":id/error" element={<GeneralError />} />
          <Route path=":id/go" element={<Onboarding />} />
          <Route path=":id/spin" element={<RouletteGame />} />
          <Route path=":id/win/:lotId" element={<GameWin />} />
          <Route path=":id/win" element={<GameWin />} />
          <Route path=":id/lost" element={<GameLost />} />
        </Routes>
      </Box>
    </StyledBox>
  )
}
