import { Box, Container, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  CartItem,
  ContainerWithMenu,
  HeaderPage,
  LoginModal
} from "../../components"
import { useApplicationContext, UserAuthStateEnum } from "../../hooks"
import emptyCardPics from "../../assets/empty-cart.png"
import { BaseBtn } from "../../components/Button/BaseBtn"
import { BaseTypography } from "../../components/Typography/BaseTypography"

export const CartPage = () => {
  const { business, businessLoading, userAuthStatus, theme } =
    useApplicationContext()
  const { cart, setCart } = useApplicationContext()
  const [open, setOpen] = useState(false)
  const currentCart = cart || []
  const navigate = useNavigate()

  const EmptyCart = () => (
    <Box textAlign="center">
      <Box
        sx={{
          width: "250px",
          height: "250px",
          m: "20px auto"
        }}
      >
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover"
          }}
          src={emptyCardPics}
          alt="empty-card"
        />
      </Box>
      <Typography sx={{ fontSize: 18, mb: 4 }}>
        Votre panier est encore vide
      </Typography>
      <BaseBtn theme={theme} onClick={() => navigate("/tag/product")}>
        Aller voir les produits
      </BaseBtn>
    </Box>
  )

  const popFromCart = (popItem: number) => {
    const arrCart = currentCart.filter((item) => item.id !== popItem)
    localStorage.setItem("mycart", JSON.stringify(arrCart))
    setCart(arrCart)
  }

  const validateCommand = () => {
    if (userAuthStatus === UserAuthStateEnum.AUTHENTICATED) {
      return navigate("/tag/commands")
    }

    return setOpen(true)
  }

  const handleLogging = (status: boolean) => {
    if (status) {
      return navigate("/tag/commands")
    }

    return navigate("/login")
  }

  useEffect(() => {
    const cartSave = localStorage.getItem("mycart")
    setCart(JSON.parse(cartSave || "[]"))
  }, [setCart])

  return (
    <ContainerWithMenu
      theme={theme}
      padding={1}
      loading={businessLoading}
      noTopNav
    >
      {business?.id && <HeaderPage theme={theme} business={business} />}
      <BaseTypography
        theme={theme}
        variant="h1"
        sx={{
          my: 2,
          mx: "auto",
          width: "max-content",
          p: 0.5,
          fontWeight: 400
        }}
      >
        Mon panier
      </BaseTypography>
      <Container
        sx={{ backgroundColor: "white", borderRadius: "12px", my: 3, p: 3 }}
      >
        {currentCart
          ? currentCart.map((item) => (
              <CartItem
                theme={theme}
                key={item.id}
                id={item.id}
                name={item.name}
                image={item.image}
                price={item.price}
                stock={item.stock}
                onPopCart={popFromCart}
              />
            ))
          : ""}

        {currentCart.length > 0 ? (
          <Box
            sx={{
              height: "65px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <BaseBtn
              theme={theme}
              variant="contained"
              onClick={() => validateCommand()}
              sx={{ mb: 1 }}
            >
              Valider ma commande
            </BaseBtn>
          </Box>
        ) : (
          <EmptyCart />
        )}
      </Container>
      <LoginModal
        onLogStatus={handleLogging}
        open={open}
        onClose={() => setOpen(false)}
      />
    </ContainerWithMenu>
  )
}
