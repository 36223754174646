export const COLOR = {
  PRIMARY: "#FE724C",
  DARK_BLUE: "#131A38",
  DARK_GREY: "#616772",
  BLUE: "#1F3C88",
  LIGHT_GREY: "#D3D1D8",
  WHITE: "#fff"
}
export const GAME_BG = [
  "#ff8f43",
  "#e91e63",
  "#70bbe0",
  "#0b3351",
  "#673ab7",
  "#f9dd50",
  "#e24a2b",
  "#7fffd4",
  "#673ab7",
  "#00bcd4",
  "#4caf50",
  "#ffeb3b",
  "#ff9800",
  "#607d8b",
  "#9e9e9e",
  "#795548",
  "#f44336"
]
