/* eslint-disable @typescript-eslint/no-explicit-any */
import { STORAGE_TOKEN_NAME, STORAGE_USER_NAME } from "../constants"
import { LoginClient_loginClient as IUser } from "../graphql/user/types"

export const AuthStorage = {
  isAuth: (): IUser | undefined => {
    if (typeof window === undefined) return undefined
    const token = localStorage.getItem(STORAGE_TOKEN_NAME)
    const user = localStorage.getItem(STORAGE_USER_NAME) as any
    if (token && user) {
      return JSON.parse(user)
    }
    return undefined
  },
  authenticate: (user: IUser, callback?: (data: IUser) => void): unknown => {
    if (typeof window !== undefined) {
      localStorage.setItem(STORAGE_TOKEN_NAME, JSON.stringify(user.token))
      localStorage.setItem(STORAGE_USER_NAME, JSON.stringify(user) as any)
      return callback && callback(user)
    }
    return undefined
  },
  clearToken: (callback: () => void) => {
    if (typeof window !== undefined) {
      localStorage.removeItem(STORAGE_TOKEN_NAME)
      localStorage.removeItem(STORAGE_USER_NAME)
    }
    callback()
  }
}
