import { Box, Button, styled } from "@mui/material"
import FacebookLogin, {
  ReactFacebookFailureResponse,
  ReactFacebookLoginInfo
} from "react-facebook-login"
import { FC } from "react"
import { FacebookIcon } from "../../icons/icons"

interface FacebookButtonProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inCallback?: boolean
  onSigninFb: (
    data: ReactFacebookLoginInfo | ReactFacebookFailureResponse
  ) => void
  onClickBtn?: () => void
}

const StyledBox = styled(Box)(() => ({
  "& button": {
    backgroundColor: "white",
    color: "inherit",
    fontFamily: "inherit",
    fontWeight: "inherit",
    alignItems: "center",
    display: "flex",
    borderColor: "#ccc",
    borderRadius: "50px !important",
    height: 40,
    width: "100%",
    justifyContent: "center",
    textTransform: "none",
    fontSize: "0.875rem",
    "& svg": {
      marginRight: 10
    }
  }
}))

export const FacebookButton: FC<FacebookButtonProps> = ({
  inCallback,
  onSigninFb,
  onClickBtn
}): JSX.Element => {
  return (
    <StyledBox>
      {inCallback ? (
        <FacebookLogin
          appId={process.env.REACT_APP_FB_APP_KEY as string}
          fields="name,email,picture"
          callback={onSigninFb}
          icon={<FacebookIcon />}
          textButton={
            inCallback
              ? "Cliquer pour continuer avec Facebook"
              : "Continuer avec Facebook"
          }
        />
      ) : (
        <Button
          variant="outlined"
          color="inherit"
          startIcon={<FacebookIcon />}
          onClick={() => onClickBtn && onClickBtn()}
        >
          Continuer avec Facebook
        </Button>
      )}
    </StyledBox>
  )
}
