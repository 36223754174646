import { ReactNode } from "react"
import { Login } from "@mui/icons-material"
import { MenuStatusEnum } from "../hooks"

type BottomMenusType = {
  id: string
  path: string
  label: string
  icon: ReactNode
  status: MenuStatusEnum
}
export const BottomMenus: BottomMenusType[] = [
  {
    id: "ACCUEIL",
    path: "/tag",
    label: "Accueil",
    icon: (
      <span className="icon-Illustration-14" style={{ fontSize: "2em" }}></span>
    ),
    status: MenuStatusEnum.ALL
  },
  {
    id: "MENUS",
    path: "/tag/product",
    label: "Produits",
    icon: (
      <span className="icon-Illustration-15" style={{ fontSize: "2em" }}></span>
    ),
    status: MenuStatusEnum.ALL
  },
  {
    id: "CART",
    path: "/tag/cart",
    label: "Panier",
    icon: (
      <span className="icon-Illustration-12" style={{ fontSize: "2em" }}></span>
    ),
    status: MenuStatusEnum.ALL
  },
  {
    id: "RESERVATION",
    path: "/tag/reservation",
    label: "Réservation",
    icon: (
      <span className="icon-Illustration-7" style={{ fontSize: "2em" }}></span>
    ),
    status: MenuStatusEnum.ALL
  },
  {
    id: "ACCOUNT",
    path: "/tag/account",
    label: "Mon profil",
    icon: (
      <span className="icon-Illustration-13" style={{ fontSize: "2em" }}></span>
    ),
    status: MenuStatusEnum.CONNECTED
  },
  {
    id: "LOGIN",
    path: "/login",
    label: "Connexion",
    icon: <Login sx={{ fontSize: "2em" }} />,
    status: MenuStatusEnum.NOTCONNECTED
  }
]
