/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  Card,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
  Checkbox
} from "@mui/material"
import moment from "moment"
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn"
import LogoutIcon from "@mui/icons-material/Logout"
import { useNavigate } from "react-router-dom"
import { ContainerWithMenu, HeaderPage } from "../../components"
import { useApplicationContext } from "../../hooks"
import { BaseTypography } from "../../components/Typography/BaseTypography"
import { FooterCommercant } from "../Landing/components/FooterCommercant"
import logo from "../../assets/newLogo.png"
import { ChangeEvent, useState, useEffect } from "react"
import { useMutation } from "@apollo/client"
import { UPDATE_CLIENT } from "../../graphql"

export const Profil = (): JSX.Element => {
  const { business, theme } = useApplicationContext()
  const navigate = useNavigate()
  const { user, logout } = useApplicationContext()
  const infos = user?.client
  const [check, setCheck] = useState(false)
  const [checkSms, setCheckSms] = useState(false)
  const [checkBusiness, setCheckBusiness] = useState(false)
  const [checkSmsBusiness, setCheckSmsBusiness] = useState(false)
  const [updateClt] = useMutation(UPDATE_CLIENT)

  const handleCheck = async (e: ChangeEvent<HTMLInputElement>) => {
    await updateClt({
      variables: {
        clientId: infos?.id,
        data: {
          [e.target.name]: e.target.checked
        }
      }
    })
  }

  const handleLogout = (event: React.SyntheticEvent): void => {
    event.preventDefault()
    logout()
    setTimeout(() => {
      navigate("/login")
      window.location.reload()
    }, 250)
  }

  useEffect(() => {
    setCheck(infos?.acceptEmail || false)
    setCheckSms(infos?.acceptSms || false)
    setCheckBusiness(infos?.acceptBusinessEmail || false)
    setCheckSmsBusiness(infos?.acceptBusinessSms || false)
  }, [user])

  return (
    <ContainerWithMenu theme={theme} padding={1} noTopNav>
      {business?.id && <HeaderPage theme={theme} business={business} />}
      <BaseTypography
        theme={theme}
        variant="h1"
        textAlign="center"
        sx={{ my: 2, fontWeight: 400 }}
      >
        Mon compte
      </BaseTypography>
      <Box sx={{ p: 2 }}>
        <Card sx={{ p: 2, mb: 3, borderRadius: "10px" }} elevation={2}>
          <Grid container>
            <Grid item xs={6}>
              <Box
                sx={{
                  mineight: 150,
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <img src={infos?.photo || logo} alt="profile" />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h3">
                {infos?.firstname} {infos?.lastname}
              </Typography>
              <Box>
                <BaseTypography
                  sx={{
                    wordBreak: "break-all"
                  }}
                >
                  {infos?.email}
                </BaseTypography>
                <BaseTypography>{infos?.phone}</BaseTypography>
              </Box>
              <Box sx={{ mb: 3 }}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox
                      checked={check}
                      name="acceptEmail"
                      onChange={(e) => {
                        setCheck(e.target.checked)
                        handleCheck(e)
                      }}
                    />}
                    label="Souscrire aux newsletters"
                  />
                  <FormControlLabel
                    control={<Checkbox
                      checked={checkSms}
                      name="acceptSms"
                      onChange={(e) => {
                        setCheckSms(e.target.checked)
                        handleCheck(e)
                      }}
                    />}
                    label="Souscrire aux sms"
                  />
                  <FormControlLabel
                    control={<Checkbox
                      checked={checkBusiness}
                      name="acceptBusinessEmail"
                      onChange={(e) => {
                        setCheckBusiness(e.target.checked)
                        handleCheck(e)
                      }}
                    />}
                    label="Souscrire aux newsletters de WIZITAG"
                  />
                  <FormControlLabel
                    control={<Checkbox
                      checked={checkSmsBusiness}
                      name="acceptBusinessSms"
                      onChange={(e) => {
                        setCheckSmsBusiness(e.target.checked)
                        handleCheck(e)
                      }}
                    />}
                    label="Souscrire aux sms de WIZITAG"
                  />
                </FormGroup>
              </Box>
              <Typography>
                <span style={{ marginRight: "2px" }}>membre depuis</span>
                {infos?.createdAt &&
                  moment(infos?.createdAt).format("DD MMM YYYY")}
              </Typography>
            </Grid>
          </Grid>
        </Card>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Card
              elevation={1}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "10px",
                p: 2
              }}
            >
              <Button
                sx={{
                  display: "block",
                  // color: "black !important",
                  "& span:before": {
                    color: theme?.colorBtn
                  }
                }}
                onClick={() => {
                  navigate("/tag/prize", { replace: true })
                }}
              >
                <MonetizationOnIcon
                  sx={{ color: theme?.colorBtn || "#e63946", fontSize: "2em" }}
                />
                <Typography variant="h5" component="div">
                  Mes gains
                </Typography>
              </Button>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card
              elevation={1}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "10px",
                p: 2
              }}
            >
              <Button
                onClick={() => {
                  navigate("/tag/order", { replace: true })
                }}
                sx={{
                  display: "block",
                  color: "black !important",
                  "& span:before": {
                    color: theme?.colorBtn
                  }
                }}
              >
                <span
                  className="icon-Illustration-12"
                  style={{
                    display: "block",
                    width: "100%",
                    marginBottom: "10px",
                    fontSize: "2em"
                  }}
                ></span>
                <Typography variant="h5" component="div">
                  Mes commandes
                </Typography>
              </Button>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card
              elevation={1}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "10px",
                p: 2
              }}
            >
              <Button
                sx={{
                  display: "block",
                  color: "black !important",
                  "& span:before": {
                    color: theme?.colorBtn
                  }
                }}
                onClick={(e) => {
                  navigate("/tag/booking-list", { replace: true })
                }}
              >
                <span
                  className="icon-Illustration-7"
                  style={{
                    display: "block",
                    width: "100%",
                    marginBottom: "10px",
                    fontSize: "2em"
                  }}
                ></span>
                <Typography variant="h5" component="div">
                  Mes réservations
                </Typography>
              </Button>
            </Card>
          </Grid>
        </Grid>
        <Card
          elevation={1}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            my: 2
          }}
        >
          <Button onClick={handleLogout} sx={{ color: "black !important" }}>
            <LogoutIcon
              sx={{ fill: theme?.colorBtn, fontSize: "2em", mr: 2 }}
            />
            <Typography variant="h5" component="div">
              Se Déconnecter
            </Typography>
          </Button>
        </Card>
        {business?.id && <FooterCommercant theme={theme} business={business} />}
      </Box>
    </ContainerWithMenu>
  )
}
