/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useLocation, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { ReactFacebookLoginInfo } from "react-facebook-login"
import { Login } from "./Login"
import { useApplicationContext, UserAuthStateEnum } from "../../../hooks"
import { PageProvider } from "../../PageProvider"
import { SourceEnumType } from "../../../types"

export const SignIn = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const location = useLocation()
  const [redirection, setRedirection] = useState<string>()
  const [path, setPath] = useState<string>()
  const { signIn, dispatchSnack, business, userAuthStatus } =
    useApplicationContext()

  const handleLogin = async (data: any): Promise<void> => {
    setLoading(true)

    const login = await signIn(data)
    if (login.success) {
      dispatchSnack({
        open: true,
        severity: "success",
        message: "Authentification réussi !"
      })
      const from = (location?.state as string) || "/tag"
      navigate(path || from, { replace: true })
      window.location.reload()
      setLoading(false)
    }
    if (login.error) {
      setLoading(false)
      dispatchSnack({
        open: true,
        severity: "error",
        message: "Action refusée . Veuillez rééssayer !"
      })
    }
    setLoading(false)
  }

  const onClickBtnGoogle = () => {
    const data = {
      name: business?.businessName,
      logo: business?.logo,
      address: business?.address,
      photo: business?.coverPhoto
    }
    localStorage.setItem("source", SourceEnumType.GOOGLE)
    const businessData = encodeURIComponent(JSON.stringify(data))
    window.location.replace(
      `https://login.wizitag.com/callback/google/${business?.url}/${businessData}`
    )
  }
  const onClickBtnFb = () => {
    const data = {
      name: business?.businessName,
      logo: business?.logo,
      address: business?.address,
      photo: business?.coverPhoto
    }
    localStorage.setItem("source", SourceEnumType.FACEBOOK)
    const businessData = encodeURIComponent(JSON.stringify(data))
    window.location.replace(
      `https://login.wizitag.com/callback/facebook/${business?.url}/${businessData}`
    )
  }

  // Those function is used only to maintain stability

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleLoginGoogle = async (googleResponse: any): Promise<void> => {
    // nothing to do here
  }
  const handleLoginFacebook = async (
    data: ReactFacebookLoginInfo
  ): Promise<void> => {
    // nothing to do here
  }
  const handleGoogleError = (error: any): void => {
    // nothing to do here
  }
  const getLocal = () => {
    const local = localStorage.getItem("redirection_path")
    if (local) {
      const auth = JSON.parse(local)
      if (auth.auth && business?.url) {
        setPath(auth.path)
        setRedirection(`https://${business?.url}${auth.path}`)
      }
    }
  }
  useEffect(() => {
    getLocal()
  }, [business])

  const isAuth = () => {
    if (userAuthStatus === UserAuthStateEnum.AUTHENTICATED) {
      navigate("/tag")
    }
  }
  useEffect(() => {
    isAuth()
  }, [userAuthStatus])

  return (
    <PageProvider>
      <Login
        redirection={redirection}
        path={path}
        onSignin={handleLogin}
        onLoginWithFacebook={handleLoginFacebook}
        onSigninGoogleSuccess={handleLoginGoogle}
        onSigninGoogleError={handleGoogleError}
        loading={loading}
        onClickBtnGoogle={onClickBtnGoogle}
        onClickBtnFb={onClickBtnFb}
      />
    </PageProvider>
  )
}
