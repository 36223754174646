import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import { TableProps } from "./TableTypes"

export const TableComponent = ({ columns, data }: TableProps) => {

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((col, index) => {
              const key = `col-${col.id}-${index}`
              return (
                <TableCell align="center" key={key}>
                  <Typography variant="h5">{col.label}</Typography>
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => {
            const key = `rowkey-${index}`

            return (
              <TableRow key={key}>
                {columns.map((col, i) => {
                  const keyCell = `cellKey-${col.id}-${i}`
                  return (
                    <TableCell
                      key={keyCell}
                      data-title={col.label}
                    >
                      {col.component(item)}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
