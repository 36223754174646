/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum BGType {
  COLOR_LINEAR = "COLOR_LINEAR",
  COLOR_SINGLE = "COLOR_SINGLE",
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}

export enum BSType {
  CIRCULAR = "CIRCULAR",
  SQUARE = "SQUARE",
}

export enum ClientStatusType {
  PARTICULIER = "PARTICULIER",
  PRO = "PRO",
}

export enum CloseType {
  AFTERNOON = "AFTERNOON",
  MORNING = "MORNING",
  NIGHT = "NIGHT",
}

export enum CommandStatusType {
  DONE = "DONE",
  PENDING = "PENDING",
  REJECT = "REJECT",
  VALID = "VALID",
}

export enum CommandTraitementType {
  DIFFEREECOMMAND = "DIFFEREECOMMAND",
  TODAYCOMMAND = "TODAYCOMMAND",
}

export enum FileBusinessType {
  CATALOGUE_PRODUCT = "CATALOGUE_PRODUCT",
  GALLERIES = "GALLERIES",
  MENU = "MENU",
  PLAT = "PLAT",
  UNKNOWN = "UNKNOWN",
}

export enum FileType {
  IMAGE = "IMAGE",
  PDF = "PDF",
  VIDEO = "VIDEO",
}

export enum OrdreStatusType {
  DONE = "DONE",
  PENDING = "PENDING",
  REJECT = "REJECT",
  VALID = "VALID",
}

export enum SourceEnumType {
  FACEBOOK = "FACEBOOK",
  FORM = "FORM",
  GOOGLE = "GOOGLE",
  IMPORT = "IMPORT",
  MANUAL = "MANUAL",
}

export enum StatusEnumType {
  CLIENT = "CLIENT",
  INACTIF = "INACTIF",
  PROSPECT = "PROSPECT",
  VIP = "VIP",
}

/**
 * command input
 */
export interface CommandInput {
  products: ProdcutCommandInput[];
  businessId: number;
  description: string;
  email: string;
  phone: string;
  name: string;
  address: string;
  clientId: number;
  commandTraitementType: CommandTraitementType;
  deferredDate?: any | null;
}

/**
 * New create user data
 */
export interface CreateClientInput {
  email?: string | null;
  phone?: string | null;
  lastname?: string | null;
  firstname?: string | null;
  address?: string | null;
  city?: string | null;
  codePostal?: string | null;
  civilite?: string | null;
  source?: SourceEnumType | null;
  status?: StatusEnumType | null;
  password?: string | null;
  photo?: string | null;
  keywords?: string | null;
  type?: ClientStatusType | null;
  acceptEmail?: boolean | null;
  acceptSms?: boolean | null;
  acceptBusinessSms?: boolean | null;
  acceptBusinessEmail?: boolean | null;
  raisonSocial?: string | null;
  function?: string | null;
  affiliateBusinessId?: number | null;
}

/**
 * Login user data
 */
export interface LoginClientInput {
  email?: string | null;
  phone?: string | null;
  password?: string | null;
  lastname?: string | null;
  firstname?: string | null;
  source?: SourceEnumType | null;
  photo?: string | null;
  tag?: string | null;
}

/**
 * Player input
 */
export interface PlayerInput {
  email: string;
  phone?: string | null;
  name?: string | null;
}

/**
 * update click
 */
export interface ProdcutCommandInput {
  product: number;
  totalCount: number;
  productPrice: number;
}

/**
 * Reservation Input
 */
export interface ReservationInput {
  name: string;
  date: any;
  hour: string;
  person: number;
  email: string;
  phone: string;
  message: string;
  clientId: number;
  businessId: number;
  eventId?: number | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
