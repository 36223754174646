/* eslint-disable radix */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from "@apollo/client"
import { Box, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import {
  GetQrcode_getQrcode as IQrcode,
  GetQrcode_getQrcode_template as Itemplate,
  ScanQrcode,
  ScanQrcodeVariables,
  SCAN_QRCODE
} from "../../graphql/qrcode"
import { useApplicationContext } from "../../hooks"
import { Roulette } from "./components/Roulette"
import { useStyles } from "./styles"

export const RouletteGame = (): JSX.Element => {
  const { id } = useParams()
  const classes = useStyles()
  const navigate = useNavigate()
  const [qrcodeData, setQrcodeData] = useState<IQrcode>()
  const [templateData, setTemplateData] = useState<Itemplate>()
  const { business } = useApplicationContext()

  const [scanExec] = useMutation<ScanQrcode, ScanQrcodeVariables>(SCAN_QRCODE)
  const load = () => {
    const qrcode = localStorage.getItem("qrcode")

    if (qrcode && id) {
      const data = JSON.parse(qrcode) as IQrcode
      const template = data.template

      if (parseInt(id) === data.id) {
        setQrcodeData(data)
        setTemplateData(template || undefined)
      } else {
        localStorage.removeItem("qrcode")
        navigate("/tag")
      }
    } else {
      navigate("/tag")
    }
  }
  useEffect(() => {
    load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleGameFinished = async (
    email: string,
    phone?: string,
    name?: string,
    lotId?: number
  ): Promise<void> => {
    const gm = await scanExec({
      variables: {
        qrcodeId: qrcodeData?.id as number,
        lotWinId: lotId,
        businessId: business?.id || 0,
        dataPlayer: {
          email,
          phone,
          name
        }
      }
    })
      .then((res) => {
        if (res.data?.scanQrcode) {
          localStorage.setItem("lot", JSON.stringify(res.data.scanQrcode))
          navigate(`/games/${id}/win/${res.data?.scanQrcode.id}`, {
            replace: true
          })
        } else {
          navigate(`/games/${id}/lost`, { replace: true })
        }
      })
      .catch(() => navigate(`/games/${id}/already_used`, { replace: true }))
  }
  return (
    <Box className={classes.container}>
      {!qrcodeData ? (
        <Box>
          <Box sx={{ textAlign: "center" }}>
            <Typography gutterBottom color="white" variant="h3">
              Chargement des données...
            </Typography>
            <Typography color="white">
              Nous sommes en train de vous préparer le Jeux
            </Typography>
          </Box>
          <Box sx={{ textAlign: "center", p: 3 }}>{/* <Loading /> */}</Box>
        </Box>
      ) : (
        <Box>
          <Box sx={{ mb: 2 }}>
            <Roulette
              bgImage={templateData?.bgImage || ""}
              bgColor={templateData?.bgColor || ""}
              textColor={templateData?.textColor || ""}
              fontFamily={templateData?.fontFamily || ""}
              name={templateData?.name || ""}
              description={templateData?.description || ""}
              spinItemMainColor={templateData?.spinItemMainColor || ""}
              subtitle={templateData?.subtitle || ""}
              fieldEmailPlaceholder={templateData?.fieldEmailPlaceholder || ""}
              fieldCondition={templateData?.fieldCondition}
              fieldPrivacy={templateData?.fieldPrivacy}
              conditionText={templateData?.conditionText || ""}
              privacyText={templateData?.privacyText || ""}
              fieldPhonePlaceholder={templateData?.fieldPhonePlaceholder || ""}
              fieldPhone={templateData?.fieldPhone}
              fieldNamePlaceholder={templateData?.fieldNamePlaceholder || ""}
              fieldName={templateData?.fieldName}
              btnBg={templateData?.btnBg || ""}
              btnText={templateData?.btnText || ""}
              btnTextColor={templateData?.btnTextColor || ""}
              thankText={templateData?.thankText || ""}
              logoUrl={qrcodeData?.logo || ""}
              onSpinFinished={handleGameFinished}
              lots={qrcodeData.lots || []}
              emptyColor={qrcodeData.colorEmpty || ""}
              borderLine={qrcodeData.borderLine || ""}
              countryCode={business?.countryCode || "fr"}
            />
          </Box>
        </Box>
      )}
    </Box>
  )
}
