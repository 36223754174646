/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker"
import { styled } from "@mui/material"
import moment from "moment"
import "dayjs/locale/fr"
import dayjs from "dayjs"
import { TextInputBase } from "./TextInputBase"

type ViewType = "year" | "day" | "month"
interface DateTimePickerBaseProps {
  onChange: (date: Date | null) => void
  label?: string
  value?: Date | string
  format?: string
  views?: readonly ViewType[]
}
export const DatePickerBase: React.FC<DateTimePickerBaseProps> = ({
  onChange,
  label,
  value,
  format,
  views
}): JSX.Element => {
  const StyledInput = styled(TextInputBase)(() => ({
    "& input": {
      textTransform: "capitalize"
    }
  }))
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
      <StaticDatePicker
        renderInput={(props: any) => <StyledInput {...props} />}
        label={label}
        views={views}
        value={value || new Date()}
        inputFormat={format || "DD MMMM YYYY"}
        onChange={onChange}
        minDate={moment(new Date()) as any}
        displayStaticWrapperAs="desktop"
      />
    </LocalizationProvider>
  )
}
