import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  split
} from "@apollo/client"
import { WebSocketLink } from "@apollo/client/link/ws"
import { getMainDefinition } from "@apollo/client/utilities"
import { AuthStorage } from "./utils"

export const apolloClient = (): ApolloClient<NormalizedCacheObject> => {
  const GRAPHQL_URI = process.env.REACT_APP_API_URI as string
  const GRAPHQL_URI_WS = process.env.REACT_APP_API_URI_WS as string

  const user = AuthStorage.isAuth()
  const httpLink = new HttpLink({
    uri: GRAPHQL_URI,

    headers: {
      authorization: `Bearer ${user?.token}`
    }
  })

  const wsLink = new WebSocketLink({
    uri: GRAPHQL_URI_WS,
    options: {
      reconnect: true,
      lazy: true
    }
  })

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      )
    },
    wsLink,
    httpLink
  )

  const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache()
  })
  return client
}
