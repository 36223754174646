import {
  Avatar,
  Box,
  IconButton,
  SxProps,
  Theme,
  Typography
} from "@mui/material"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import React, { FC } from "react"
import { makeStyles } from "@mui/styles"
import { useNavigate } from "react-router-dom"
import { useApplicationContext } from "../../hooks"
import { ThemeType } from "../../types/ThemeType"

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    zIndex: 999
  }
})

export interface HeaderNavigationProps {
  title?: string
  absolute?: boolean
  padding?: number
  withBg?: boolean
  theme?: ThemeType
}
export const HeaderNavigation: FC<HeaderNavigationProps> = ({

  title,
  absolute,
  padding,
  withBg,
  theme
}): JSX.Element => {
  const { user } = useApplicationContext()
  const navigate = useNavigate()
  const classes = useStyles()
  const handleClickProfil = (event: React.SyntheticEvent): void => {
    event.preventDefault()
    navigate("/tag/account")
  }
  const handleGoback = (event: React.SyntheticEvent): void => {
    event.preventDefault()
    navigate(-1)
  }
  const abs: SxProps<Theme> = {
    position: "absolute",
    width: "100%",
    left: 0,
    background: withBg ? "white" : undefined
  }

  return (
    <Box
      className={classes.container}
      sx={absolute ? abs : undefined}
      p={padding || 2}
    >
      <IconButton
        sx={{
          background: "white",
          boxShadow: "0 2px 2px 0 #ccc",
          borderRadius: 2,
          width: 35
        }}
        onClick={handleGoback}
      >
        <ArrowBackIosNewIcon fontSize="small" sx={{ width: 15 }} />
      </IconButton>
      {title ? (
        <Typography
          variant="h5"
          sx={{
            flexGrow: 1,
            textAlign: "center",
            background: theme?.colorBtn,
            fontFamily: theme?.police,
            color: theme?.colorBtnText
          }}
        >
          {title}
        </Typography>
      ) : null}
      <Box sx={{ borderRadius: 50, overflow: "hidden" }}>
        <IconButton
          onClick={handleClickProfil}
          sx={{ width: 35, p: 0, borderRadius: 5 }}
        >
          <Avatar
            src={user?.client?.photo || ""}
            alt=""
            sx={{ width: 35, height: 35 }}
          >
            {user?.client?.firstname?.charAt(0)}
          </Avatar>
        </IconButton>
      </Box>
    </Box>
  )
}
