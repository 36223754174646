import {
  FacebookOutlined,
  Google,
  Instagram,
  Language,
  LinkedIn,
  Twitter
} from "@mui/icons-material"
import { BusinessForClient_businessForClient as IBusiness } from "../graphql/business"

export const businessMedia = (business?: IBusiness): any[] => {
  const medias = [
    {
      media: "Facebook",
      url: business?.facebook,
      icon: <FacebookOutlined />
    },
    {
      media: "Google My Business",
      url: business?.googleBusiness,
      icon: <Google fontSize="medium" />
    },
    {
      media: "LinkedIn",
      url: business?.linkedin,
      icon: <LinkedIn fontSize="medium" />
    },
    {
      media: "Twitter",
      url: business?.twitter,
      icon: <Twitter fontSize="medium" />
    },
    {
      media: "Instagram",
      url: business?.instagram,
      icon: <Instagram fontSize="medium" />
    },
    {
      media: "Website",
      url: business?.siteweb,
      icon: <Language fontSize="medium" />
    }
  ]

  return medias.filter((med) => med.url)
}
