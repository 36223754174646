import {
  createContext,
  FC,
  Reducer,
  useMemo,
  useReducer,
  useState
} from "react"
import { IApplicationContext, IUserAction } from "./Interfaces"

import { reducerUser } from "./reducers"
import {
  BusinessForClient_businessForClient as IBusiness,
  getDevisInfo_clickCollect as DevisInfo,
  OneAbonnement_oneAbonnement as IAbonnement
} from "../../graphql/business"
import { LoginClient_loginClient as IUser } from "../../graphql/user/types"
import { CartType } from "../../types"
import { ThemeType } from "../../types/ThemeType"

export const ApplicationContext = createContext<IApplicationContext>(
  {} as IApplicationContext
)
export const ApplicationContextProvider: FC = ({ children }) => {
  const now = new Date()

  const [business, setBusiness] = useState<IBusiness>()
  const [abonnementCounter, setAbonnementCounter] = useState<IAbonnement>()
  const [theme, setTheme] = useState<ThemeType>()
  const [noBusiness, setNoBusiness] = useState<boolean>()
  const [cart, setCart] = useState<CartType[] | undefined>()
  const [count, setCount] = useState(0)
  const [devis, setDevis] = useState<DevisInfo | undefined>()
  const [user, dispatchUser] = useReducer<Reducer<IUser, IUserAction>>(
    reducerUser,
    {} as IUser
  )
  const [bookingDate, setBookingDate] = useState<Date | null>(now)

  const memoizedContext = useMemo(
    () => ({
      theme,
      setTheme,
      user,
      dispatchUser,
      business,
      setBusiness,
      noBusiness,
      setNoBusiness,
      cart,
      setCart,
      count,
      setCount,
      devis,
      setDevis,
      abonnementCounter,
      setAbonnementCounter,
      bookingDate,
      setBookingDate
    }),
    [
      theme,
      user,
      business,
      noBusiness,
      cart,
      count,
      devis,
      abonnementCounter,
      bookingDate
    ]
  )
  return (
    <ApplicationContext.Provider value={memoizedContext}>
      {children}
    </ApplicationContext.Provider>
  )
}
