import React, { useEffect, useState } from "react"
import { Box, Grid } from "@mui/material"
import moment from "moment"
import { ContainerWithMenu, HeaderPage } from "../../components"
import { useApplicationContext } from "../../hooks"
import { BaseBtn } from "../../components/Button/BaseBtn"
import { BaseTypography } from "../../components/Typography/BaseTypography"

interface hourProps {
  id: number
  day: string
  start: Date
  end: Date
}

const days: {
  name: string
  id: string
  index: number
}[] = [
  {
    name: "Lundi",
    id: "Lundi",
    index: 0
  },
  {
    name: "Mardi",
    id: "Mardi",
    index: 1
  },
  {
    name: "Mercredi",
    id: "Mercredi",
    index: 2
  },
  {
    name: "Jeudi",
    id: "Jeudi",
    index: 3
  },
  {
    name: "Vendredi",
    id: "Vendredi",
    index: 4
  },
  {
    name: "Samedi",
    id: "Samedi",
    index: 5
  },
  {
    name: "Dimanche",
    id: "Dimanche",
    index: 6
  }
]

export const Horaire = () => {
  const [procedHours, setProcedHours] = useState<
    hourProps[] | null | undefined
  >([])
  const { business, businessLoading, theme } = useApplicationContext()

  useEffect(() => {
    if (business?.businessHours) {
      const reverseList = [...business?.businessHours].reverse()
      setProcedHours(
        days.map((day) => {
          const hours = reverseList?.find((hour: any) => hour.day === day.name)
          return {
            id: Number(day.id),
            day: day.name,
            start: hours?.start,
            end: hours?.end
          }
        })
      )
    }
  }, [business?.businessHours])

  return (
    <ContainerWithMenu
      theme={theme}
      padding={1}
      title="Horaire"
      loading={businessLoading}
      noTopNav
    >
      {business?.id && <HeaderPage theme={theme} business={business} />}
      <Box sx={{ p: 2 }}>
        <Box>
          {business?.businessHours && business?.businessHours.length >= 1 ? (
            <>
              <BaseTypography
                theme={theme}
                variant="h1"
                textAlign="center"
                sx={{
                  my: 2,
                  whiteSpace: "nowrap",
                  fontSize: "35px !important"
                }}
              >
                Horaires d&apos; ouverture
              </BaseTypography>
              <Box>
                {procedHours?.map((businessHour) => {
                  return (
                    <Box>
                      <Grid
                        container
                        columnSpacing={2}
                        sx={{
                          my: 2
                        }}
                      >
                        <Grid item xs={3}>
                          <BaseBtn
                            theme={theme}
                            sx={{
                              borderRadius: "3px",
                              textTransform: "none",
                              border: "none"
                            }}
                            variant="outlined"
                            fullWidth
                          >
                            {businessHour.day}
                          </BaseBtn>
                        </Grid>
                        <Grid item xs={9}>
                          <BaseBtn
                            theme={theme}
                            sx={{
                              borderRadius: "3px",
                              textTransform: "none",
                              border: "none"
                            }}
                            variant="outlined"
                            fullWidth
                          >
                            {businessHour.start
                              ? `${moment(businessHour.start)
                                  .format("HH:mm")
                                  .replace(":", "h")}`
                              : "Fermé"}
                            {businessHour.start && businessHour.end
                              ? ` - ${moment(businessHour.end)
                                  .format("HH:mm")
                                  .replace(":", "h")}`
                              : " "}
                          </BaseBtn>
                        </Grid>
                      </Grid>
                    </Box>
                  )
                })}
              </Box>
            </>
          ) : (
            " "
          )}
        </Box>
      </Box>
    </ContainerWithMenu>
  )
}
