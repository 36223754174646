/* eslint-disable @typescript-eslint/no-explicit-any */
import { Email, Lock } from "@mui/icons-material"
import { Box, Button, TextField, Typography, Divider } from "@mui/material"
import React, { FC, useState } from "react"
import { useNavigate } from "react-router-dom"

interface LoginFormProps {
  onLogin?: (data: any) => void
  error?: string
}
export const LoginForm: FC<LoginFormProps> = ({
  onLogin,
  error
}): JSX.Element => {
  const [values, setValues] = useState<any>({
    email: "",
    password: ""
  })

  const navigate = useNavigate()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault()
    const { name, value } = event.target
    setValues((prev: any) => ({
      ...prev,
      [name]: value
    }))
  }
  const handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault()
    onLogin && onLogin(values)
  }
  return (
    <form onSubmit={handleSubmit}>
      <Box>
        <Typography variant="subtitle1">Se connecter</Typography>
      </Box>
      <Divider sx={{ mb: 2 }} />
      <Box mb={1} sx={{ textAlign: "center" }}>
        <TextField
          fullWidth
          label="Votre email"
          name="email"
          type="email"
          value={values.email}
          onChange={handleChange}
          InputProps={{
            endAdornment: <Email />
          }}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="Mot de passe"
          name="password"
          type="password"
          value={values.password}
          onChange={handleChange}
          InputProps={{
            endAdornment: <Lock />
          }}
        />
      </Box>
      <Box>
        {error && (
          <Typography sx={{ textAlign: "center" }} color="error">
            {error}
          </Typography>
        )}
      </Box>
      <Box sx={{ textAlign: "right", py: 1 }}>
        <Button
          sx={{ textTransform: "none", textDecoration: "underline" }}
          onClick={() => navigate("/auth/forgot-password")}
        >
          Mot de passe oublié ?
        </Button>
        <Button
          disabled={!values.email || !values.password}
          variant="contained"
          type="submit"
          sx={{ textTransform: "none" }}
        >
          Se connecter
        </Button>
      </Box>
    </form>
  )
}
