import { gql } from "@apollo/client"

export const RESERVATIONS = gql`
  query ClientResevation($clientId: Float!) {
    clientResevation(clientId: $clientId) {
      business {
        id
        logo
        businessName
        url
      }
      businessId
      client {
        id
        lastname
        firstname
        email
        phone
        photo
      }
      eventParty {
        description
        dateStart
        dateEnd
        id
        img
        title
        hourStart
        hourEnd
      }
      clientId
      createdAt
      date
      email
      eventId
      hour
      id
      message
      name
      person
      phone
      status
      updatedAt
    }
  }
`

export const RESERVATIONS_BUSINESS = gql`
  query ClientBookingBusiness($businessId: Float!, $clientId: Float!) {
    clientBookingBusiness(businessId: $businessId, clientId: $clientId) {
      business {
        id
        logo
        businessName
      }
      businessId
      client {
        id
        lastname
        firstname
        email
        phone
        photo
      }
      eventParty {
        description
        dateStart
        dateEnd
        id
        img
        title
        hourStart
        hourEnd
      }
      clientId
      createdAt
      date
      email
      eventId
      hour
      id
      message
      name
      person
      phone
      status
      updatedAt
    }
  }
`
