import { gql } from "@apollo/client"

export const GET_BUSINESS = gql`
  query BusinessForClient($url: String!) {
    businessForClient(url: $url) {
      id
      logo
      activeBooking
      businessName
      password
      email
      lastname
      firstname
      activity
      phone
      siret
      url
      description
      siteweb
      address
      facebook
      linkedin
      twitter
      mentions
      conditions
      seoDescription
      seoTitle
      metas
      countryCode
      googleTag
      fbTag
      confidentiality
      instagram
      googleBusiness
      coverPhoto
      coverVidz
      animatedCover
      createdAt
      updatedAt
      dinerId
      dinerServicesId
      abonnementId
      diner {
        id
        surplace
        emporter
        drive
        serviceDrive
        delevery
        deliveryNoContact
        createdAt
        updatedAt
      }
      platPhotos {
        id
        url
      }
      menusPhotos {
        id
        url
      }
      businessHours {
        id
        day
        start
        end
        name
        createdAt
      }
      files {
        id
        url
        fileType
        type
        name
        createdAt
      }
      BusinessPalette {
        id
        bgImage
        bgColor1
        bgColor2
        bgColor
        bgViedo
        bgType
        createdAt
        businessId
      }
      BusinessTheme {
        id
        colorBtn
        colorBtnText
        colorCardProduct
        colorTextCardProduct
        bsType
        mediaColor
        police
        colorText
        fontSize
        businessId
      }
      closeType
      paypalImg
      urlPaypalCount
      ReservationConfiguration {
        morning
        afternoon
        night
        startHourMorning
        endHourMorning
        startHourAfternoon
        endHourAfternoon
        startHourNight
        endHourNight
        minutesIntervalle
      }
    }
  }
`

export const GET_ALL_PRODUCT = gql`
  query getAllProduct {
    getAllProduct {
      id
      name
      image
      businessId
      prix
      prixTTC
      prixPromotionnels
      description
      preparationTime
      createdAt
      status
    }
  }
`
export const GET_ALL_CATEGORY = gql`
  query getAllCategory($businessId: Float!, $isClient: Boolean) {
    category(businessId: $businessId, isClient: $isClient) {
      id
      name
      image
      status
    }
  }
`

export const GET_PRODUCT_ID = gql`
  query getProductById($id: Float!) {
    getproductById(productId: $id) {
      id
      name
      image
      prix
      description
      descriptionLong
      prix
      prixTTC
      categoryId
      sousCategoryId
      preparationTime
      status
    }
  }
`

export const GET_CLICK_COLLECT_ID = gql`
  query getClickCollectId($businessId: Float!) {
    clickCollectById(businessId: $businessId) {
      id
      currency
      position
      thousandsSeparator
      decimalSeparator
      decimalNumber
      deferredCommand
    }
  }
`

export const GET_DEVIS_INFO = gql`
  query getDevisInfo {
    clickCollect {
      businessId
      currency
      decimalNumber
      decimalSeparator
      exchangeRate
      id
      position
      thousandsSeparator
    }
  }
`

export const GET_PRODUCT_BY_CAT = gql`
  query GetProductByCategoryId($categoryId: Float!) {
    getProductByCategoryId(categoryId: $categoryId) {
      businessId
      createdAt
      description
      id
      image
      name
      preparationTime
      prix
      prixPromotionnels
      prixTTC
      status
    }
  }
`

export const GET_PRODUCT_BUSINESS = gql`
  query Query($businessId: Float!, $isClient: Boolean) {
    productByBusiness(businessId: $businessId, isClient: $isClient) {
      id
      image
      name
      preparationTime
      prix
      prixPromotionnels
      prixTTC
      status
      description
    }
  }
`

export const GET_PRODUCT_BUSINESS_CAT = gql`
  query getProductBusinessCategory(
    $businessId: Float!
    $categoryId: Float!
    $isClient: Boolean
  ) {
    getProductBusinessCategory(
      businessId: $businessId
      categoryId: $categoryId
      isClient: $isClient
    ) {
      id
      image
      name
      preparationTime
      prix
      prixPromotionnels
      prixTTC
      status
      description
    }
  }
`

export const TEMPLATE_GAME = gql`
  query GetTemplateSpinner($templateId: Float!) {
    getTemplateSpinnerById(templateId: $templateId) {
      acceptNewsletter
      bgColor
      bgImage
      btnBg
      btnText
      btnTextColor
      businessId
      conditionLink
      conditionText
      copyCodeText
      createdAt
      description
      fieldCondition
      fieldEmail
      fieldEmailPlaceholder
      fieldName
      fieldNamePlaceholder
      fieldPhone
      fieldPhonePlaceholder
      fieldPrivacy
      fontFamily
      fontSizeSubtitle
      fontSizeText
      fontSizeTitle
      id
      instructionText
      isSavedAsGlobal
      isSavedByOwner
      name
      privacyLink
      privacyText
      spinItemMainColor
      subtitle
      textColor
      thankText
      title
      updatedAt
      winCloseBtnText
      winMessageCode
      winMessageTitle
    }
  }
`

export const INFO_SUBSCRIPTION = gql`
  query GetSubscriptionList($businessId: Float!) {
    getSubscriptionList(businessId: $businessId) {
      Abonnements {
        abonnementId
        bookingCount
        businessId
        clickCount
        defaultAbonnementId
        itemId
        payementUrl
        status
        stripeSubscriptionId
        subscribeCount
      }
    }
  }
`

export const INFO_ABONNEMENT = gql`
  query OneAbonnement($abonnementId: Float!) {
    oneAbonnement(abonnementId: $abonnementId) {
      numberBooking
      numberClick
      numberSubscription
    }
  }
`
export const WINNER_CLIENT = gql`
  query WinnerPerUser($clientId: Float!) {
    winnerPerUser(clientId: $clientId) {
      id
      retrieve
      lots {
        image
        title
        businessId
      }
      createdAt
      clients {
        email
        phone
      }
    }
  }
`
