/* eslint-disable @typescript-eslint/no-explicit-any */
import { Avatar, Box, styled, Link } from "@mui/material"
import Card from "@mui/material/Card"
import { FC, useEffect, useRef } from "react"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"

import { BusinessForClient_businessForClient as IBusiness } from "../../../graphql/business"
import "../../../style.css"
import { ThemeType } from "../../../types/ThemeType"
import { BaseTypography } from "../../../components/Typography/BaseTypography"
import ReactPlayer from "react-player"
import "./CardCommercant.css"

interface LogoCommercantProps {
  logo: string
  name: string
}

const LogoCommercant: FC<LogoCommercantProps> = ({
  logo,
  name
}): JSX.Element => {
  const StyledBox = styled(Box)(() => ({
    backgroundColor: "transparent",
    height: 100,
    width: 100,
    borderRadius: 50,
    overflow: "hidden",
    margin: "auto",
    position: "relative",
    top: -55,
    borderWidth: 7,
    borderColor: "transparent",
    borderStyle: "solid"
  }))
  return (
    <StyledBox>
      <Avatar src={logo} sx={{ width: 85, height: 85 }}>
        {name.charAt(0)}
      </Avatar>
    </StyledBox>
  )
}

interface CardCommercantProps {
  theme?: ThemeType
  business: IBusiness
}
export const CardCommercant: FC<CardCommercantProps> = ({
  theme,
  business
}): JSX.Element => {
  const formatAddress = (item: string) => {
    return item.split(" ").join("+")
  }
  return (
    <Card
      elevation={0}
      sx={{
        borderRadius: 0,
        backgroundColor: "transparent"
      }}
    >
      {!business.animatedCover ? (
        <CardMedia
          component="img"
          height="140"
          image={business.coverPhoto as string}
          alt={business.firstname}
          sx={{ borderRadius: "0px 0px 40px 40px" }}
        />
      ) : (
        <Box
          sx={{
            height: "200px",
            width: "auto",
            borderRadius: "0 0 40px 40px",
            overflow: "hidden",
            position: "relative"
          }}
        >
          <ReactPlayer
            playing
            autoplay
            muted
            loop
            url={business?.coverVidz || ""}
            height="100%"
            width="100%"
            className="react-player"
          />
        </Box>
      )}
      <Box sx={{ height: 48 }}>
        <LogoCommercant
          logo={business.logo || ""}
          name={business.businessName}
        />
      </Box>
      <CardContent sx={{ p: 0 }}>
        <BaseTypography
          theme={theme}
          sx={{
            textAlign: "center",
            mb: 2,
            fontWeight: 400
          }}
          variant="h1"
        >
          {business.businessName}
        </BaseTypography>
        <BaseTypography
          theme={theme}
          variant="body2"
          sx={{ textAlign: "center" }}
        >
          {business.activity}
        </BaseTypography>
        <BaseTypography
          theme={theme}
          variant="body2"
          sx={{ textAlign: "center" }}
        >
          <Link
            href={"https://www.google.com/maps/place/" + formatAddress(business?.address || "") }
            sx={{ color: theme?.colorText }}
            variant="body2"
          >{business?.address}</Link>
        </BaseTypography>
      </CardContent>
    </Card>
  )
}
