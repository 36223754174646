import { useQuery } from "@apollo/client"
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Container,
  Typography,
  Grid
} from "@mui/material"
import moment from "moment"
import { NavLink, useNavigate } from "react-router-dom"
import {
  ContainerWithMenu,
  HeaderPage,
  QueryResult
} from "../../../../components"
import { BaseTypography } from "../../../../components/Typography/BaseTypography"
import { LISTEVENT } from "../../../../graphql/eventParty/query"
import { ListEventParty_listEventParty } from "../../../../graphql/eventParty/types/ListEventParty"
import { useApplicationContext } from "../../../../hooks"
import tag_logo from "../../../../assets/finaltag_logo.png"

export const ListEventReservation = () => {
  const { business, businessLoading, theme } = useApplicationContext()
  const { data, loading } = useQuery(LISTEVENT, {
    variables: {
      businessId: business?.id,
      active: true
    }
  })
  const navigate = useNavigate()

  return (
    <ContainerWithMenu
      theme={theme}
      noTopNav
      padding={1}
      loading={businessLoading}
    >
      {business?.id && <HeaderPage theme={theme} business={business} />}
      <BaseTypography
        theme={theme}
        variant="h1"
        textAlign="center"
        sx={{ mb: 4, fontWeight: 400 }}
      >
        Liste des événements
      </BaseTypography>

      <Container>
        <Box sx={{ my: 2 }}>
          <QueryResult data={data} loading={loading}>
            <>
              {data?.listEventParty.map(
                (item: ListEventParty_listEventParty) => {
                  return (
                    <Card
                      key={item.id}
                      sx={{ mb: 2, borderRadius: 3 }}
                      elevation={2}
                    >
                      <CardActionArea
                        sx={{
                          ":hover .MuiCardActionArea-focusHighlight": {
                            opacity: 0
                          }
                        }}
                        onClick={(e) => {
                          navigate(`${item.id}`)
                        }}
                      >
                        <CardContent sx={{ display: "flex" }}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                              <Box
                                sx={{
                                  borderRadius: 3,
                                  overflow: "hidden",
                                  display: "flex",
                                  height: 200
                                }}
                              >
                                <img
                                  src={item.img || tag_logo}
                                  alt={item.title}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover"
                                  }}
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={8}>
                              <Box>
                                <Typography variant="subtitle1">
                                  {item.title}
                                </Typography>
                                <Typography component="p">
                                  {moment(item.dateStart).format(
                                    "DD MMMM YYYY"
                                  )}{" "}
                                  -{moment(item.dateEnd).format("DD MMMM YYYY")}
                                </Typography>
                                <Box sx={{ mt: 2 }}>
                                  <Typography>{item.description}</Typography>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  )
                }
              )}
            </>
          </QueryResult>
        </Box>
      </Container>
    </ContainerWithMenu>
  )
}
