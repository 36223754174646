import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  CircularProgress,
  styled
} from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom"
import { ChangeEvent, FC, useState } from "react"
import { Email, Lock } from "@mui/icons-material"
import PhoneInput, { CountryData } from "react-phone-input-2"

import "react-phone-input-2/lib/style.css"

import { CreateClientInput, SourceEnumType, StatusEnumType } from "../../types"
import { useApplicationContext } from "../../hooks"

interface IncriptionProps {
  path?: string
  onClickHasAccount: () => void
}

const PhoneContainer = styled(Box)(() => ({
  "& .react-tel-input .form-control": {
    width: "100%",
    paddingBlock: 10
  }
}))

export const InscriptionEmailForm: FC<IncriptionProps> = ({
  path,
  onClickHasAccount
}): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false)
  const [values, setValues] = useState<CreateClientInput>({
    email: "",
    firstname: "",
    lastname: "",
    password: "",
    phone: "",
    photo: "",
    source: SourceEnumType.FORM
  })
  const [confirmPswd, setConfirmPswd] = useState("")

  const [validForm, setValidForm] = useState({
    email: false,
    firstname: false,
    lastname: false,
    password: false,
    phone: false
  })

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const { value, name } = e.target
    setValues((prev) => ({
      ...prev,
      [name]: value
    }))
  }
  const handleBlur = (e: any) => {
    const { value, name } = e.target
    if (value !== "") {
      setValidForm((last) => ({
        ...last,
        [name]: false
      }))
    } else {
      setValidForm((last) => ({
        ...last,
        [name]: true
      }))
    }
  }
  const handleBlurEmail = (e: any) => {
    const { value, name } = e.target
    const regEx = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/
    if (!regEx.test(value) || value === "") {
      setValidForm((last) => ({
        ...last,
        [name]: true
      }))
    } else {
      setValidForm((last) => ({
        ...last,
        [name]: false
      }))
    }
  }
  const handleBlurPhone = (e: any) => {
    const { value, name } = e.target
    const regex = /^(\+?\d{1,3}|\d{1,4})$/
    if (value === "+" || regex.test(value)) {
      setValidForm((last) => ({
        ...last,
        [name]: true
      }))
    } else {
      setValidForm((last) => ({
        ...last,
        [name]: false
      }))
    }
  }
  const handleChangePhone = (
    value: string,
    data: CountryData,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    handleChange(event)
  }

  const showErrorEmail = (value: CreateClientInput) => {
    return value.email === ""
      ? "veuillez entrer votre addresse mail"
      : "entrer un email valide"
  }
  const navigate = useNavigate()
  const location = useLocation()

  const { signup, dispatchSnack } = useApplicationContext()

  const handleLogin = async (): Promise<void> => {
    try {
      setLoading(true)
      const controlValues = { ...values, email: values.email?.toLowerCase(), status: StatusEnumType.CLIENT }
      const login = await signup(controlValues)
      if (login.success) {
        dispatchSnack({
          open: true,
          severity: "success",
          message: "Authentification réussi !"
        })
        const from = (location?.state as string) || "/tag/"
        navigate(path || from, { replace: true })
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      const err = Object(error).toString()
      const message = err.split(":")[1]
      if (message) {
        dispatchSnack({
          open: true,
          severity: "error",
          message: "Les identifiants que vous avez entrer existe déja"
        })
      }
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box>
            <TextField
              fullWidth
              error={validForm.firstname}
              label="Prénom"
              type="text"
              sx={{ mb: 2 }}
              name="firstname"
              value={values.firstname || ""}
              onChange={(e: any | ChangeEvent<HTMLInputElement>) => {
                handleChange(e)
                handleBlur(e)
              }}
              onBlur={handleBlur}
              helperText={
                validForm.firstname ? " veuillez entrer votre prénom " : null
              }
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <TextField
              error={validForm.lastname}
              fullWidth
              label="Nom"
              name="lastname"
              value={values.lastname || ""}
              onChange={(e: any | ChangeEvent<HTMLInputElement>) => {
                handleChange(e)
                handleBlur(e)
              }}
              onBlur={handleBlur}
              type="text"
              sx={{ mb: 2 }}
              helperText={
                validForm.lastname ? " veuillez entrer votre nom " : null
              }
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <TextField
              error={validForm.email}
              fullWidth
              label="Email"
              name="email"
              value={values.email || ""}
              onChange={(e: any | ChangeEvent<HTMLInputElement>) => {
                handleChange(e)
                handleBlurEmail(e)
              }}
              onBlur={handleBlurEmail}
              type="email"
              InputProps={{
                endAdornment: <Email />
              }}
              sx={{ mb: 2 }}
              helperText={validForm.email ? showErrorEmail(values) : null}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <PhoneContainer>
              <PhoneInput
                enableSearch
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true
                }}
                country="ch"
                value={values.phone || ""}
                onChange={handleChangePhone}
                onBlur={handleBlurPhone}
                preferredCountries={["ch", "fr"]}
              />
              {validForm.phone && <span>ce champ est requis</span>}
            </PhoneContainer>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <TextField
              error={validForm.password}
              fullWidth
              label="Mot de passe"
              name="password"
              value={values.password || ""}
              onChange={(e: any | ChangeEvent<HTMLInputElement>) => {
                handleChange(e)
                handleBlur(e)
              }}
              onBlur={handleBlur}
              type="password"
              sx={{ mb: 2, mr: 2 }}
              InputProps={{
                endAdornment: <Lock />
              }}
              helperText={
                validForm.password ? " veuillez entrer un mot de passe " : null
              }
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <TextField
              fullWidth
              label="Confirmer"
              name="confirm"
              type="password"
              sx={{ mb: 2 }}
              value={confirmPswd}
              InputProps={{
                endAdornment: <Lock />
              }}
              onChange={(e) => setConfirmPswd(e.target.value)}
              error={confirmPswd !== values.password}
              helperText={
                confirmPswd !== values.password
                  ? "les mots de passe de correspondent pas"
                  : null
              }
            />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ textAlign: "center", display: "flex", py: 1 }}>
        <Typography
          onClick={() => {
            onClickHasAccount()
          }}
          sx={{ mr: 4, mt: 1, textDecoration: "underline", cursor: "pointer" }}
        >
          Se connecter à un compte existant
        </Typography>
        <Button
          startIcon={
            loading ? (
              <CircularProgress style={{ width: 25, height: 25 }} />
            ) : null
          }
          variant="contained"
          onClick={() => handleLogin()}
          disabled={
            loading ||
            confirmPswd !== values.password ||
            validForm.email ||
            values.email === "" ||
            validForm.firstname ||
            values.firstname === "" ||
            validForm.lastname ||
            values.lastname === "" ||
            validForm.phone ||
            values.phone === "" ||
            validForm.password ||
            values.password === ""
          }
        >
          S&#39;inscrire
        </Button>
      </Box>
    </>
  )
}
