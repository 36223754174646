/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useState, ChangeEvent } from "react"
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from "@mui/material"
import { Wheel } from "react-custom-roulette"
import { GetQrcode_getQrcode_lots as ILot } from "../../../graphql/qrcode"
import { COLOR } from "../../../utils/color"
import { withStyles } from "@mui/styles"
import PhoneInput from "react-phone-input-2"
import { TemplateInput } from "../Types"
import { useForm, Controller } from "react-hook-form"
import { useApplicationContext } from "../../../hooks"

import Color from "color"
import LogoWithoutBg from "../../../assets/logoSimple.png"
import "./spinner.css"

interface ILost extends ILot {
  option: ""
  winnable?: boolean
  color: string
}
interface ILotExt extends ILot {
  winnable?: boolean
  color: string
}

export const gameCreator = (
  lots: ILot[],
  colorForEmpty?: string
): (ILotExt | ILost)[] => {
  // **
  const background = [
    "#ff8f43",
    "#e91e63",
    "#70bbe0",
    "#0b3351",
    "#673ab7",
    "#f9dd50",
    "#e24a2b",
    "#7fffd4",
    "#673ab7",
    "#00bcd4",
    "#4caf50",
    "#ffeb3b",
    "#ff9800",
    "#607d8b",
    "#9e9e9e",
    "#795548",
    "#f44336"
  ]

  const stripped = [...lots]
  const gameData = stripped.reduce((acc, next): any => {
    const indexColor = Math.floor(Math.random() * 15)
    const randomColor = background[indexColor]
    const winnable = next.scan < next.winFrequence
    const nextD = [
      {
        option: next.title,
        winnable,
        ...next,
        color: next.color || randomColor
      },
      { option: "", winnable: false, color: colorForEmpty || "#fff" }
    ]
    return [...acc, ...nextD]
  }, [])

  return gameData
}

const innerBorderColor = COLOR.PRIMARY
const innerRadius = 0
const radiusLineColor = COLOR.PRIMARY
const radiusLineWidth = 0
const fontSize = 20
const textDistance = 80
const spinDuration = 1.0

type RouletteProps = TemplateInput & {
  lots: ILot[]
  onSpinFinished: (
    email: string,
    phone?: string,
    name?: string,
    lotId?: number
  ) => void
  emptyColor?: string
  borderLine?: string
  logoUrl?: string
  countryCode?: string
  children?: JSX.Element
}

type Inputs = {
  email: string
  phone: string | null
  name: string
  privacy: boolean
  condition: boolean
}

const tintColorFinder = (colorSpinner: any) => {
  const color = Color(colorSpinner).hex()
  const colors = Array.from(Array(4)).map((_, i) => {
    if (i % 2 === 0) {
      return colorSpinner
    }
    return "#ffffff"
  })
  return colors
}
const tintColorText = (colorText: any) => {
  const colors = Array.from(Array(4)).map((_, i) => {
    if (i % 2 === 0) {
      return "#ffffff"
    }
    return colorText
  })
  return colors
}

export const Roulette: FC<RouletteProps> = ({
  bgImage,
  bgColor,
  textColor,
  fontFamily,
  name,
  description,
  spinItemMainColor,
  subtitle,
  fieldEmailPlaceholder,
  fieldCondition,
  fieldPrivacy,
  conditionText,
  privacyText,
  fieldPhone,
  fieldNamePlaceholder,
  fieldName,
  btnBg,
  btnText,
  btnTextColor,
  thankText,
  logoUrl,
  lots,
  emptyColor,
  countryCode,
  onSpinFinished
}): JSX.Element => {
  const [showLaunch, setShowLaunch] = useState<boolean>(true)
  const [mustSpin, setMustSpin] = useState(false)
  const [prizeNumber, setPrizeNumber] = useState(0)
  const { dispatchSnack } = useApplicationContext()
  const [player, setPlayer] = useState({
    email: "",
    phone: "",
    name: ""
  })

  const [isChecked, setIsChecked] = useState(false)
  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors }
  } = useForm<Inputs>()
  const data = gameCreator(lots, emptyColor)

  const bgColors = tintColorFinder(spinItemMainColor || COLOR.PRIMARY)
  const textColorSpinner = tintColorText(spinItemMainColor || COLOR.PRIMARY)
  const handleCheckChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(ev.target.checked)
  }
  const handleSpinClick = (): void => {
    const values = getValues()
    if (values.email && typeof values.email === "string") {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      const urlTrue = emailRegex.test(values.email)
      if (!isChecked) {
        dispatchSnack({
          open: true,
          severity: "error",
          message: "Veuillez accepter les conditions pour continuer"
        })
      } else if (!urlTrue) {
        dispatchSnack({
          open: true,
          severity: "error",
          message: "Veuillez vérifier l'email saisi"
        })
      } else {
        setPlayer({
          email: values.email.toLowerCase(),
          name: values.name,
          phone: values.phone || ""
        })
        setShowLaunch(false)
        setMustSpin(true)
      }
    }
  }
  const handleFinished = (): void => {
    const gagnot = data[prizeNumber] as any
    setMustSpin(false)
    onSpinFinished(player.email, player.phone, player.name, gagnot.id)
  }
  const CustomColorCheckbox = withStyles({
    root: {
      color: `${textColor}`,
      "&$checked": {
        color: `${textColor}`
      }
    },
    checked: {}
  })((props) => (
    <Checkbox
      color="default"
      sx={{ color: `${textColor}` }}
      {...props}
      checked={isChecked}
      onChange={handleCheckChange}
    />
  ))

  const processedLots = (lots: (ILotExt | ILost)[]) => {
    return lots.map((item: ILost | ILotExt, index: number) => ({
      ...item,
      percentageRate: (1 / item.winFrequence).toFixed(3),
      index
    }))
  }

  useEffect(() => {
    const dataProcessed = processedLots(data)
    const randomValue = Math.random()
    const resultGame = dataProcessed.find(
      (item) => Number(randomValue) < Number(item.percentageRate)
    )
    const prizeIndex = resultGame ? resultGame.index : 1
    setPrizeNumber(prizeIndex)
  }, [data])

  useEffect(() => {
    if (!fieldCondition) setIsChecked(true)
  }, [fieldCondition])

  return (
    <>
      <Box
        sx={{
          p: 2,
          borderRadius: "10px",
          background: `${bgImage}`
            ? `url(${bgImage}) center no-repeat`
            : `${bgColor} center no-repeat`,
          backgroundSize: "cover",
          display: "flex",
          justifyContent: "center"
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <Box sx={{ px: 3, textAlign: "center", pt: 2 }}>
            <Avatar
              src={logoUrl}
              style={{ width: 80, height: 80, margin: "auto" }}
            />
          </Box>
          <Box sx={{ my: 4 }}>
            <Typography
              variant="h4"
              sx={{
                color: `${textColor}`,
                fontFamily: `${fontFamily} !important`
              }}
            >
              {name}
            </Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography
              sx={{
                color: `${textColor}`,
                fontFamily: `${fontFamily} !important`
              }}
            >
              {description}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              width: 250,
              height: 250,
              mx: "auto",
              div: {
                width: "100%",
                height: "100%"
              }
            }}
          >
            <Box sx={{ transform: "rotate(300deg)" }}>
              <Wheel
                mustStartSpinning={mustSpin}
                prizeNumber={prizeNumber}
                data={data as any}
                backgroundColors={bgColors}
                textColors={textColorSpinner}
                fontSize={fontSize}
                outerBorderColor="#eeeeee"
                innerBorderWidth={0}
                outerBorderWidth={5}
                innerRadius={innerRadius}
                innerBorderColor={spinItemMainColor || innerBorderColor}
                radiusLineColor="black"
                radiusLineWidth={2}
                spinDuration={spinDuration}
                textDistance={textDistance}
                onStopSpinning={handleFinished}
                perpendicularText={true}
              />
            </Box>
            <img
              src={LogoWithoutBg}
              alt="logo"
              width="40px"
              height="40px"
              style={{
                position: "absolute",
                borderTopLeftRadius: 0,
                zIndex: 100,
                top: "50%",
                transform: "translateY(-50%)"
              }}
            />
            <Box
              sx={{
                width: "65px !important",
                height: "65px !important",
                backgroundColor: spinItemMainColor || innerBorderColor,
                borderRadius: "50%",
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                zIndex: 99
              }}
            ></Box>
            <Box
              sx={{
                width: "25px !important",
                height: "25px !important",
                backgroundColor: spinItemMainColor || innerBorderColor,
                borderRadius: "50%",
                borderBottomRightRadius: 0,
                position: "absolute",
                transform: "rotate(45deg)",
                top: "-5px",
                zIndex: 99
              }}
            ></Box>
            <Box
              sx={{
                width: "90px !important",
                height: "90px !important",
                backgroundColor: "white",
                borderRadius: "50%",
                border: "2px solid black",
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                zIndex: 98
              }}
            ></Box>
          </Box>
          <Box sx={{ my: 2 }}>
            <Typography
              sx={{
                color: `${textColor}`,
                fontFamily: `${fontFamily} !important`
              }}
            >
              {subtitle}
            </Typography>
          </Box>
          <Box sx={{ my: 2 }}>
            <TextField
              variant="outlined"
              placeholder={fieldEmailPlaceholder || ""}
              sx={{
                backgroundColor: "#ffffff",
                borderRadius: 1,
                width: "250px"
              }}
              {...register("email", { required: true })}
            />
            {errors.email && (
              <Box sx={{ color: "red" }}>Ce champ est obligatoire !</Box>
            )}
          </Box>
          <Box sx={{ my: 2 }}>
            {fieldPhone ? (
              <>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Controller
                    control={control}
                    name="phone"
                    rules={{
                      required: true
                    }}
                    render={({ field: { ref, value, ...field } }) => (
                      <PhoneInput
                        {...field}
                        inputProps={{
                          ref,
                          required: true,
                          autoFocus: true
                        }}
                        containerStyle={{
                          width: "auto"
                        }}
                        dropdownStyle={{
                          width: "250px"
                        }}
                        specialLabel=""
                        country={countryCode || "fr"}
                        value={value}
                        enableSearch
                        preferredCountries={["ch", "fr"]}
                        inputStyle={{
                          backgroundColor: "#ffffff",
                          borderRadius: 1,
                          width: "250px",
                          height: "40px"
                        }}
                      />
                    )}
                  />
                </Box>
                {errors.phone && (
                  <Box sx={{ color: "red" }}>Ce champ est obligatoire !</Box>
                )}
              </>
            ) : (
              ""
            )}
          </Box>
          <Box sx={{ my: 2 }}>
            {fieldName ? (
              <Box>
                <TextField
                  variant="outlined"
                  placeholder={fieldNamePlaceholder || ""}
                  sx={{
                    backgroundColor: "#ffffff",
                    borderRadius: 1,
                    width: "250px"
                  }}
                  {...register("name", { required: true })}
                />
                {errors.name && (
                  <Box sx={{ color: "red" }}>Ce champ est obligatoire !</Box>
                )}
              </Box>
            ) : (
              ""
            )}
          </Box>
          <Box>
            {fieldPrivacy && (
              <Grid container item alignItems="center" justifyContent="center">
                <Box
                  sx={{
                    width: "80%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start"
                  }}
                >
                  <Grid>
                    <FormControlLabel
                      control={<CustomColorCheckbox />}
                      label=""
                    />
                  </Grid>
                  <Grid>
                    <Typography
                      sx={{
                        color: `${textColor}`,
                        fontFamily: `${fontFamily} !important`,
                        textAlign: "left"
                      }}
                    >
                      {privacyText}
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
            )}

            {fieldCondition && (
              <Grid container alignItems="center" justifyContent="center">
                <Box
                  sx={{
                    width: "80%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start"
                  }}
                >
                  <Grid>
                    <FormControlLabel
                      control={<CustomColorCheckbox />}
                      label=""
                    />
                  </Grid>
                  <Grid>
                    <Typography
                      sx={{
                        color: `${textColor}`,
                        fontFamily: `${fontFamily} !important`,
                        textAlign: "left"
                      }}
                    >
                      {conditionText}
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
            )}
            {showLaunch && (
              <Button
                variant="contained"
                sx={{
                  background: `${btnBg}`,
                  color: `${btnTextColor}`,
                  fontFamily: `${fontFamily} !important`
                }}
                onClick={handleSubmit(handleSpinClick)}
              >
                {btnText}
              </Button>
            )}
          </Box>
          <Box sx={{ my: 2 }}>
            <Typography
              sx={{
                color: `${textColor}`,
                fontFamily: `${fontFamily} !important`
              }}
            >
              {thankText}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  )
}
