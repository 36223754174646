import { Groups } from "@mui/icons-material"
import { Box, Card, CardContent, Typography } from "@mui/material"
import { FC } from "react"
import { useNavigate } from "react-router-dom"
import { BaseBtn } from "../../../../components/Button/BaseBtn"
import { useApplicationContext } from "../../../../hooks"

interface ReservationCountProps {
  count: number
  disabled?: boolean
  onDecrement: () => void
  onIncrement: () => void
}

export const ReservationCount: FC<ReservationCountProps> = ({
  count,
  disabled,
  onDecrement,
  onIncrement
}) => {
  const { theme } = useApplicationContext()
  const navigate = useNavigate()
  return (
    <>
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 2,
              justifyContent: "center"
            }}
          >
            <Groups sx={{ mr: 1 }} />
            <Typography>Pour combien de personne(s) ?</Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <BaseBtn
              theme={theme}
              disabled={count <= 1}
              variant="contained"
              onClick={() => {
                onDecrement()
              }}
            >
              -
            </BaseBtn>
            <Typography variant="h2">{count}</Typography>
            <BaseBtn
              theme={theme}
              disabled={disabled}
              variant="contained"
              onClick={() => onIncrement()}
            >
              +
            </BaseBtn>
          </Box>
        </CardContent>
      </Card>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center"
        }}
      >
        <BaseBtn
          theme={theme}
          variant="contained"
          onClick={(e) => navigate("date")}
        >
          suivant
        </BaseBtn>
      </Box>
    </>
  )
}
