import { useLazyQuery, useMutation } from "@apollo/client"
import { useCallback, useState } from "react"
import { CREATE_RESERVATION } from "../graphql/reservations/mutation"
import { RESERVATIONS } from "../graphql/reservations/query"
import {
  ClientResevation,
  ClientResevationVariables
} from "../graphql/reservations/types/ClientResevation"
import {
  CreateReservation,
  CreateReservationVariables
} from "../graphql/reservations/types/CreateReservation"
import { ReservationInput } from "../types"
import { useApplicationContext } from "./useApplicationContext"

export const useReservation = () => {
  const { user } = useApplicationContext()
  const [currentClient, setCurrentClient] = useState(0)
  const [loadReservationFn, { data, loading, error, refetch }] = useLazyQuery<
    ClientResevation,
    ClientResevationVariables
  >(RESERVATIONS)
  const [bookingFn, { loading: loadingBooking }] = useMutation<
    CreateReservation,
    CreateReservationVariables
  >(CREATE_RESERVATION)
  const useId = localStorage.getItem("td_user_auth")
  const loadReservation = useCallback(async () => {
    if (useId) setCurrentClient(JSON.parse(useId)?.client?.id)
    if (!user?.client?.id && currentClient) {
      return
    }
    if (useId) {
      setTimeout(async () => {
        await loadReservationFn({
          variables: {
            clientId: JSON.parse(useId)?.client?.id
          }
        })
      }, 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const bookTable = async (data: ReservationInput) => {
    try {
      const book = await bookingFn({
        variables: {
          data
        }
      })
      if (book.data) {
        loadReservation()
      }
    } catch (error) {
      return error
    }
  }
  return {
    reservations: data?.clientResevation,
    loading,
    error,
    loadReservation,
    loadingBooking,
    bookTable,
    refetch
  }
}
