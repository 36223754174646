import {
  Avatar,
  Box,
  BoxProps,
  Card,
  CardMedia,
  IconButton,
  styled
} from "@mui/material"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import { FC } from "react"
import { BusinessForClient_businessForClient as IBusiness } from "../../graphql/business"
import { useApplicationContext } from "../../hooks"
import { useNavigate } from "react-router-dom"
import { BaseBtn } from "../Button/BaseBtn"
import { ThemeType } from "../../types/ThemeType"

type LogoCommercantProps = LogoCommercantFirstProps & BoxProps

type LogoCommercantFirstProps = {
  logo: string
  name: string
}
const LogoCommercant = ({
  logo,
  name,
  sx,
  onClick
}: LogoCommercantProps): JSX.Element => {
  const StyledBox = styled(Box)(() => ({
    backgroundColor: "transparent",
    height: 100,
    width: 100,
    borderRadius: 50,
    overflow: "hidden",
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
    top: -55,
    borderWidth: 7,
    borderColor: "transparent",
    borderStyle: "solid"
  }))
  return (
    <StyledBox sx={sx} onClick={onClick}>
      <Avatar src={logo} sx={{ width: 85, height: 85 }}>
        {name.charAt(0)}
      </Avatar>
    </StyledBox>
  )
}

interface CardCommercantProps {
  theme?: ThemeType
  business: IBusiness
  img?: string
}

export const HeaderPage: FC<CardCommercantProps> = ({
  theme,
  business,
  img
}): JSX.Element => {
  const { user } = useApplicationContext()
  const navigate = useNavigate()

  const handleGoback = (e: React.SyntheticEvent) => {
    e.preventDefault()
    navigate(-1)
  }

  const handleClickProfil = (e: React.SyntheticEvent): void => {
    e.preventDefault()
    navigate("/tag/account")
  }

  return (
    <Card
      style={{
        position: "relative",
        zIndex: 1,
        backgroundColor: "transparent"
      }}
      elevation={0}
    >
      {!img ? (
        <CardMedia
          component="img"
          height="140"
          image={business.coverPhoto as string}
          alt={business.firstname}
          sx={{ borderRadius: "0px 0px 40px 40px" }}
        />
      ) : (
        <CardMedia
          component="img"
          height="140"
          image={img || ""}
          alt="green iguana"
          sx={{ borderRadius: "0px 0px 40px 40px" }}
        />
      )}
      <Box sx={{ height: 48, backgroundColor: "transparent" }}>
        <LogoCommercant
          logo={business.logo || ""}
          name={business.businessName}
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/tag")}
        />
      </Box>
      <BaseBtn
        theme={theme}
        variant="outlined"
        sx={{
          position: "absolute",
          top: 10,
          left: 10,
          borderRadius: "50%",
		  minWidth: 0,
		  padding: "7px 10px"
        }}
        onClick={handleGoback}
      >
        <ArrowBackIosNewIcon fontSize="small" sx={{ width: 15 }} />
      </BaseBtn>
      {user?.client ? (
        <Box
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            borderRadius: 50,
            overflow: "hidden"
          }}
        >
          <IconButton
            onClick={handleClickProfil}
            sx={{ width: 35, p: 0, borderRadius: 5 }}
          >
            <Avatar
              src={user?.client?.photo || ""}
              alt=""
              sx={{ width: 35, height: 35 }}
            >
              {user?.client?.firstname?.charAt(0)}
            </Avatar>
          </IconButton>
        </Box>
      ) : (
        ""
      )}
    </Card>
  )
}
