import "./App.css"
import { ApolloProvider } from "@apollo/client"
import { BrowserRouter } from "react-router-dom"
import { CssBaseline, ThemeProvider } from "@mui/material"
import { StylesProvider } from "@mui/styles"
import moment from "moment"
import "moment/locale/fr"
import { HelmetProvider } from "react-helmet-async"
import dayjs from "dayjs"
import { apolloClient } from "./apolloClient"
import { MainRouter } from "./routers"
import theme from "./theme"
import { ContextProvider } from "./contexts"

moment.defineLocale("fr", null)
moment.locale("fr")
dayjs.locale("fr")
const App = (): JSX.Element => {
  const client = apolloClient()

  return (
    <HelmetProvider>
      <BrowserRouter>
        <ApolloProvider client={client}>
          <ContextProvider>
            <ThemeProvider theme={theme}>
              <StylesProvider injectFirst>
                <CssBaseline />
                <MainRouter />
              </StylesProvider>
            </ThemeProvider>
          </ContextProvider>
        </ApolloProvider>
      </BrowserRouter>
    </HelmetProvider>
  )
}

export default App
