import { useQuery } from "@apollo/client"
import {
  ClientCommand,
  ClientCommandVariables,
  COMMANDS
} from "../graphql/commands"
import { useApplicationContext } from "./useApplicationContext"

export const useCommands = () => {
  const { user } = useApplicationContext()
  const info = user?.client
  const { data, loading, error, refetch } = useQuery<
    ClientCommand,
    ClientCommandVariables
  >(COMMANDS, {
    variables: {
      clientId: info?.id as any
    },
    skip: !info
  })

  if (user?.client) refetch()

  return { commands: data?.clientCommand, loading, error, refetch }
}
