import { useQuery } from "@apollo/client"
import { TABLES } from "../graphql/tables/query"
import { Tables, TablesVariables } from "../graphql/tables/types/Tables"
import { useApplicationContext } from "./useApplicationContext"

export const useTables = () => {
  const { business } = useApplicationContext()
  const { data, loading, error } = useQuery<Tables, TablesVariables>(TABLES, {
    variables: {
      businessId: business?.id as any
    },
    skip: !business?.id
  })
  return {
    tables: data?.tables,
    loading,
    error
  }
}
