import { Badge, Box, CircularProgress, styled } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { FC, ReactNode, useEffect, useState } from "react"
import { NavLink, useParams } from "react-router-dom"
import { HeaderNavigation } from "."
import {
  MenuStatusEnum,
  useApplicationContext,
  UserAuthStateEnum
} from "../../hooks"
import { BottomMenus } from "../../routers/BottomMenus"
import { ThemeType } from "../../types/ThemeType"
import { HeaderNavigationProps } from "./HeaderNavigation"

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "100vh"
  }
})
interface BoxMenuProps {
  theme?: ThemeType
  active?: boolean
  sx?: any
}
const BoxMenu: FC<BoxMenuProps> = ({
  active,
  children,
  sx,
  theme
}): JSX.Element => {
  const StyledBox = styled(Box)(() => ({
    "& span": {
      "::before": {
        color: active ? `${theme?.colorBtn} !important` : "black"
      }
    },
    display: "flex",
    alignItems: "center",
    width: active ? "80% !important" : "max-content",
    height: active ? "57px !important " : "max-content",
    color: active ? theme?.colorBtn : "black",
    justifyContent: "center"
  }))
  return <StyledBox sx={sx}>{children}</StyledBox>
}

interface ContainerWithMenuProps extends HeaderNavigationProps {
  theme?: ThemeType
  componentAfterMenu?: ReactNode
  noTopNav?: boolean
  loading?: boolean
}
export const ContainerWithMenu: FC<ContainerWithMenuProps> = ({
  noTopNav,
  children,
  theme,
  componentAfterMenu,
  title,
  absolute,
  padding,
  withBg,
  loading
}): JSX.Element => {
  const classes = useStyles()
  const { id } = useParams()
  const { cart, userAuthStatus } = useApplicationContext()
  let sommeStock = 0
  cart?.forEach((car) => {
    sommeStock += car.stock
  })
  const [currentMenus, setCurrentMenus] = useState(BottomMenus)
  useEffect(() => {
    if (userAuthStatus === UserAuthStateEnum.AUTHENTICATED) {
      const newMenus = BottomMenus.filter(
        (item) => item.status !== MenuStatusEnum.NOTCONNECTED
      )

      setCurrentMenus(newMenus)
    } else {
      const newMenus = BottomMenus.filter(
        (item) => item.status !== MenuStatusEnum.CONNECTED
      )

      setCurrentMenus(newMenus)
    }
  }, [userAuthStatus])

  return (
    <Box
      className={classes.container}
      sx={{ maxWidth: 768, margin: "auto", pb: 8 }}
    >
      <Box sx={{ flexGrow: 1, overflow: "auto" }}>
        {noTopNav ? null : (
          <HeaderNavigation
            title={title}
            absolute={absolute}
            padding={padding}
            withBg={withBg}
            theme={theme}
          />
        )}
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%"
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          children
        )}
      </Box>
      <Box
        sx={{
          width: { xs: "100%", sm: "60%" },
          maxWidth: 460,
          minHeight: "50px",
          mx: "auto",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          borderRadius: "45px 45px 0px 0px",
          position: "fixed",
          bottom: 0,
          left: "50%",
          transform: "translateX(-50%)"
        }}
      >
        <Box
          sx={{
            width: "80%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center"
          }}
        >
          {currentMenus.map((menu) => {
            return (
              <NavLink
                key={menu.id}
                to={menu.path.replace(":id", id as string)}
                style={(isActive) => ({
                  width: "80px",
                  height: "50px",
                  textDecoration: "none",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                })}
                end
              >
                {({ isActive }) => (
                  <BoxMenu
                    theme={theme}
                    active={isActive}
                    sx={{
                      width:
                        menu.id === "CART" ? "100% !important" : "max-content",
                      height:
                        menu.id === "CART" ? "70px !important" : "max-content",
                      borderRadius: menu.id === "CART" ? "50%" : "20%",
                      backgroundColor:
                        menu.id === "CART" ? "white" : "transparent",
                      transform:
                        menu.id === "CART"
                          ? "translateY(-9px)"
                          : "translateY(0px)"
                    }}
                  >
                    {menu.id === "CART" ? (
                      <Badge
                        badgeContent={sommeStock}
                        sx={{
                          mb: "6px",
                          color: "white",
                          ".MuiBadge-anchorOriginTopRightRectangular": {
                            background: theme?.colorBtn
                          }
                        }}
                      >
                        {menu.icon}
                      </Badge>
                    ) : (
                      menu.icon
                    )}
                  </BoxMenu>
                )}
              </NavLink>
            )
          })}
        </Box>
        {componentAfterMenu}
      </Box>
    </Box>
  )
}
