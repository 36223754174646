import { ChangeEvent, useEffect, useState } from "react"
import { Route, Routes, useParams, useNavigate } from "react-router-dom"
import { CustomedDescription } from "./components/CustomedDescription"
import { dataBar } from "./dataBar"
import { ProgressBarReservation } from "./components/ProgressBarReservation"
import { ReservationCount } from "./components/ReservationCount"
import { CustomDateEvent } from "./components/CustomDateEvent"
import { useApplicationContext } from "../../../hooks"
import Swal from "sweetalert2"
import {
  ContainerWithMenu,
  CustomModal,
  HeaderPage,
  QueryResult
} from "../../../components"
import { ModalBooking } from "../ModalBooking/ModalBooking"
import { useQuery } from "@apollo/client"
import { DETAILSEVENT } from "../../../graphql/eventParty/query"
import { useReservation } from "../../../hooks/useReservation"
import { Container } from "@mui/system"
import { DetailEventPartyVariables, DetailEventParty } from "../../../graphql/eventParty/types/DetailEventParty"
import moment from "moment"

export const EventItem = () => {
  const navigate = useNavigate()
  const [listDate, setListDate] = useState<Date[]>([])
  const [phone, setPhone] = useState("")
  const { bookTable } = useReservation()
  const { user, theme, business, businessLoading } = useApplicationContext()
  const { idEvents } = useParams()
  const { data, loading } = useQuery<DetailEventParty, DetailEventPartyVariables>(DETAILSEVENT, {
    variables: {
      businessId: business?.id || 0,
      detailEventPartyId: Number(idEvents)
    }
  })
  const [count, setCount] = useState(1)
  const [date, setDate] = useState<string>()
  const [open, setOpen] = useState<boolean>(false)
  const [restPlace, setRestPlace] = useState<number>(0)
  const eventBar = dataBar.filter((bar) => bar.id !== 3)
  const handleDecrement = () => {
    if (count > 0) {
      setCount(count - 1)
    }
  }
  const handleIncrement = () => {
    if (count >= restPlace) {
      setCount(restPlace)
    } else {
      setCount(count + 1)
    }
  }
  const handleChangeDate = (
    e: ChangeEvent<HTMLInputElement>,
    value?: string
  ) => {
    if (e.target?.name === "date") {
      const dateReservation = e.target.value
      setDate(dateReservation)
    } else {
      setPhone(value || "")
    }
  }
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setOpen(!open)
  }
  const arrDate = (start: Date, end: Date): Date[] => {
    const startDate = new Date(start)
    const endDate = new Date(end)
    const timeDiff = endDate.getTime() - startDate.getTime()
    const numDays = timeDiff / (1000 * 60 * 60 * 24)
    const dateArr = Array.from(
      { length: numDays },
      (v, i) => new Date(startDate.getTime() + i * (1000 * 60 * 60 * 24))
    )
    return [...dateArr, endDate]
  }

  useEffect(() => {
    if (data?.detailEventParty) {
      if (data?.detailEventParty.guest !== null && data?.detailEventParty.guestCount !== null) {
        setRestPlace(data?.detailEventParty?.guest - data?.detailEventParty?.guestCount)
      }
      try {
        const dataDetails = data?.detailEventParty
        setListDate(arrDate(dataDetails.dateStart, dataDetails.dateEnd))
        setDate(dataDetails.dateStart)
      } catch (error) {
        console.log("error:", error)
      }
    }
  }, [data])

  return (
    <ContainerWithMenu
      theme={theme}
      padding={1}
      title="Réservation"
      loading={businessLoading}
      noTopNav
    >
      {business?.id && (
        <HeaderPage
          img={data?.detailEventParty?.img || ""}
          theme={theme}
          business={business}
        />
      )}
      <Container>
        <QueryResult data={data} loading={loading}>
          <>
            {data?.detailEventParty && (
              <CustomedDescription eventDetails={data?.detailEventParty} />
            )}
            {eventBar && <ProgressBarReservation data={eventBar} />}
            <Routes>
              <Route
                index
                element={
                  <ReservationCount
                    count={count}
                    disabled={count >= restPlace}
                    onDecrement={handleDecrement}
                    onIncrement={handleIncrement}
                  />
                }
              />
              <Route
                path="date"
                element={
                  data?.detailEventParty && (
                    <CustomDateEvent
                      hour={`${moment(data?.detailEventParty.hourStart).format("HH:mm")} - ${moment(data?.detailEventParty.hourEnd).format("HH:mm")}`}
                      listDate={listDate}
                      onChange={handleChangeDate}
                      onClick={handleClick}
                    />
                  )
                }
              />
            </Routes>
            {user?.client ? (
              <CustomModal
                open={open}
                theme={theme}
                setOpen={setOpen}
                onConfirm={() => {
                  bookTable({
                    date: date,
                    businessId: business?.id || 0,
                    clientId: user?.client?.id || 0,
                    email: user?.client?.email || "",
                    message: "",
                    person: count,
                    phone: phone || user?.client?.phone || "",
                    name: user?.client?.firstname || "",
                    hour: date || "",
                    eventId: Number(idEvents)
                  }).then(() =>
                    Swal.fire({
                      title: "Réservation effectué",
                      icon: "success",
                      showConfirmButton: false,
                      showCloseButton: true
                    })
                      .then(() => navigate("/tag/booking-list"))
                      .catch(() =>
                        Swal.fire({
                          title: "Réservation non-envoyé",
                          icon: "error",
                          showConfirmButton: false,
                          showCloseButton: true
                        })
                      )
                  )
                }}
              >
                <ModalBooking
                  date={date}
                  nbPrs={count}
                  infos={{
                    name: user?.client?.firstname || "",
                    mail: phone || user?.client?.email || "",
                    phone: user?.client?.phone || ""
                  }}
                />
              </CustomModal>
            ) : (
              ""
            )}
          </>
        </QueryResult>
      </Container>
    </ContainerWithMenu>
  )
}
