import { Add, Delete, Remove } from "@mui/icons-material"
import { Box, Button, Divider, Grid, Typography } from "@mui/material"
import { useState } from "react"
import { useApplicationContext } from "../../hooks"
import { BSType } from "../../types"
import { ThemeType } from "../../types/ThemeType"
import { BaseBtn } from "../Button/BaseBtn"
import { FormatPrice } from "../FormatPriceBusiness"
import { BaseTypography } from "../Typography/BaseTypography"

type CartItemProps = {
  id: string | number
  image?: string
  theme?: ThemeType
  name: string
  description?: string
  price: number
  stock: number
  onPopCart: (id: number) => void
}

export const CartItem = ({
  id,
  image,
  name,
  theme,
  description,
  price,
  stock,
  onPopCart
}: CartItemProps) => {
  const [itemCount, setItemCount] = useState(stock || 0)
  const { cart, setCart, count, setCount } = useApplicationContext()
  const currentCart = cart || []
  const procedPrice = (price * itemCount).toFixed(2)
  const onChangeStock = (id: number | string, stock: number, price: number) => {
    const arrCart = currentCart.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          stock,
          totalPrice: price
        }
      }

      return item
    })
    localStorage.setItem("mycart", JSON.stringify(arrCart))
    setCart(arrCart)
  }
  const incrementCount = () => {
    setItemCount(itemCount + 1)
    setCount(count + 1)
    onChangeStock(id, itemCount + 1, Number(procedPrice))
  }
  const decrementCount = () => {
    if (itemCount <= 1) {
      setItemCount(1)
      onChangeStock(id, itemCount - 1, Number(procedPrice))
      return
    }
    setItemCount(itemCount - 1)
    setCount(count - 1)
    onChangeStock(id, itemCount - 1, Number(procedPrice))
  }
  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <Box sx={{ maxWidth: 100, width: "100%", height: 100 }}>
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover"
              }}
              src={image}
              alt={name}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h5" sx={{ mb: 1 }}>
            {name}
          </Typography>
          <FormatPrice
            variant="h5"
            component="div"
            sx={{ mb: 2 }}
            priceItem={price || 0}
          />
          <Typography sx={{ mb: 2 }}>{description}</Typography>
          <Box sx={{ display: "flex" }}>
            {theme?.bsType === BSType.SQUARE ? (
              <Button
                onClick={decrementCount}
                sx={{
                  minWidth: 20,
                  p: 1,
                  fontSize: "1.5em",
                  "& span": {
                    "::before": {
                      width: 20,
                      height: 20,
                      color: `${theme?.colorBtn} !important`
                    }
                  }
                }}
                size="small"
              >
                <span className="icon-Illustration-10"></span>
              </Button>
            ) : (
              <BaseBtn
                onClick={decrementCount}
                theme={theme}
                sx={{ minWidth: 20, p: 1, fontSize: "1.5em" }}
                size="small"
              >
                <Remove />
              </BaseBtn>
            )}
            <BaseTypography
              variant="h5"
              sx={{
                display: "flex",
                alignItems: "center",
                mx: 1,
                textShadow: "1px 1px rgba(0,0,0, 0.4)"
              }}
            >
              {itemCount}
            </BaseTypography>
            {theme?.bsType === BSType.SQUARE ? (
              <Button
                onClick={incrementCount}
                sx={{
                  minWidth: 20,
                  p: 1,
                  fontSize: "1.5em",
                  "& span": {
                    "::before": {
                      width: 20,
                      height: 20,
                      color: `${theme?.colorBtn} !important`
                    }
                  }
                }}
                size="small"
              >
                <span className="icon-Illustration-11"></span>
              </Button>
            ) : (
              <BaseBtn
                onClick={incrementCount}
                theme={theme}
                sx={{ minWidth: 20, p: 1, fontSize: "1.5em" }}
                size="small"
              >
                <Add />
              </BaseBtn>
            )}
          </Box>
        </Grid>
        <Grid item container xs={3} alignContent="space-between">
          <Grid item textAlign="right" xs={12}>
            <Button
              sx={{
                minWidth: 36,
                p: 1,
                ml: 2,
                color: theme?.colorBtn,
                border: `1px solid ${theme?.colorBtn}`
              }}
              variant="outlined"
              onClick={() => onPopCart(Number(id))}
            >
              <Delete />
            </Button>
          </Grid>
          <Grid item textAlign="right" xs={12}>
            <FormatPrice
              variant="h5"
              component="div"
              sx={{ mb: 2 }}
              priceItem={procedPrice || 0}
            />
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ my: 1 }} />
    </Box>
  )
}
