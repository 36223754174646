import { Box, Typography } from "@mui/material"
import { Helmet } from "react-helmet"
import { Route, Routes } from "react-router-dom"
import { Loading } from "../../../components"
import { AuthContainer } from "../../../components/AuthContainer1"
import { useApplicationContext } from "../../../hooks"
import { bg } from "../Signin1/Login"
import { useStyles } from "../Signin1/styles"
import { ForgotPassword } from "./ForgotPassword"
import { ResetPassword } from "./ResetPassword"

export const AuthRoute = () => {
  const { business, businessLoading } = useApplicationContext()
  const classes = useStyles()
  const palette = business?.BusinessPalette
  return (
    <Box>
      {businessLoading ? (
        <Box
          sx={{
            marginTop: "25%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Loading />
        </Box>
      ) : (
        <AuthContainer
          bg={bg(palette)?.bg as string}
          bgType={bg(palette)?.type}
          business={business}
        >
          <Helmet>
            <meta charSet="utf-8" />
            <title>{business?.seoTitle || business?.businessName}</title>
            <meta
              name="description"
              content={business?.seoDescription || business?.description || ""}
            />
          </Helmet>
          <Box sx={{ p: 2 }}>
            <Box>
              <Box sx={{ textAlign: "center" }}>
                <Box sx={{ mb: 1 }}>
                  <img
                    src={business?.logo || ""}
                    alt="chef"
                    width="120"
                    height="120"
                    style={{ objectFit: "cover" }}
                  />
                </Box>
                <Box>
                  <Typography className={classes.welcome} variant="h3">
                    {business?.businessName}
                  </Typography>
                  <Typography>{business?.address}</Typography>
                  <Box
                    sx={{
                      p: 2
                    }}
                  >
                    <Typography variant="body1">
                      {business?.description}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ p: 2 }}>
                <Routes>
                  <Route path="forgot-password" element={<ForgotPassword />} />
                  <Route
                    path="reset-password/:token"
                    element={<ResetPassword />}
                  />
                </Routes>
              </Box>
            </Box>
          </Box>
        </AuthContainer>
      )}
    </Box>
  )
}
