/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  TextField,
  Typography,
  styled,
  TextFieldProps,
  BoxProps
} from "@mui/material"
import { FC } from "react"

const Label = styled(Typography)(() => ({}))
const CustomTextField = styled(TextField)(() => ({
  borderRadius: 10
}))
interface TextInputBaseProps {
  label?: string
  containerProps?: BoxProps
  errorMessage?: string
}
export const TextInputBase: FC<
  TextInputBaseProps & Omit<TextFieldProps, "label">
> = ({ label, containerProps, errorMessage, ...props }): JSX.Element => {
  return (
    <Box {...containerProps}>
      <Label variant="subtitle2">{label}</Label>
      <CustomTextField fullWidth {...props} error={!!errorMessage} />
      {errorMessage && <Typography color="error">{errorMessage}</Typography>}
    </Box>
  )
}
