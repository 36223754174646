import { Box, Container } from "@mui/material"
import { BaseBtn } from "../../../components/Button/BaseBtn"
import { useApplicationContext, UserAuthStateEnum } from "../../../hooks"
import CalendarViewDayOutlinedIcon from "@mui/icons-material/CalendarViewDayOutlined"
import { useNavigate } from "react-router-dom"
import { ContainerWithMenu, HeaderPage, LoginModal } from "../../../components"
import { useState } from "react"

export const LandingReservation = () => {
  const { theme, businessLoading, business, userAuthStatus } =
    useApplicationContext()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [url, setUrl] = useState("")
  const handleLogging = (status: boolean) => {
    if (status) {
      return navigate(url)
    }

    return navigate("/login")
  }

  return (
    <ContainerWithMenu
      theme={theme}
      padding={1}
      title="Réservation"
      loading={businessLoading}
      noTopNav
    >
      {business?.id && <HeaderPage theme={theme} business={business} />}
      <Container>
        <Box
          sx={{
            height: "50vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >
          <Box
            sx={{
              mb: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center"
            }}
          >
            <BaseBtn
              theme={theme}
              className="buttonClass"
              variant="outlined"
              onClick={(e) => {
                if (userAuthStatus === UserAuthStateEnum.AUTHENTICATED) {
                  return navigate("sample-reservation")
                }
                setUrl("sample-reservation")
                return setOpen(true)
              }}
              sx={{
                borderRadius: "5px",
                borderWidth: "0px",
                borderColor: "rgb(0, 0, 0)",
                width: "300px",
                height: "60px",
                "&:hover": {
                  borderColor: "rgb(0, 0, 0)"
                }
              }}
            >
              <span
                style={{ width: "50px" }}
                className="icon-Illustration-7"
              ></span>
              Réservation simple
            </BaseBtn>
          </Box>
          <Box
            sx={{
              mb: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center"
            }}
          >
            <BaseBtn
              theme={theme}
              variant="outlined"
              onClick={(e) => {
                if (userAuthStatus === UserAuthStateEnum.AUTHENTICATED) {
                  return navigate("events")
                }
                setUrl("events")
                return setOpen(true)
              }}
              sx={{
                borderRadius: "5px",
                borderWidth: "0px",
                borderColor: "rgb(0, 0, 0)",
                width: "300px",
                height: "60px",
                "&:hover": {
                  borderColor: "rgb(0, 0, 0)"
                }
              }}
            >
              <CalendarViewDayOutlinedIcon sx={{ mr: 2 }} />
              Réservation événement
            </BaseBtn>
          </Box>
        </Box>
      </Container>
      <LoginModal
        onLogStatus={handleLogging}
        open={open}
        onClose={() => setOpen(false)}
      />
    </ContainerWithMenu>
  )
}
