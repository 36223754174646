import { FC } from "react"
import { ApplicationContextProvider } from "./application"
import { SnackbarProvider } from "./snackbar"

export const ContextProvider: FC = ({ children }) => {
  return (
    <ApplicationContextProvider>
      <SnackbarProvider>{children}</SnackbarProvider>
    </ApplicationContextProvider>
  )
}
