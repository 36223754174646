import { Box, IconButton, Tooltip } from "@mui/material"
import { FC, useEffect, useState } from "react"
import { businessMedia } from "../../../utils/getSocialMedia"
import { businessMenu } from "../../../utils/menuBottom"
import { BusinessForClient_businessForClient as IBusiness } from "../../../graphql/business"
import { ThemeType } from "../../../types/ThemeType"
import { BaseTypography } from "../../../components/Typography/BaseTypography"

interface FooterCommercantProps {
  theme?: ThemeType
  business: IBusiness
}
export const FooterCommercant: FC<FooterCommercantProps> = ({
  business,
  theme
}) => {
  const [medias, setMedias] = useState<any[]>([])
  const [menus, setMenus] = useState<any[]>([])

  useEffect(() => {
    const medis = businessMedia(business)
    const menis = businessMenu(business)

    setMedias(medis)
    setMenus(menis)
  }, [business])
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        minHeight: "25vh"
      }}
    >
      <Box sx={{ textAlign: "center", mb: 1 }}>
        {medias.map(({ url, media, icon }) => {
          return (
            <Tooltip title={media} key={media}>
              <a
                style={{ marginRight: "16px" }}
                href={url}
                target="_blank"
                rel="noreferrer"
              >
                <IconButton
                  sx={{
                    backgroundColor: "white",
                    "&:hover": {
                      backgroundColor: "white"
                    },
                    "& svg": {
                      fill: theme?.mediaColor
                    }
                  }}
                >
                  {icon}
                </IconButton>
              </a>
            </Tooltip>
          )
        })}
      </Box>
      {/* <Typography
        variant="body2"
        color="text.secondary"
        sx={{ textAlign: "center" }}
      >
        {business.description}
      </Typography> */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
          mb: 2
        }}
      >
        {menus.map(({ url, menu }, index) => {
          return (
            <Box key={menu} sx={{ m: 1 }}>
              <Tooltip title={menu} key={menu}>
                <a
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    textDecoration: "underline",
                    color: theme?.colorText
                  }}
                >
                  <BaseTypography theme={theme}>{menu}</BaseTypography>
                </a>
              </Tooltip>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
