import { Box, Container, Typography } from "@mui/material"
import { ChangeEvent, useState } from "react"
import { Route, Routes, useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import { ContainerWithMenu, CustomModal, HeaderPage } from "../../../components"
import { useApplicationContext } from "../../../hooks"
import { useReservation } from "../../../hooks/useReservation"
import { ModalBooking } from "../ModalBooking/ModalBooking"
import { DateReservation } from "./components/DateReservation"
import { HoursReservation } from "./components/HoursReservation"
import { ProgressBarReservation } from "./components/ProgressBarReservation"
import { ReservationCount } from "./components/ReservationCount"
import { CustomedDescription } from "./components/CustomedDescription"
import { dataBar } from "./dataBar"
import { useMutation } from "@apollo/client"
import { UP_BOOKING_COUNT } from "../../../graphql/business/mutation"
import {
  UpBookingCount,
  UpBookingCountVariables
} from "../../../graphql/business/types/UpBookingCount"

type SubmitType = {
  showDate?: Date | undefined
  hour?: string | undefined
  date?: Date
}

export const SampleReservation = () => {
  const {
    user,
    business,
    theme,
    businessLoading,
    refetchAbonnement,
    bookingDate,
    setBookingDate
  } = useApplicationContext()
  const navigate = useNavigate()
  const appBar = dataBar
  const { bookTable } = useReservation()
  const [values, setValues] = useState<SubmitType>()
  const [count, setCount] = useState(1)
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [open, setOpen] = useState(false)
  const [incrementBookingCount] = useMutation<
    UpBookingCount,
    UpBookingCountVariables
  >(UP_BOOKING_COUNT)
  const handleChangeDate = (date?: Date | null) => {
    if (date) {
      setBookingDate(date)
    }
  }
  const handleChangeHours = (
    e: ChangeEvent<HTMLInputElement>,
    value?: string
  ) => {
    if (e.target?.name === "hours") {
      setValues((prev) => ({
        ...prev,
        hour: e.target.value
      }))
    } else {
      setPhone(value || "")
    }
  }
  const handleEmail = (value: string) => {
    setEmail(value)
  }
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setOpen(!open)
  }
  const handleDecrement = () => {
    if (count > 0) {
      setCount(count - 1)
    }
  }
  const handleIncrement = () => {
    setCount(count + 1)
  }
  const reservationDescription = {
    title: "RESERVATION SIMPLE",
    description:
      "Profitez de notre service de reservation. Certaines de nos tables peuvent être réservés à l'avance! S'il n'y a plus de tables disponibles, vous pouvez vous positionner sur la file d'attente dès l'ouverture du restaurant."
  }

  return (
    <ContainerWithMenu
      theme={theme}
      padding={1}
      title="Réservation"
      loading={businessLoading}
      noTopNav
    >
      {business?.id && <HeaderPage theme={theme} business={business} />}
      <Container>
        <Box>
          <CustomedDescription
            descritpion={reservationDescription.description}
          />
          <ProgressBarReservation data={appBar} />
          <Box sx={{ my: 2 }}>
            <Routes>
              <Route
                index
                element={
                  <ReservationCount
                    count={count}
                    onDecrement={handleDecrement}
                    onIncrement={handleIncrement}
                  />
                }
              />
              <Route
                path="date"
                element={
                  <DateReservation
                    values={bookingDate}
                    onChangeDate={handleChangeDate}
                  />
                }
              />
              <Route
                path="hours"
                element={
                  <HoursReservation
                    values={values?.hour || ""}
                    onChange={handleChangeHours}
                    onClick={handleClick}
                    onGetEmail={handleEmail}
                  />
                }
              />
            </Routes>
          </Box>
          {user?.client ? (
            <CustomModal
              open={open}
              theme={theme}
              setOpen={setOpen}
              onConfirm={() => {
                bookTable({
                  date: bookingDate,
                  businessId: business?.id || 0,
                  clientId: user?.client?.id || 0,
                  email: email || user?.client?.email || "",
                  message: "",
                  person: count,
                  phone: phone || user?.client?.phone || "",
                  name: user?.client?.firstname || "",
                  hour: values?.hour || ""
                }).then(async () => {
                  await incrementBookingCount({
                    variables: {
                      businessId: business?.id
                    }
                  })
                  refetchAbonnement()
                  Swal.fire({
                    title: "Réservation effectué",
                    icon: "success",
                    showConfirmButton: false,
                    showCloseButton: true
                  })
                    .then(() => navigate("/tag/booking-list"))
                    .catch(() =>
                      Swal.fire({
                        title: "Réservation non-envoyé",
                        icon: "error",
                        showConfirmButton: false,
                        showCloseButton: true
                      })
                    )
                })
              }}
            >
              <ModalBooking
                date={bookingDate}
                hour={values?.hour}
                nbPrs={count}
                infos={{
                  name: user?.client?.firstname || "",
                  mail: user?.client?.email || "",
                  phone: user?.client?.phone || ""
                }}
              />
            </CustomModal>
          ) : (
            ""
          )}
        </Box>
      </Container>
    </ContainerWithMenu>
  )
}
