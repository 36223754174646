import { SendRounded } from "@mui/icons-material"
import {
  Alert,
  Box,
  Card,
  CardMedia,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material"
import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import { ContainerWithMenu, DatePickerBase, HeaderPage } from "../../components"
import { FormatPrice } from "../../components/FormatPriceBusiness"
import { TableColProps, TableComponent } from "../../components/Table"
import { useApplicationContext } from "../../hooks"
import { useCreateCommand } from "../../hooks/useCreateCommand"
import { CartType, CommandInput, CommandTraitementType } from "../../types"
import { BaseBtn } from "../../components/Button/BaseBtn"
import { BaseTypography } from "../../components/Typography/BaseTypography"
import { useMutation, useQuery } from "@apollo/client"
import { UP_CLICK_COUNT } from "../../graphql/business/mutation"
import {
  UpClickCount,
  UpClickCountVariables
} from "../../graphql/business/types/UpClickCount"
import {
  GetSubscriptionList,
  GetSubscriptionListVariables,
  GET_CLICK_COLLECT_ID,
  INFO_SUBSCRIPTION
} from "../../graphql"
import { useForm } from "react-hook-form"
import PhoneInput, { CountryData } from "react-phone-input-2"

function artcomponent(img: string, name: string) {
  return (
    <Grid container columnSpacing={1} alignItems="center">
      <Grid item xs={4}>
        <CardMedia alt={name} component="img" image={img} height="50" />
      </Grid>
      <Grid item xs={8}>
        {name}
      </Grid>
    </Grid>
  )
}

function typoContent(item: string | number) {
  return (
    <Typography textAlign="center" variant="body2">
      {item}
    </Typography>
  )
}

function formatPrice(item: string | number) {
  return <FormatPrice sx={{ textAlign: "center" }} priceItem={item || 0} />
}

export const CommandsPage = () => {
  const [disable, setDisable] = useState(true)
  const [close, setClose] = useState(false)
  const [showCalendar, setShowCalendar] = useState(false)
  const {
    register,
    handleSubmit: submitCommand,
    formState: { errors }
  } = useForm()

  const {
    businessLoading,
    cart,
    user,
    setCart,
    business,
    theme,
    abonnementCounter,
    refetchAbonnement
  } = useApplicationContext()
  const cartTest = localStorage.getItem("mycart")
  const cartFromLocalStorage: CartType[] = cartTest
    ? JSON.parse(cartTest)
    : null
  const { createCommand, loading: loadingCommand, error } = useCreateCommand()
  const navigate = useNavigate()
  const [incrementClickCount] = useMutation<
    UpClickCount,
    UpClickCountVariables
  >(UP_CLICK_COUNT)
  const [userInfo, setUserInfo] = useState<Partial<CommandInput>>({
    name: `${user?.client?.firstname} ${user?.client?.lastname}` || "",
    email: user?.client?.email || "",
    phone: user?.client?.phone || "",
    address: "",
    commandTraitementType: CommandTraitementType.TODAYCOMMAND,
    deferredDate: null,
    description: ""
  })
  const getPhone = (value: string) => {
    setUserInfo((prev) => ({
      ...prev,
      phone: value
    }))
  }
  const columns: TableColProps[] = [
    {
      id: "name",
      label: "Article",
      component: (data: CartType) => artcomponent(data.image, data.name)
    },
    {
      id: "quantity",
      label: "Quantité",
      component: (data: CartType) => typoContent(data.stock)
    },
    {
      id: "price",
      label: "Prix",
      component: (data: CartType) => {
        return formatPrice(data.price || 0)
      }
    },
    {
      id: "total",
      label: "Total",
      component: (data: CartType) => {
        const total = data.price * data.stock

        return formatPrice(total || 0)
      }
    }
  ]
  const verifyCart = cart || cartFromLocalStorage
  const sumPrice = verifyCart
    ?.map((value) => value.price * value.stock)
    .reduce((a, b) => a + b, 0)
  const { data: dataClick } = useQuery(GET_CLICK_COLLECT_ID, {
    variables: {
      businessId: Number(business?.id)
    },
    skip: !business?.id
  })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (e: any) => {
    const { name, value } = e.target

    if (
      name === "commandTraitementType" &&
      value === CommandTraitementType.DIFFEREECOMMAND
    ) {
      setShowCalendar(true)
    } else {
      setShowCalendar(false)
    }

    setUserInfo((prev) => ({
      ...prev,
      [name]: value
    }))
  }
  const handleDeferred = (date: Date | null) => {
    setUserInfo((prev) => ({
      ...prev,
      deferredDate: date
    }))
  }
  const validateCommand = async () => {
    if (!business?.id || !user?.client?.id || !verifyCart?.length) {
      return
    }
    const requiredValue = [
      userInfo.address,
      userInfo.email,
      userInfo.name,
      userInfo.phone
    ].every((val) => val !== undefined && val !== "" && val)
    if (!requiredValue) {
      Swal.fire({
        title: "Completer les champs de facturations",
        icon: "error",
        showConfirmButton: false,
        showCloseButton: true
      })

      return
    }
    const data: CommandInput = {
      businessId: business.id,
      clientId: user.client.id,
      description: userInfo.description || "",
      address: userInfo.address || "",
      email: userInfo.email || "",
      phone: userInfo.phone || "",
      name: userInfo.name || "",
      deferredDate: userInfo.deferredDate || null,
      commandTraitementType:
        userInfo.commandTraitementType || CommandTraitementType.TODAYCOMMAND,
      products: verifyCart.map((card) => ({
        product: card.id as any,
        totalCount: card.stock,
        productPrice: card.price
      }))
    }

    const command = await createCommand(data)
    if (command && !error) {
      localStorage.removeItem("mycart")
      setCart([])

      await incrementClickCount({
        variables: {
          businessId: business?.id
        }
      }).then(() => {
        refetchAbonnement()
      })

      Swal.fire({
        title:
          data.commandTraitementType === CommandTraitementType.DIFFEREECOMMAND
            ? "Votre commande sera traitée dans 24h"
            : "commande effectuée",
        icon: "success",
        showConfirmButton: false,
        showCloseButton: true
      })
      navigate("/tag/order")
    } else {
      Swal.fire({
        title: "Commande non effectué",
        icon: "error",
        showConfirmButton: false,
        showCloseButton: true
      })
    }
  }

  const {
    data,
    loading: loadingSubscribe,
    refetch: refetchSubscribe
  } = useQuery<GetSubscriptionList, GetSubscriptionListVariables>(
    INFO_SUBSCRIPTION,
    {
      variables: {
        businessId: business?.id || 0
      },
      skip: !business
    }
  )

  useEffect(() => {
    refetchSubscribe()
    setDisable(loadingCommand || loadingSubscribe)
    if (data && abonnementCounter?.numberClick) {
      if (
        data?.getSubscriptionList[0]?.Abonnements &&
        data?.getSubscriptionList[0]?.Abonnements[0].clickCount !== null &&
        abonnementCounter?.numberClick !== null
      ) {
        if (
          data?.getSubscriptionList[0]?.Abonnements[0].clickCount >=
          abonnementCounter?.numberClick
        ) {
          setDisable(true)
          setClose(true)
        }
      }
    }
  }, [
    abonnementCounter,
    data,
    loadingCommand,
    loadingSubscribe,
    refetchSubscribe
  ])

  return (
    <ContainerWithMenu
      padding={1}
      theme={theme}
      loading={businessLoading}
      noTopNav
    >
      {business?.id && <HeaderPage theme={theme} business={business} />}
      <BaseTypography
        theme={theme}
        variant="h1"
        textAlign="center"
        sx={{ my: 3, fontWeight: 400 }}
      >
        Résumé
      </BaseTypography>
      <Container>
        <TableComponent columns={columns} data={verifyCart || []} />
        <Card
          sx={{
            background: "white",
            height: "10%",
            p: 2,
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <Grid item>
            <Typography variant="h5" component="div">
              TOTAL A PAYER DE LA COMMANDE:
            </Typography>
            <Typography variant="h5" component="div">
              ( Payable à la réception )
            </Typography>
          </Grid>
          <Grid item>
            <FormatPrice
              variant="h5"
              component="div"
              priceItem={sumPrice || 0}
            />
          </Grid>
        </Card>
        <Card sx={{ p: 4, my: 2 }}>
          <Typography variant="h5" component="div" sx={{ mb: 2 }}>
            Adresse de facturation :
          </Typography>
          <Box sx={{ my: 1 }}>
            <TextField
              fullWidth
              label="Nom"
              required
              variant="outlined"
              value={userInfo.name}
              {...register("name", {
                required: { value: true, message: "Ce champ est requis" }
              })}
              error={errors.name && true}
              helperText={errors.name?.message}
              onChange={handleChange}
            />
          </Box>
          <Box sx={{ my: 1 }}>
            <TextField
              type="email"
              fullWidth
              required
              label="E-mail"
              variant="outlined"
              value={userInfo.email}
              {...register("email", {
                required: { value: true, message: "email requis" },
                pattern: {
                  value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                  message: "Entrer un email valide"
                }
              })}
              error={errors.email && true}
              helperText={errors.email?.message}
              onChange={handleChange}
            />
          </Box>
          <Box sx={{ my: 1 }}>
            <PhoneInput
              enableSearch
              containerStyle={{
                width: "auto"
              }}
              specialLabel=""
              country={business?.countryCode || "fr"}
              preferredCountries={["ch", "fr"]}
              inputStyle={{
                backgroundColor: "#ffffff",
                borderRadius: 1
              }}
              value={userInfo.phone || ""}
              onChange={getPhone}
            />
          </Box>
          <Box sx={{ my: 1 }}>
            <TextField
              fullWidth
              label="Adresse"
              required
              variant="outlined"
              value={userInfo.address}
              {...register("address", {
                required: { value: true, message: "Ce champ est requis" }
              })}
              error={errors.address && true}
              helperText={errors.address?.message}
              onChange={handleChange}
            />
          </Box>
          <Box my={5}>
            <TextField
              multiline
              fullWidth
              label="Message"
              variant="outlined"
              value={userInfo.description}
              name="description"
              onChange={handleChange}
            />
          </Box>
          {dataClick?.clickCollectById?.deferredCommand ? (
            <RadioGroup
              row
              name="commandTraitementType"
              sx={{ my: 1 }}
              defaultValue={CommandTraitementType.TODAYCOMMAND}
              onChange={handleChange}
            >
              <FormControlLabel
                value={CommandTraitementType.TODAYCOMMAND}
                label="Commande du jour"
                control={
                  <Radio sx={{ color: theme?.colorBtn + "!important" }} />
                }
              />
              <FormControlLabel
                value={CommandTraitementType.DIFFEREECOMMAND}
                label="Commande différée"
                control={
                  <Radio sx={{ color: theme?.colorBtn + "!important" }} />
                }
              />
            </RadioGroup>
          ) : (
            ""
          )}
          {showCalendar ? (
            <Box>
              <DatePickerBase
                value={userInfo.deferredDate}
                onChange={handleDeferred}
              />
            </Box>
          ) : (
            ""
          )}
          {business?.urlPaypalCount && (
            <BaseBtn
              theme={theme}
              variant="contained"
              onClick={() =>
                (window.location.href = `${business?.urlPaypalCount}`)
              }
            >
              Payer
            </BaseBtn>
          )}
        </Card>
        {business?.paypalImg && (
          <Card
            sx={{
              display: { xs: "none", md: "flex" },
              p: 2,
              justifyContent: "center"
            }}
          >
            <img src={business.paypalImg} alt="qrcode" />
          </Card>
        )}
        <Box textAlign="center" sx={{ my: 2 }}>
          {close ? (
            <Alert severity="info">
              Nos commandes sont temporairement indisponibles!
            </Alert>
          ) : (
            <BaseBtn
              theme={theme}
              disabled={disable}
              variant="contained"
              onClick={submitCommand(validateCommand)}
            >
              {loadingCommand ? (
                <CircularProgress
                  style={{ width: 25, height: 25, marginRight: 2 }}
                />
              ) : (
                <SendRounded sx={{ mr: 1 }} />
              )}
              Envoyer ma commande
            </BaseBtn>
          )}
        </Box>
      </Container>
    </ContainerWithMenu>
  )
}
