import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Container,
  Grid,
  Tabs,
  Tab,
  Typography
} from "@mui/material"
import moment from "moment"
import { SyntheticEvent, useEffect, useState } from "react"
import { ContainerWithMenu, HeaderPage, QueryResult } from "../../components"
import { useApplicationContext } from "../../hooks"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { BaseTypography } from "../../components/Typography/BaseTypography"
import { useNavigate } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { RESERVATIONS_BUSINESS } from "../../graphql/reservations/query"
import { useReservation } from "../../hooks/useReservation"

export const ReservationList = () => {
  const { business, businessLoading, theme, user } = useApplicationContext()
  const navigate = useNavigate()
  const [expanded, setExpanded] = useState<number | false>(false)
  const [tabValue, setTabValue] = useState(1)
  const {
    reservations: wiziRes,
    loading: loadingRes,
    loadReservation,
    refetch: wiziRefetch
  } = useReservation()
  const {
    data: reservations,
    loading,
    refetch
  } = useQuery(RESERVATIONS_BUSINESS, {
    variables: {
      clientId: user?.client?.id,
      businessId: business?.id
    }
  })
  const Status = (status: string) => {
    switch (status) {
      case "PENDING":
        return <Chip color="warning" label="En cours" />
      case "VALID":
        return <Chip color="success" label="Validé" />
      case "DONE":
        return <Chip color="primary" label="Fini" />
      case "REJECT":
        return <Chip color="error" label="Réjété" />

      default:
        return null
    }
  }
  const handleChange =
    (panel: number, item: any) =>
    (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  useEffect(() => {
    refetch()
  }, [refetch])

  useEffect(() => {
    loadReservation()
    wiziRefetch()
  }, [loadReservation, wiziRefetch])

  return (
    <ContainerWithMenu
      theme={theme}
      noTopNav
      padding={1}
      loading={businessLoading}
    >
      {business?.id && <HeaderPage business={business} theme={theme} />}
      <BaseTypography
        theme={theme}
        variant="h1"
        textAlign="center"
        sx={{ mb: 4, fontWeight: 400 }}
      >
        Liste de mes réservations
      </BaseTypography>
      <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
        <Tabs value={tabValue} onChange={(e, newVal) => setTabValue(newVal)}>
          <Tab
            value={1}
            label={`Mes reservations sur ${business?.businessName}`}
          />
          <Tab value={2} label="Mes reservations sur WIZITAG" />
        </Tabs>
      </Box>
      <Container>
        {tabValue === 1 && (
          <QueryResult data={reservations} loading={loading}>
            <>
              {reservations?.clientBookingBusiness
                ?.map((item: any) => (
                  <Accordion
                    key={item.id}
                    expanded={expanded === item.id}
                    onChange={handleChange(item.id, item)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          mr: 2,
                          alignItems: "center"
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                          <Box
                            sx={{
                              border: "1px solid rgba(224, 224, 224, 1)",
                              borderRadius: 1,
                              px: 1,
                              mr: 2
                            }}
                          >
                            <Typography component="div">
                              N°: {item.id}
                            </Typography>
                          </Box>
                          <Typography component="div">
                            {moment(item.createdAt).format("DD MMM YYYY HH:mm")}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex"
                          }}
                        >
                          <Box mr={1}>
                            {item.eventId && (
                              <Chip color="warning" label="événement" />
                            )}
                          </Box>
                          <Typography component="div">
                            {Status(item.status)}
                          </Typography>
                        </Box>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item md={6}>
                          <Box mb={2}>
                            <Typography variant="body2" fontWeight="bold">
                              Nom :
                            </Typography>
                            {item.name}
                          </Box>
                          <Box mb={2}>
                            <Typography variant="body2" fontWeight="bold">
                              Date:
                            </Typography>
                            {item.eventParty ? (
                              <>
                                <Box>
                                  {moment(item.eventParty.dateStart).format(
                                    "DD MMMM YYYY"
                                  )}{" "}
                                  -{" "}
                                  {moment(item.eventParty.dateEnd).format(
                                    "DD MMMM YYYY"
                                  )}
                                </Box>
                              </>
                            ) : (
                              <>
                                {moment(item.date).format("DD MMMM YYYY")}
                                {item.hour && (
                                  <>
                                    {" "}
                                    - {moment(item.hour).format("hh")}h
                                    {moment(item.hour).format("mm")}
                                  </>
                                )}
                              </>
                            )}
                          </Box>
                          <Box mb={2}>
                            <Typography variant="body2" fontWeight="bold">
                              Nombre de personne :
                            </Typography>
                            {item?.person < 10
                              ? "0" + item?.person
                              : item?.person}
                          </Box>
                          <Box mb={2}>
                            <Typography variant="body2" fontWeight="bold">
                              E-mail :
                            </Typography>
                            {item.email}
                          </Box>
                          <Box mb={2}>
                            <Typography variant="body2" fontWeight="bold">
                              Téléphone :
                            </Typography>
                            {item.phone}
                          </Box>
                          <Box mb={2}>
                            <Typography variant="body2" fontWeight="bold">
                              Url :
                            </Typography>
                            {window.location.hostname}
                          </Box>
                        </Grid>
                        {item.eventParty && (
                          <Grid item md={6}>
                            {item.eventParty.img && (
                              <Box
                                sx={{
                                  width: "100%",
                                  height: 250,
                                  margin: "0 auto 10px"
                                }}
                              >
                                <img
                                  src={item.eventParty.img || ""}
                                  alt={item.eventParty.title}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover"
                                  }}
                                />
                              </Box>
                            )}
                            <Box mb={2}>
                              <Typography variant="body2" fontWeight="bold">
                                Nom de l'événement :
                              </Typography>
                              {item?.eventParty.title}
                            </Box>
                            <Box mb={2}>
                              <Typography variant="body2" fontWeight="bold">
                                Heure de début et fin :
                              </Typography>
                              {moment(item.eventParty.hourStart).format(
                                "hh:mm"
                              )}{" "}
                              -{" "}
                              {moment(item.eventParty.hourEnd).format("hh:mm")}
                            </Box>
                            <Box>
                              <Typography variant="body2" fontWeight="bold">
                                Description :
                              </Typography>
                              {item.eventParty.description}
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))
                .reverse()}
            </>
          </QueryResult>
        )}
        {tabValue === 2 && (
          <QueryResult data={wiziRes} loading={loadingRes}>
            <>
              {wiziRes
                ?.map((item) => (
                  <Accordion
                    key={item.id}
                    expanded={expanded === item.id}
                    onChange={handleChange(item.id, item)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          mr: 2,
                          alignItems: "center"
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                          <Box
                            sx={{
                              border: "1px solid rgba(224, 224, 224, 1)",
                              borderRadius: 1,
                              px: 1,
                              mr: 2
                            }}
                          >
                            <Typography component="div">
                              N°: {item.id}
                            </Typography>
                          </Box>
                          <Typography component="div">
                            {moment(item.createdAt).format("DD MMM YYYY HH:mm")}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex"
                          }}
                        >
                          <Box mr={1}>
                            {item.eventId && (
                              <Chip color="warning" label="événement" />
                            )}
                          </Box>
                          <Typography component="div">
                            {Status(item.status)}
                          </Typography>
                        </Box>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item md={6}>
                          <Box mb={2}>
                            <Typography variant="body2" fontWeight="bold">
                              Nom :
                            </Typography>
                            {item.name}
                          </Box>
                          <Box mb={2}>
                            <Typography variant="body2" fontWeight="bold">
                              Date:
                            </Typography>
                            {item.eventParty ? (
                              <>
                                <Box>
                                  {moment(item.eventParty.dateStart).format(
                                    "DD MMMM YYYY"
                                  )}{" "}
                                  -{" "}
                                  {moment(item.eventParty.dateEnd).format(
                                    "DD MMMM YYYY"
                                  )}
                                </Box>
                              </>
                            ) : (
                              <>
                                {moment(item.date).format("DD MMMM YYYY")}
                                {item.hour && (
                                  <>
                                    {" "}
                                    - {moment(item.hour).format("hh")}h
                                    {moment(item.hour).format("mm")}
                                  </>
                                )}
                              </>
                            )}
                          </Box>
                          <Box mb={2}>
                            <Typography variant="body2" fontWeight="bold">
                              Nombre de personne :
                            </Typography>
                            {item?.person < 10
                              ? "0" + item?.person
                              : item?.person}
                          </Box>
                          <Box mb={2}>
                            <Typography variant="body2" fontWeight="bold">
                              E-mail :
                            </Typography>
                            {item.email}
                          </Box>
                          <Box mb={2}>
                            <Typography variant="body2" fontWeight="bold">
                              Téléphone :
                            </Typography>
                            +{item.phone}
                          </Box>
                          <Box mb={2}>
                            <Typography variant="body2" fontWeight="bold">
                              Url :
                            </Typography>
                            {item.business.url}
                          </Box>
                        </Grid>
                        {item.eventParty && (
                          <Grid item md={6}>
                            {item.eventParty.img && (
                              <Box
                                sx={{
                                  width: "100%",
                                  height: 250,
                                  margin: "0 auto 10px"
                                }}
                              >
                                <img
                                  src={item.eventParty.img || ""}
                                  alt={item.eventParty.title}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover"
                                  }}
                                />
                              </Box>
                            )}
                            <Box mb={2}>
                              <Typography variant="body2" fontWeight="bold">
                                Nom de l'événement :
                              </Typography>
                              {item?.eventParty.title}
                            </Box>
                            <Box mb={2}>
                              <Typography variant="body2" fontWeight="bold">
                                Heure de début et fin :
                              </Typography>
                              {moment(item.eventParty.hourStart).format(
                                "hh:mm"
                              )}{" "}
                              -{" "}
                              {moment(item.eventParty.hourEnd).format("hh:mm")}
                            </Box>
                            <Box>
                              <Typography variant="body2" fontWeight="bold">
                                Description :
                              </Typography>
                              {item.eventParty.description}
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))
                .reverse()}
            </>
          </QueryResult>
        )}
      </Container>
    </ContainerWithMenu>
  )
}
