import { createTheme } from "@mui/material"
import { COLOR } from "../utils/color"

const theme = createTheme({
  palette: {
    primary: {
      light: "#FE724C",
      main: "#FE724C",
      dark: "#FE724C",
      contrastText: "#fff"
    },
    secondary: {
      light: "#ffc53d",
      main: "#ffc53d",
      dark: "#ffc53d",
      contrastText: "#ffff"
    }
  },
  typography: {
    h1: {
      fontSize: 40,
      lineHeight: 0.8
    },
    h2: {
      fontSize: 35,
      fontWeight: "bold"
    },
    h3: {
      fontWeight: 400,
      fontSize: 28,
      color: COLOR.DARK_BLUE
    },
    h4: {
      fontSize: 26,
      color: COLOR.DARK_BLUE
    },
    h5: {
      color: COLOR.DARK_BLUE,
      fontSize: "1rem",
      fontWeight: "bold"
    },
    body1: {
      color: COLOR.DARK_GREY,
      fontSize: "0.875rem"
    },
    body2: {
      color: COLOR.DARK_GREY
    },
    subtitle1: {
      color: COLOR.DARK_BLUE,
      fontWeight: "bold",
      fontSize: "0.875rem"
    },
    subtitle2: {
      color: COLOR.DARK_GREY
    },
    button: {
      textTransform: "none"
    }
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 50
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: '"Sophia Pro", sans-serif'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        elevation3: {
          boxShadow: "0 6px 18px 0 #ccc"
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          padding: 8
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&:not(.Mui-focused, [data-shrink='true'])": {
            top: -6
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            padding: 0,
            "& .MuiAutocomplete-input": {
              padding: 10
            }
          }
        },
        input: {
          padding: 10
        }
      }
    }
  }
})

export default theme
