import { ReactNode } from "react"
import { ThemeType } from "../../types/ThemeType"
import { BaseBtn } from "./BaseBtn"

interface ButtonCloseProps {
  theme?: ThemeType
  children: ReactNode
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
}

export const ButtonClose = ({
  children,
  theme,
  onClick
}: ButtonCloseProps): JSX.Element => {
  return (
    <BaseBtn
      theme={theme}
      sx={{ minWidth: 36, p: 1 }}
      children={children}
      onClick={onClick}
    />
  )
}
