import * as React from "react"
import Backdrop from "@mui/material/Backdrop"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Fade from "@mui/material/Fade"
import Button from "@mui/material/Button"
import { styled } from "@mui/material"

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: 2,
  maxWidth: "768px"
}

const StyledModal = styled(Modal)(() => ({
  padding: 20
}))
interface ModalOverlayProps {
  noClickAway?: boolean
  children: React.ReactNode
  open: boolean
  withoutButton?: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  onClose?: () => void
  onOpen?: () => void
}
export const ModalOverlay: React.FC<ModalOverlayProps> = ({
  noClickAway,
  children,
  open,
  withoutButton,
  setOpen,
  onClose,
  onOpen
}): JSX.Element => {
  const handleClose = (): void => {
    setOpen(false)
    onClose && onClose()
  }
  const handleOpen = (): void => {
    setOpen(true)
    onOpen && onOpen()
  }
  return (
    <div>
      {!withoutButton && <Button onClick={handleOpen}>Open modal</Button>}
      <StyledModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={!noClickAway ? handleClose : undefined}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Box sx={style}>{children}</Box>
        </Fade>
      </StyledModal>
    </div>
  )
}
