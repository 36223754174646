
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Container,
  Typography
} from "@mui/material"
import { SyntheticEvent, useEffect, useState } from "react"
import { ContainerWithMenu, HeaderPage, QueryResult } from "../../components"
import { useApplicationContext } from "../../hooks"
import { CommandStatusType } from "../../types"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import moment from "moment"
import { useQuery } from "@apollo/client"
import {
  getAllProduct_getAllProduct,
  GET_PRODUCT_BUSINESS
} from "../../graphql"
import { ProductListComponent } from "./components/ProductListComponent"
import { BaseTypography } from "../../components/Typography/BaseTypography"
import { BaseBtn } from "../../components/Button/BaseBtn"
import { useNavigate } from "react-router-dom"

interface ProductType extends getAllProduct_getAllProduct {
  totalCount: number
  productPrice: number
}

export const CommandListWizi = () => {
  const navigate = useNavigate()
  const { user, business, businessLoading, theme } = useApplicationContext()
  const { data: productList } = useQuery(GET_PRODUCT_BUSINESS, {
    variables: {
      businessId: business?.id
    },
    skip: !business?.id
  })
  const [currentProduct, setCurrentProduct] = useState<ProductType[]>([])
  const [expanded, setExpanded] = useState<number | false>(false)
  const Status = (status: CommandStatusType) => {
    switch (status) {
      case CommandStatusType.PENDING:
        return <Chip color="warning" label="En cours" />
      case CommandStatusType.VALID:
        return <Chip color="success" label="Validé" />
      case CommandStatusType.DONE:
        return <Chip color="primary" label="Fini" />
      case CommandStatusType.REJECT:
        return <Chip color="error" label="Réjété" />

      default:
        return null
    }
  }
  const handleChange =
    (panel: number, item: any) =>
      (event: SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false)
        displayProduct(isExpanded, item)
      }
  const displayProduct = (expand: boolean, item: any) => {
    const productArrId = item.products.map(
      (item: { productId: any }) => item.productId
    )
    if (expand && productList) {
      const product = productList.productByBusiness.filter(
        (product: { id: number }) => productArrId.includes(product.id)
      )
      const processedProduct = product.map((i: any, index: number) => {
        return {
          ...i,
          ...item.products[index]
        }
      })

      setCurrentProduct(processedProduct)
    }
  }

  return (
    <ContainerWithMenu
      theme={theme}
      noTopNav
      padding={1}
      loading={businessLoading}
    >
      {business?.id && <HeaderPage business={business} theme={theme} />}
      <BaseTypography
        theme={theme}
        variant="h1"
        textAlign="center"
        sx={{ mb: 4, fontWeight: 400 }}
      >
        Liste des commandes sur WIZITAG
      </BaseTypography>
      <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
        <BaseBtn
          theme={theme}
          variant="outlined"
          sx={{
            borderRadius: "5px",
            borderWidth: "0px",
            borderColor: "rgb(0, 0, 0)",
            width: "300px",
            height: "60px",
            "&:hover": {
              borderColor: "rgb(0, 0, 0)"
            }
          }}
          onClick={() => navigate("../order")}
        >
          Mes commandes sur {business?.businessName}
        </BaseBtn>
      </Box>
      <Container>
      </Container>
    </ContainerWithMenu>
  )
}
