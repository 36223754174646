/* eslint-disable react/no-unescaped-entities */
import {
  Alert,
  Divider,
  IconButton,
  styled,
  Tooltip,
  Typography
} from "@mui/material"
import Box, { BoxProps } from "@mui/material/Box"
import Card from "@mui/material/Card"
import ReactPlayer from "react-player"
import { FC, useEffect, useState } from "react"
import { BusinessForClient_businessForClient as IBusiness } from "../../graphql/business"
import { BGType } from "../../types"
import { businessMedia } from "../../utils/getSocialMedia"
import { businessMenu } from "../../utils/menuBottom"

interface BoxContainerProps extends BoxProps {
  background?: string
  bgType?: BGType
}
const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "background" && prop !== "bgType"
})<BoxContainerProps>(({ background, bgType }) => ({
  minHeight: "100vh",
  width: "100%",
  padding: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  ...(bgType === BGType.IMAGE
    ? { background: `url(${background}) no-repeat center` }
    : {}),
  ...(bgType === BGType.COLOR_LINEAR ? { background: `${background}` } : {}),
  backgroundSize: "cover"
}))

const StyledBoxAuth = styled(Card)(() => ({
  padding: 0,
  paddingTop: 20,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  maxWidth: 800,
  margin: "auto",
  zIndex: 1,
  transition: "1s"
}))

const StyledBoxVideo = styled(Box)(() => ({
  zIndex: 0,
  width: "100%",
  height: "100vh",
  position: "fixed",
  top: 0,
  left: 0
}))

interface AuthContainerProps {
  bg?: string
  business?: IBusiness
  redirection?: string
  bgType?: BGType
}
export const AuthContainer: FC<AuthContainerProps> = ({
  children,
  bg,
  business,
  redirection,
  bgType
}) => {
  const [medias, setMedias] = useState<any[]>([])
  const [menus, setMenus] = useState<any[]>([])
  useEffect(() => {
    const medis = businessMedia(business)
    const menis = businessMenu(business)
    setMedias(medis)
    setMenus(menis)
  }, [business])

  return (
    <StyledBox background={bg} bgType={bgType}>
      {bgType === BGType.VIDEO ? (
        <StyledBoxVideo>
          <ReactPlayer
            // onPlay={() => setIsPlaying(true)}
            // onStart={() => setIsPlaying(true)}
            playing
            muted
            // onPause={() => setIsPlaying(true)}
            url={bg}
            height="100vh"
            width="100%"
            config={{
              file: {
                attributes: {
                  autoPlay: true,
                  muted: true
                }
              }
            }}
          />
        </StyledBoxVideo>
      ) : null}
      {/* <StyledBoxAuth style={isPlaying ? { width: 0 } : {}}> */}
      <StyledBoxAuth>
        {redirection ? (
          <Alert severity="warning" sx={{ mb: 1 }}>
            <Typography>
              Vous devez se connecter pour continuer, creér un compte si vous
              n'avez pas encore !
            </Typography>
          </Alert>
        ) : null}
        <Box>{children}</Box>
        <Box sx={{ p: 2, background: "#f2f2f2", width: "100%" }}>
          <Box sx={{ textAlign: "center" }}>
            {menus.map(({ url, menu }) => {
              return (
                <Tooltip title={menu} key={menu}>
                  <a href={url} target="_blank" rel="noreferrer">
                    <IconButton disabled={!url}>
                      <Typography>{menu}</Typography>
                    </IconButton>
                  </a>
                </Tooltip>
              )
            })}
          </Box>
          <Box sx={{ textAlign: "center" }}>
            {medias.map(({ url, media, icon }) => {
              return (
                <Tooltip title={media} key={media}>
                  <a href={url} target="_blank" rel="noreferrer">
                    <IconButton>{icon}</IconButton>
                  </a>
                </Tooltip>
              )
            })}
          </Box>
          <Divider sx={{ width: 100, margin: "auto" }} />
          <Box sx={{ textAlign: "center", p: 1 }}>
            <Typography variant="subtitle2">Tag discount - © 2022</Typography>
          </Box>
        </Box>
      </StyledBoxAuth>
    </StyledBox>
  )
}
