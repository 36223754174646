import { Typography, Box, Button, Card, Grid } from "@mui/material"
import React from "react"
import { ContainerWithMenu, Loading } from "../../components"
import { useApplicationContext } from "../../hooks"
import { FileBusinessType } from "../../types"

export const UnderCatalogue = () => {
  const { business, businessLoading } = useApplicationContext()

  const filterCatalogue = business?.files.filter(
    (file) => file.type === FileBusinessType.CATALOGUE_PRODUCT
  )
  return (
    <ContainerWithMenu padding={1} title="Sous Categorie">
      <Box>
        <Card elevation={3} sx={{ m: 1 }}>
          <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
            {filterCatalogue
              ? filterCatalogue.map((categorie) => {
                  return (
                    <Box sx={{ p: 2 }}>
                      <Button variant="outlined" sx={{ borderRadius: "5px" }}>
                        {categorie.name}
                      </Button>
                    </Box>
                  )
                })
              : ""}
          </Box>
        </Card>
        <Card elevation={3} sx={{ m: 1 }}>
          <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
            {filterCatalogue
              ? filterCatalogue.map((categorie) => {
                  return (
                    <Box sx={{ p: 2 }}>
                      <Box
                        sx={{
                          width: 120,
                          height: 120,
                          m: 2,
                          borderRadius: "5px",
                          position: "relative"
                        }}
                        key={categorie.id}
                      >
                        <img
                          src={categorie.url}
                          alt=""
                          width="100%"
                          height="100%"
                          style={{ objectFit: "cover" }}
                        />
                      </Box>
                    </Box>
                  )
                })
              : ""}
          </Box>
          <Box>
            <Typography sx={{ p: 1, m: 1 }}>Nom du sous Categorie</Typography>
            <Grid xs={12}>
              <Card elevation={3} sx={{ p: 2, m: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between"
                  }}
                >
                  <Box>
                    <Typography>List Item</Typography>
                    <Typography>List Item</Typography>
                    <Typography>List Item</Typography>
                  </Box>

                  <Box>
                    <Typography>300 $</Typography>
                    <Typography>340 $</Typography>
                    <Typography>460 $</Typography>
                  </Box>
                </Box>
              </Card>
            </Grid>
          </Box>
        </Card>
      </Box>
    </ContainerWithMenu>
  )
}
