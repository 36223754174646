import { CircularProgress } from "@mui/material"
import { Navigate, useLocation } from "react-router-dom"
import { useApplicationContext, UserAuthStateEnum } from "../hooks"

export const PrivateRoute = ({
  children
}: {
  children: JSX.Element
}): JSX.Element => {
  const location = useLocation()
  const { userAuthStatus } = useApplicationContext()

  if (userAuthStatus === UserAuthStateEnum.WAITING) {
    return <CircularProgress />
  }
  if (userAuthStatus === UserAuthStateEnum.AUTHENTICATED) {
    return children
  }
  localStorage.setItem(
    "redirection_path",
    JSON.stringify({ path: location.pathname, auth: true })
  )
  return <Navigate to="/login" />
}
