import { useMutation } from "@apollo/client"
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography
} from "@mui/material"
import { useForm } from "react-hook-form"
import Swal from "sweetalert2"
import { FORGOT_PASSWORD } from "../../../graphql"
import {
  ForgotPassword as Forgot,
  ForgotPasswordVariables
} from "../../../graphql/user/types/ForgotPassword"
import { useApplicationContext } from "../../../hooks"
import { LoginClientInput } from "../../../types"

export const ForgotPassword = (): JSX.Element => {
  const [forgotExec, { loading }] = useMutation<
    Forgot,
    ForgotPasswordVariables
  >(FORGOT_PASSWORD)
  const { business } = useApplicationContext()
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<LoginClientInput>()
  const sendEmail = async (data: LoginClientInput) => {
    try {
      const message = await forgotExec({
        variables: {
          businessId: business?.id || 1,
          clientInput: data
        }
      })
      Swal.fire({
        title: message.data?.forgotPassword,
        icon: "info"
      })
    } catch (error) {
      const messageError = JSON.parse(JSON.stringify(error, null, 2)).message
      Swal.fire({
        title: messageError,
        icon: "error"
      })
    }
  }
  return (
    <Box sx={{ maxWidth: 600, mx: "auto" }}>
      <Typography variant="h4" textAlign="center">
        Mot de passe oublié
      </Typography>
      <Box sx={{ width: 240, mx: "auto", my: 1 }}>
        <form onSubmit={handleSubmit(sendEmail)}>
          <TextField
            fullWidth
            error={errors?.email && true}
            required
            label="email"
            {...register("email", {
              required: {
                value: true,
                message: "entrer l'email de réinitialisation"
              },
              pattern: {
                value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                message: "entrer un email valide"
              }
            })}
            helperText={errors?.email?.message}
          />
          <Button
            disabled={loading}
            type="submit"
            variant="contained"
            sx={{ mt: 1 }}
          >
            {loading && <CircularProgress />}
            Envoyer
          </Button>
        </form>
      </Box>
    </Box>
  )
}
