import { Box, Typography } from "@mui/material"
import { BaseTable } from "../../../components"
import { FormatPrice } from "../../../components/FormatPriceBusiness"
import { getAllProduct_getAllProduct } from "../../../graphql"
import logo_tag from "../../../assets/finaltag_logo.png"

interface ProductTypes extends getAllProduct_getAllProduct {
  totalCount: number
  productPrice: number
}

type ProductProps = {
  dataTable: ProductTypes[]
}

export const ProductListComponent = ({ dataTable }: ProductProps) => {
  const column = [
    {
      id: "name",
      label: "Désignation",
      align: "left",
      component: (data: ProductTypes) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center"
          }}
        >
          <Box
            sx={{
              width: 45,
              height: 45,
              mr: 2
            }}
          >
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover"
              }}
              src={data.image || logo_tag}
              alt={data.name || ""}
            />
          </Box>
          <Typography sx={{ mr: 2 }}>{data.name}</Typography>
        </Box>
      )
    },
    {
      id: "quantity",
      label: "quantité",
      component: (data: ProductTypes) => {
        return (
          <Typography sx={{ textAlign: "center" }}>
            {data.totalCount}
          </Typography>
        )
      }
    },
    {
      id: "price",
      label: "prix",
      component: (data: ProductTypes) => (
        <Box sx={{ textAlign: "center" }}>
          <FormatPrice component="span" priceItem={data.productPrice || 0} />
        </Box>
      )
    },
    {
      id: "total",
      label: "total",
      component: (data: ProductTypes) => (
        <Box sx={{ textAlign: "center" }}>
          <FormatPrice
            component="span"
            priceItem={data.totalCount * data.productPrice || 0}
          />
        </Box>
      )
    }
  ]

  return <BaseTable columns={column} data={dataTable} />
}
