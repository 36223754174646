import { gql } from "@apollo/client"

export const GET_QRCODEWITHNOTUSER = gql`
  query getQrcodewithnotuser($qrcodeId: Float!) {
    getQrcodewithnotuser(qrcodeId: $qrcodeId) {
      id
      logo
      etablissement
      textColor
      qrcodeImage
      title
      subtitle1
      subtitle2
      active
      background
      scanTotal
      colorEmpty
      lastReset
      createdAt
      updatedAt
      borderLine
      businessId
      lots {
        id
        image
        title
        color
        winFrequence
        description
        createdAt
        updatedAt
        scan
      }
      template {
        acceptNewsletter
        bgColor
        bgImage
        btnBg
        btnText
        btnTextColor
        businessId
        conditionLink
        conditionText
        copyCodeText
        createdAt
        description
        fieldCondition
        fieldEmail
        fieldEmailPlaceholder
        fieldName
        fieldNamePlaceholder
        fieldPhone
        fieldPhonePlaceholder
        fieldPrivacy
        fontFamily
        fontSizeSubtitle
        fontSizeText
        fontSizeTitle
        id
        instructionText
        isSavedAsGlobal
        isSavedByOwner
        name
        privacyLink
        privacyText
        spinItemMainColor
        subtitle
        textColor
        thankText
        title
        updatedAt
        winCloseBtnText
        winMessageCode
        winMessageTitle
        winPictures
        loseMessage
        loseMessageTitle
        losePictures
      }
    }
  }
`

export const GET_QRCODE = gql`
  query GetQrcode($userId: Float!, $qrcodeId: Float!) {
    getQrcode(userId: $userId, qrcodeId: $qrcodeId) {
      id
      logo
      etablissement
      textColor
      qrcodeImage
      title
      subtitle1
      subtitle2
      active
      background
      scanTotal
      colorEmpty
      lastReset
      createdAt
      updatedAt
      borderLine
      businessId
      lots {
        id
        image
        title
        color
        winFrequence
        description
        createdAt
        updatedAt
        scan
      }
      template {
        acceptNewsletter
        bgColor
        bgImage
        btnBg
        btnText
        btnTextColor
        businessId
        conditionLink
        conditionText
        copyCodeText
        createdAt
        description
        fieldCondition
        fieldEmail
        fieldEmailPlaceholder
        fieldName
        fieldNamePlaceholder
        fieldPhone
        fieldPhonePlaceholder
        fieldPrivacy
        fontFamily
        fontSizeSubtitle
        fontSizeText
        fontSizeTitle
        id
        instructionText
        isSavedAsGlobal
        isSavedByOwner
        name
        privacyLink
        privacyText
        spinItemMainColor
        subtitle
        textColor
        thankText
        title
        updatedAt
        winCloseBtnText
        winMessageCode
        winMessageTitle
        winPictures
        loseMessage
        loseMessageTitle
        losePictures
      }
    }
  }
`
