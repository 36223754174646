import {
  Box,
  Typography,
  Container,
  Grid,
  CardContent,
  Card,
  CardActionArea,
  Modal,
  Divider
} from "@mui/material"
import { useLazyQuery, useQuery } from "@apollo/client"
import { useState } from "react"
import Slider from "react-slick"
import { Close } from "@mui/icons-material"
import { useApplicationContext } from "../../hooks"
import {
  CardProduct,
  ContainerWithMenu,
  QueryResult,
  ProductDetails,
  HeaderPage
} from "../../components"
import {
  getAllCategory,
  getAllCategoryVariables,
  GET_ALL_CATEGORY,
  GET_PRODUCT_BUSINESS,
  GET_PRODUCT_BUSINESS_CAT
} from "../../graphql"
import "./Product.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { CartType } from "../../types"

import { BaseTypography } from "../../components/Typography/BaseTypography"
import { BaseBtn } from "../../components/Button/BaseBtn"
import tag_logo from "../../assets/finaltag_logo.png"

type ProductCardType = {
  id: number
  name: string
  image: string
  businessId: number
  prix: number
  prixTTC: number | null
  prixPromotionnels: number | null
  description: string | null
  preparationTime: number | null
  status: boolean | null
}

type CurrentCatType = {
  id: number
  name: string
}

export const Product = () => {
  const { business, businessLoading, theme } = useApplicationContext()
  const {
    data: dataCat,
    error: errorCat,
    loading: loadingCat
  } = useQuery<getAllCategory, getAllCategoryVariables>(GET_ALL_CATEGORY, {
    variables: {
      businessId: business?.id as number,
      isClient: true
    },
    skip: !business?.id
  })
  const { data, error, loading } = useQuery(GET_PRODUCT_BUSINESS, {
    variables: {
      businessId: business?.id,
      isClient: true
    },
    skip: !business?.id
  })
  const [
    getProductCat,
    {
      data: dataCatId,
      error: errorCatId,
      loading: loadingCatId,
      refetch: refetchCatId
    }
  ] = useLazyQuery(GET_PRODUCT_BUSINESS_CAT)
  const [currentCat, setCurrentCat] = useState<CurrentCatType | null>()
  const [currentList, setCurrentList] = useState("all")
  const [open, setOpen] = useState(false)
  const [currentProduct, setCurrentProduct] = useState<number>(0)
  const { cart, setCart } = useApplicationContext()
  const currentCart = cart || []
  const addToCart = (item: CartType) => {
    localStorage.setItem("mycart", JSON.stringify([...currentCart, item]))
    setCart([...currentCart, item])
  }

  const popFromCart = (popItem: number) => {
    const arrCart = currentCart.filter((item) => item.id !== popItem)
    localStorage.setItem("mycart", JSON.stringify(arrCart))
    setCart(arrCart)
  }
  const checkCart = (id: number) => {
    if (cart && cart?.length > 0) {
      return cart?.some((item) => item.id === id)
    }

    return false
  }

  const getStockCart = (id: number) => {
    if (cart && cart?.length > 0) {
      const idxItem = cart?.map((item) => item.id).indexOf(id)

      if (idxItem >= 0) {
        return cart[idxItem].stock
      }

      return 0
    }

    return 0
  }

  const getCatProduct = ({ id, name }: CurrentCatType) => {
    setCurrentCat({
      id,
      name
    })
    getProductCat({
      variables: {
        businessId: business?.id,
        categoryId: id,
        isClient: true
      }
    }).then(() => {
      setCurrentList("category")
      refetchCatId()
    })
  }

  const handleClose = () => {
    setOpen(false)
  }

  const nbrShowDatas =
    dataCat && dataCat?.category.length < 3 ? dataCat?.category.length : 3

  return (
    <ContainerWithMenu
      theme={theme}
      noTopNav
      padding={1}
      loading={businessLoading}
    >
      {business?.id && <HeaderPage theme={theme} business={business} />}
      <BaseTypography
        theme={theme}
        variant="h1"
        textAlign="center"
        sx={{ my: 2, fontWeight: 400 }}
      >
        Liste des produits
      </BaseTypography>
      <Container>
        <BaseTypography
          theme={theme}
          variant="h2"
          sx={{
            my: 2,
            width: "max-content",
            p: 0.5,
            fontSize: "20px",
            fontWeight: 200
          }}
        >
          Catégories
        </BaseTypography>
        <Box
          sx={{
            mb: 6
          }}
        >
          <QueryResult data={dataCat} error={errorCat} loading={loadingCat}>
            <Slider
              dots
              variableWidth
              slidesToShow={nbrShowDatas}
              slidesToScroll={1}
              infinite={false}
              responsive={[
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    infinite: false
                  }
                }
              ]}
            >
              {dataCat?.category.map((item: any) => (
                <Box
                  key={item.id}
                  sx={{
                    px: 1,
                    width: { xs: "100px !important", md: "150px !important" }
                  }}
                >
                  <Card
                    sx={{
                      border: `2px solid ${
                        currentCat?.id === item.id ? "#FE724C" : "transparent"
                      }`,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <CardActionArea onClick={() => getCatProduct(item)}>
                      <CardContent
                        sx={{
                          p: "0px !important",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          minHeight: "100px"
                        }}
                      >
                        <Box
                          sx={{
                            width: "80%",
                            height: 70
                            // overflow: "hidden"
                          }}
                        >
                          <img
                            style={{
                              width: 50,
                              height: 50,
                              marginLeft: "auto",
                              marginRight: "auto",
                              objectFit: "cover"
                            }}
                            src={item.image || tag_logo}
                            alt={item.name}
                          />
                          <Typography
                            variant="body2"
                            fontWeight="bold"
                            sx={{
                              textAlign: "center",
                              color: theme?.colorTextCardProduct,
                              fontFamily: theme?.police,
                              fontSize: {
                                xs: "10px !important",
                                md: "0.8rem !important"
                              }
                            }}
                          >
                            {item.name}
                          </Typography>
                        </Box>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Box>
              ))}
            </Slider>
          </QueryResult>
        </Box>
        <BaseTypography
          theme={theme}
          variant="h2"
          sx={{
            mb: 2,
            p: 0.5,
            width: "max-content",
            fontSize: "20px",
            fontWeight: 200
          }}
        >
          Liste des produits : &nbsp;
          {currentList !== "all" ? (
            <BaseBtn
              theme={theme}
              variant="outlined"
              size="small"
              sx={{ textTransform: "none" }}
              onClick={() => {
                setCurrentList("all")
                setCurrentCat(null)
              }}
            >
              <Close />
              &nbsp; {currentCat?.name}
            </BaseBtn>
          ) : (
            ""
          )}
        </BaseTypography>
        {currentList === "all" ? (
          <QueryResult data={data} error={error} loading={loading}>
            <Grid container rowSpacing={4} columnSpacing={2} sx={{ pb: 2 }}>
              {data?.productByBusiness.map((item: ProductCardType) => (
                <Grid item xs={6} sm={3} key={item.id}>
                  <CardProduct
                    theme={theme}
                    id={item?.id}
                    image={item?.image || tag_logo}
                    name={item?.name}
                    description={item?.description || ""}
                    prix={item?.prix}
                    prixTTC={item?.prixTTC}
                    stock={getStockCart(item?.id)}
                    add={checkCart(item?.id)}
                    onNavigate={() => {
                      setOpen(true)
                      setCurrentProduct(item?.id)
                    }}
                    onAddCart={addToCart}
                    onPopCart={popFromCart}
                  />
                </Grid>
              ))}
            </Grid>
          </QueryResult>
        ) : (
          <QueryResult
            data={dataCatId}
            error={errorCatId}
            loading={loadingCatId}
          >
            <Grid container rowSpacing={4} columnSpacing={2} sx={{ pb: 2 }}>
              {dataCatId?.getProductBusinessCategory.map(
                (item: ProductCardType) => (
                  <Grid item xs={6} sm={3} key={item.id}>
                    <CardProduct
                      theme={theme}
                      id={item?.id}
                      image={item?.image || tag_logo}
                      name={item?.name}
                      description={item?.description || ""}
                      prix={item?.prix}
                      stock={getStockCart(item?.id)}
                      prixTTC={item?.prixTTC}
                      add={checkCart(item?.id)}
                      onNavigate={() => {
                        setOpen(true)
                        setCurrentProduct(item?.id)
                      }}
                      onAddCart={addToCart}
                      onPopCart={popFromCart}
                    />
                  </Grid>
                )
              )}
            </Grid>
          </QueryResult>
        )}
        <Divider sx={{ mt: 4, mb: 4, background: theme && theme?.colorBtn }} />
        <Modal open={open} onClose={handleClose}>
          <Box>
            <ProductDetails
              theme={theme}
              id={currentProduct}
              add={checkCart(currentProduct)}
              onclose={handleClose}
              onAddCart={addToCart}
              onPopCart={popFromCart}
            />
          </Box>
        </Modal>
      </Container>
    </ContainerWithMenu>
  )
}
