import { makeStyles } from "@mui/styles"
import winningconcept from "../../../assets/Winning_concept.png"

export const useStyles = makeStyles(() => ({
  container: {
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    maxWidth: 768,
    width: "100%",
    margin: "auto"
  },
  title: {
    textAlign: "center",
    lineHeight: "1em"
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20
  },
  bottonContainer: {
    flexGrow: 1,
    width: "100%",
    background: `url(${winningconcept}) no-repeat center`,
    backgroundSize: "cover",
    display: "flex",
    alignItems: "end",
    justifyContent: "center"
  }
}))
