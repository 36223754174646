import { useMutation } from "@apollo/client"
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography
} from "@mui/material"
import { useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import Swal from "sweetalert2"
import { RESET_PASSWORD } from "../../../graphql"
import {
  ResetPassword as Reset,
  ResetPasswordVariables
} from "../../../graphql/user/types/ResetPassword"
import { LoginClientInput } from "../../../types"

export const ResetPassword = (): JSX.Element => {
  const { token } = useParams()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<LoginClientInput>()

  const [modifyExec, { loading }] = useMutation<Reset, ResetPasswordVariables>(
    RESET_PASSWORD
  )

  const modifyPassword = async (data: LoginClientInput) => {
    try {
      if (!token) return
      const message = await modifyExec({
        variables: {
          token,
          clientInput: data
        }
      })
      Swal.fire({
        title: message.data?.resetPassword,
        icon: "success"
      }).then(() => navigate("/login"))
    } catch (error) {
      const messageError = JSON.parse(JSON.stringify(error, null, 2)).message
      Swal.fire({
        title: messageError,
        icon: "error"
      })
    }
  }

  return (
    <Box sx={{ maxWidth: 600, mx: "auto" }}>
      <Typography variant="h4" textAlign="center">
        Votre nouveau mot de passe
      </Typography>
      <Box sx={{ width: 310, mx: "auto", my: 1 }}>
        <form onSubmit={handleSubmit(modifyPassword)}>
          <TextField
            fullWidth
            error={errors?.password && true}
            required
            label="Mot de passe"
            {...register("password", {
              required: {
                value: true,
                message: "Veuillez entrer votra nouveau mot de passe"
              }
            })}
            helperText={errors?.password?.message}
          />
          <Button
            disabled={loading}
            type="submit"
            variant="contained"
            sx={{ mt: 1 }}
          >
            {loading && <CircularProgress />}
            Envoyer
          </Button>
        </form>
      </Box>
    </Box>
  )
}
