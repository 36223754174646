import { useQuery } from "@apollo/client"
import { Typography } from "@mui/material"

import { GET_CLICK_COLLECT_ID } from "../../graphql"
import { useApplicationContext } from "../../hooks"
import { ThemeType } from "../../types/ThemeType"

type FormatProps = {
  theme?: ThemeType
  priceItem: string | number | null
  variant?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2"
    | "caption"
    | "button"
    | "overline"
    | "inherit"
    | undefined
  sx?: object
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: any
}

export const FormatPrice = ({ priceItem, variant, sx }: FormatProps) => {
  const { business } = useApplicationContext()
  const { data } = useQuery(GET_CLICK_COLLECT_ID, {
    variables: {
      businessId: Number(business?.id)
    }
  })
  const devis = data?.clickCollectById
  const decNum = Number(priceItem).toFixed(devis?.decimalNumber)
  const decimal =
    data?.clickCollectById?.decimalSeparator === "coma"
      ? String(decNum).replace(".", ",")
      : decNum
  let currencyUnit = "€"

  switch (devis?.currency) {
    case "dollars":
      currencyUnit = "$"
      break

    case "euro":
      currencyUnit = "€"
      break

    case "roupie":
      currencyUnit = "RS"
      break

    case "franc":
      currencyUnit = "CHF"
      break

    default:
      currencyUnit = "€"
      break
  }

  return (
    <Typography variant={variant} sx={sx}>
      {devis?.position === "left" ? (
        <>
          {currencyUnit} {decimal}
        </>
      ) : (
        <>
          {decimal} {currencyUnit}
        </>
      )}
    </Typography>
  )
}
