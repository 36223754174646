import { useEffect } from "react"
import { Container } from "@mui/material"
import { ContainerWithMenu, HeaderPage } from "../../components"
import { TableComponent } from "../../components/Table"
import { useBookingColumns } from "./useBookingColumns"
import { useReservation } from "../../hooks/useReservation"
import { useApplicationContext } from "../../hooks"
import { BaseTypography } from "../../components/Typography/BaseTypography"

export const Booking = () => {
  const { reservations, loading, loadReservation } = useReservation()
  const { business, businessLoading, theme } = useApplicationContext()
  const { columns } = useBookingColumns()
  useEffect(() => {
    loadReservation()
  }, [loadReservation])

  return (
    <ContainerWithMenu
      theme={theme}
      padding={1}
      loading={businessLoading}
      noTopNav
    >
      {business?.id && <HeaderPage theme={theme} business={business} />}
      <BaseTypography
        theme={theme}
        variant="h1"
        textAlign="center"
        sx={{ my: 2 }}
      >
        Mes réservations
      </BaseTypography>
      <Container>
        <TableComponent
          loading={loading}
          columns={columns}
          data={reservations || []}
        />
      </Container>
    </ContainerWithMenu>
  )
}
