import { Route, Routes } from "react-router-dom"
import { ListEventReservation } from "./components/ListEventReservation"
import { EventItem } from "./EventItem"

export const Events = () => {
  return (
    <Routes>
      <Route index element={<ListEventReservation />} />
      <Route path=":idEvents/*" element={<EventItem />} />
    </Routes>
  )
}
