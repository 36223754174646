/* eslint-disable radix */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"
import { Box, Typography } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { useStyles } from "./styles"
import { Loading } from "../../../components"
import { useQRCode } from "../../../hooks"

export const LoadGame = (): JSX.Element => {
  const { getQrcode } = useQRCode()
  const { id } = useParams()
  const navigate = useNavigate()
  const classes = useStyles()

  const loadGame = async () => {
    if (!id) return
    const infos = await getQrcode(parseInt(id as string))

    if (infos?.data?.getQrcodewithnotuser) {
      const data = infos.data.getQrcodewithnotuser
      localStorage.setItem("qrcode", JSON.stringify(data))
      navigate("spin")
    } else {
      navigate("not_found")
    }
  }

  useEffect(() => {
    if (id) {
      loadGame()
    }
  }, [id])
  return (
    <Box className={classes.container}>
      <Box>
        <Box sx={{ textAlign: "center" }}>
          <Typography gutterBottom variant="h3">
            Chargement des données...
          </Typography>
          <Typography>Nous sommes en train de vous préparer le Jeux</Typography>
        </Box>
        <Box sx={{ textAlign: "center", p: 3 }}>
          <Loading />
        </Box>
      </Box>
    </Box>
  )
}
