import { Box } from "@mui/material"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useParams } from "react-router-dom"
import { ContainerWithMenu } from "../../../components"
import { useApplicationContext } from "../../../hooks"
import { CardCommercant } from "../../Landing/components/CardCommercant"
import { useStyles } from "./styles"
import {
  GetQrcode_getQrcode as IQrcode,
  GetQrcode_getQrcode_template as Itemplate
} from "../../../graphql/qrcode"
import { BaseTypography } from "../../../components/Typography/BaseTypography"
import { FooterCommercant } from "../../Landing/components/FooterCommercant"

export const GameLost = (): JSX.Element => {
  const { id } = useParams()
  const { business, businessLoading, theme } = useApplicationContext()
  const [templateData, setTemplateData] = useState<Itemplate>()
  const classes = useStyles()
  const load = () => {
    const qrcode = localStorage.getItem("qrcode")

    if (qrcode && id) {
      const data = JSON.parse(qrcode) as IQrcode
      const template = data.template

      if (parseInt(id) === data.id) {
        setTemplateData(template || undefined)
      } else {
        console.log("ERROR")
      }
    } else {
      console.log("ERROR")
    }
  }
  useEffect(() => {
    load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <ContainerWithMenu
      theme={theme}
      padding={1}
      loading={businessLoading}
      noTopNav
    >
      <Helmet>
        <title>{business?.seoTitle || business?.businessName}</title>
        <meta
          name="description"
          content={business?.seoDescription || business?.description || ""}
        />
      </Helmet>
      <Box>
        {business?.id && <CardCommercant theme={theme} business={business} />}
        <Box className={classes.container}>
          <BaseTypography
            theme={theme}
            variant="h3"
            sx={{ textAlign: "center", fontWeight: 700, my: 3 }}
          >
            {templateData?.loseMessageTitle}
          </BaseTypography>
          {templateData?.losePictures ? (
            <img
              src={templateData?.losePictures || ""}
              alt={templateData?.loseMessageTitle || ""}
              style={{ marginBottom: 30, width: 200, height: 200 }}
            />
          ) : (
            ""
          )}
          <BaseTypography
            theme={theme}
            variant="h4"
            sx={{ textAlign: "center", fontWeight: 500 }}
          >
            {templateData?.loseMessage}
          </BaseTypography>
        </Box>
      </Box>
      {business?.id && <FooterCommercant theme={theme} business={business} />}
    </ContainerWithMenu>
  )
}
