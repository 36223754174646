import { Box, Card, CardContent, Typography } from "@mui/material"
import moment from "moment"
import { FC } from "react"
import { FormatPrice } from "../../../../components/FormatPriceBusiness"
import { useApplicationContext } from "../../../../hooks"

interface CustomedDescriptionProps {
  eventDetails?: any
  descritpion?: string
}

export const CustomedDescription: FC<CustomedDescriptionProps> = ({
  eventDetails,
  descritpion
}) => {
  const { theme } = useApplicationContext()
  return (
    <Card
      sx={{
        borderLeft: `3px solid ${theme?.colorBtn}`,
        boxShadow: "1px 5px 5px rgba(0, 0, 0, 0.02)",
        borderRadius: "5px",
        boxSizing: "border-box"
      }}
    >
      <CardContent sx={{ p: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
          <Box>
            {eventDetails?.title ? (
              <Box mb={1}>
                <Typography
                  variant="h2"
                  sx={{ fontSize: "20px", fontWeight: 200 }}
                >
                  {eventDetails?.title}
                </Typography>
              </Box>
            ) : (
              ""
            )}
            {eventDetails?.price ? (
              <Box sx={{ display: "flex" }}>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  Prix :{"  "}
                </Typography>
                <FormatPrice
                  sx={{
                    marginLeft: 1,
                    display: "inline-block",
                    width: "max-content"
                  }}
                  variant="body2"
                  priceItem={eventDetails?.price}
                />
              </Box>
            ) : (
              ""
            )}
          </Box>
          <Box>
            {eventDetails?.dateStart && eventDetails?.dateEnd && (
              <Typography component="p">
                {moment(eventDetails?.dateStart).format("DD MMMM YYYY")} - {""}
                {moment(eventDetails?.dateEnd).format("DD MMMM YYYY")}
              </Typography>
            )}
          </Box>
        </Box>
        {
          eventDetails && (
            <Typography variant="body2" sx={{ fontWeight: "bold", color: "black" }}>Nombre de place restant : {eventDetails?.guest - eventDetails?.guestCount}</Typography>
          )
        }
        <Typography variant="body2" sx={{ fontWeight: "bold", color: "black" }}>Description :</Typography>
        <Typography component="p">
          {eventDetails?.description || descritpion}
        </Typography>
      </CardContent>
    </Card>
  )
}
