import { useEffect, useState } from "react"
import { Typography, Box, Button, Grid, Container, Modal } from "@mui/material"
import { Helmet } from "react-helmet-async"
import ReactPlayer from "react-player"
import { useNavigate } from "react-router-dom"
import { useQuery } from "@apollo/client"
import {
  CardProduct,
  ContainerWithMenu,
  ProductDetails,
  QueryResult
} from "../../components"
import { useApplicationContext } from "../../hooks"
import { CartType, FileType } from "../../types"
import { CardCommercant } from "./components/CardCommercant"
import { GET_PRODUCT_BUSINESS } from "../../graphql"
import { FooterCommercant } from "./components/FooterCommercant"
import "../../style.css"
import { BaseBtn } from "../../components/Button/BaseBtn"
import tag_logo from "../../assets/finaltag_logo.png"
import ReactGA from "react-ga4"

type ProductCardType = {
  id: number
  name: string
  image: string
  businessId: number
  prix: number
  prixTTC: number | null
  prixPromotionnels: number | null
  description: string | null
  preparationTime: number | null
  status: boolean | null
}

export const Landing = () => {
  const navigate = useNavigate()
  const { business, businessLoading, theme } = useApplicationContext()
  const { cart, setCart } = useApplicationContext()
  const currentCart = cart || []
  const [open, setOpen] = useState(false)
  const [currentProduct, setCurrentProduct] = useState<number>(0)
  const { data, error, loading } = useQuery(GET_PRODUCT_BUSINESS, {
    variables: {
      businessId: business?.id,
      isClient: true
    },
    skip: !business?.id
  })

  const addToCart = (item: CartType) => {
    localStorage.setItem("mycart", JSON.stringify([...currentCart, item]))
    setCart([...currentCart, item])
  }

  const popFromCart = (popItem: number) => {
    const arrCart = currentCart.filter((item) => item.id !== popItem)
    localStorage.setItem("mycart", JSON.stringify(arrCart))
    setCart(arrCart)
  }

  const filterTypeVideo = business?.files.filter(
    (video) => video.fileType === FileType.VIDEO
  )

  const filterTypeVideoCount = filterTypeVideo?.slice(0, 3)

  const handleHoraireClick = () => {
    navigate("/tag/horaire")
  }
  const handleClickBooking = () => {
    navigate("/tag/reservation")
  }
  const handleClickProduct = () => {
    navigate("/tag/product")
  }

  const handleClickMedia = () => {
    navigate("/tag/video")
  }

  const checkCart = (idItem: number) => {
    if (cart && cart?.length > 0) {
      return cart?.some((item) => item.id === idItem)
    }

    return false
  }

  const getStockCart = (id: number) => {
    if (cart && cart?.length > 0) {
      const idxItem = cart?.map((item) => item.id).indexOf(id)

      if (idxItem >= 0) {
        return cart[idxItem].stock
      }

      return 0
    }

    return 0
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    const qrcode = localStorage.getItem("qrcode")
    if (!qrcode) {
      navigate(`/tag`)
    }
  }, [navigate])

  useEffect(() => {
    data?.productByBusiness
      .slice(0, 4)
      .forEach((item: any) => getStockCart(item.id))
  }, [cart, data])

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.hostname,
      title: window.location.hostname
    })
  }, [])

  return (
    <ContainerWithMenu
      theme={theme}
      noTopNav
      absolute
      loading={businessLoading}
    >
      <Helmet>
        <title>{business?.seoTitle || business?.businessName}</title>
        <meta
          name="description"
          content={business?.seoDescription || business?.description || ""}
        />
      </Helmet>
      <Box sx={{ p: 0 }}>
        {business?.id && <CardCommercant theme={theme} business={business} />}
        <Container>
          <Box sx={{ mt: 3 }}>
            <Box
              sx={{
                mb: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center"
              }}
            >
              {business?.activeBooking && (
                <BaseBtn
                  theme={theme}
                  className="buttonClass"
                  variant="outlined"
                  onClick={handleClickBooking}
                  sx={{
                    borderRadius: "5px",
                    borderWidth: "0px",
                    borderColor: "rgb(0, 0, 0)",
                    width: "300px",
                    height: "60px",
                    "&:hover": {
                      borderColor: "rgb(0, 0, 0)"
                    }
                  }}
                >
                  <span
                    style={{ width: "50px" }}
                    className="icon-Illustration-7"
                  ></span>
                  Reserver
                </BaseBtn>
              )}
            </Box>
            <Box
              sx={{
                mb: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center"
              }}
            >
              <BaseBtn
                theme={theme}
                variant="outlined"
                onClick={handleClickProduct}
                sx={{
                  borderRadius: "5px",
                  borderWidth: "0px",
                  borderColor: "rgb(0, 0, 0)",
                  width: "300px",
                  height: "60px",
                  "&:hover": {
                    borderColor: "rgb(0, 0, 0)"
                  }
                }}
              >
                <span
                  style={{ width: "50px" }}
                  className="icon-Illustration-12"
                ></span>
                Commander
              </BaseBtn>
            </Box>
            <Box
              sx={{
                mb: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center"
              }}
            >
              <BaseBtn
                theme={theme}
                variant="outlined"
                onClick={handleHoraireClick}
                sx={{
                  borderRadius: "5px",
                  borderWidth: "0px",
                  borderColor: "rgb(0, 0, 0)",
                  width: "300px",
                  height: "60px",
                  "&:hover": {
                    borderColor: "rgb(0, 0, 0)"
                  }
                }}
              >
                <span
                  style={{ width: "50px" }}
                  className="icon-Illustration-9"
                ></span>
                Horaires
              </BaseBtn>
            </Box>
          </Box>

          <QueryResult data={data} error={error} loading={loading}>
            <>
              <Grid
                sx={{ mt: 4 }}
                container
                rowSpacing={4}
                columnSpacing={2}
                justifyContent="center"
              >
                {data?.productByBusiness
                  .slice(0, 4)
                  .map((item: ProductCardType) => (
                    <Grid item xs={6} sm={3} key={item.id}>
                      <CardProduct
                        id={item?.id}
                        image={item?.image || tag_logo}
                        theme={theme}
                        name={item?.name}
                        description={item?.description || ""}
                        prix={item?.prix}
                        prixTTC={item?.prixTTC}
                        stock={getStockCart(item?.id)}
                        add={checkCart(item?.id)}
                        onNavigate={() => {
                          setOpen(true)
                          setCurrentProduct(item?.id)
                        }}
                        onAddCart={addToCart}
                        onPopCart={popFromCart}
                      />
                    </Grid>
                  ))}
              </Grid>
            </>
          </QueryResult>

          <Box sx={{ py: 2 }}>
            {filterTypeVideo && filterTypeVideo.length >= 1 ? (
              <>
                <Typography variant="subtitle1">Media</Typography>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      borderRadius: "5px"
                    }}
                  >
                    {filterTypeVideoCount?.map((video) => {
                      return (
                        <Box sx={{ p: 2 }} key={video.id}>
                          <ReactPlayer
                            controls
                            url={video.url}
                            height={200}
                            width={360}
                          />
                        </Box>
                      )
                    })}
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center"
                      }}
                    >
                      {filterTypeVideo?.length > 3 ? (
                        <Button variant="outlined" onClick={handleClickMedia}>
                          voir tout
                        </Button>
                      ) : (
                        ""
                      )}
                    </Box>
                  </Box>
                </Grid>
              </>
            ) : (
              ""
            )}
          </Box>
          {business?.id && (
            <FooterCommercant theme={theme} business={business} />
          )}
        </Container>
      </Box>
      <Modal open={open} onClose={handleClose}>
        <Box>
          <ProductDetails
            theme={theme}
            id={currentProduct}
            add={checkCart(currentProduct)}
            onclose={handleClose}
            onAddCart={addToCart}
            onPopCart={popFromCart}
          />
        </Box>
      </Modal>
    </ContainerWithMenu>
  )
}
