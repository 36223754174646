import {
  Box,
  Card,
  CardContent,
  Typography
} from "@mui/material"
import moment from "moment"
import { ChangeEvent, FC, useState } from "react"
import PhoneInput from "react-phone-input-2"
import { useNavigate } from "react-router-dom"
import { BaseBtn } from "../../../../components/Button/BaseBtn"
import { useApplicationContext } from "../../../../hooks"

interface CustomDateEventProps {
  listDate: Date[]
  hour: string
  onChange: (e: ChangeEvent<HTMLInputElement>, value?: string) => void
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const CustomDateEvent: FC<CustomDateEventProps> = ({
  listDate,
  hour,
  onChange,
  onClick
}) => {
  const { theme } = useApplicationContext()
  const navigate = useNavigate()
  const getPhone = (value: string, e: ChangeEvent<HTMLInputElement>) => {
    onChange(e, value)
  }
  const [selected, isSelected] = useState<boolean>(false)
  const [error, isError] = useState<boolean>(true)
  const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    const { value } = e.target
    const regex = /^(\+?\d{1,3}|\d{1,4})$/
    if (value === "+" || regex.test(value) || !value) {
      isError(true)
      isSelected(true)
    } else {
      isError(false)
      isSelected(false)
    }
  }
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!error) {
      onClick(e)
    } else {
      isSelected(true)
    }
  }
  return (
    <>
      <Card sx={{ mb: 2, overflow: "visible" }}>
        <CardContent sx={{ p: 2 }}>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
              L'évènement se tiendra le:
            </Typography>
            <Typography
              variant="h4"
              sx={{ textAlign: "center", fontSize: "16px" }}
            >
              {moment(listDate[0]).format("DD MMMM YYYY")} au{" "}
              {moment(listDate[listDate.length - 1]).format("DD MMMM YYYY")}
            </Typography>
            <Typography
              variant="h4"
              sx={{ textAlign: "center", fontSize: "16px" }}
            >
              {hour}
            </Typography>
          </Box>
          <Box my={2}>
            <Typography variant="subtitle1" sx={{ textAlign: "center" }} mb={2}>
              Merci de laisser un contact rapide dans le cas d'un changement
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Box pr={1}>
                phone<span style={{ color: "red" }}>*</span>
              </Box>
              <PhoneInput
                enableSearch
                containerStyle={{
                  width: "auto"
                }}
                inputProps={{
                  require: true
                }}
                specialLabel=""
                country="fr"
                preferredCountries={["fr"]}
                inputStyle={{
                  backgroundColor: "#ffffff",
                  borderRadius: 1,
                  borderColor: error && selected ? "red" : undefined,
                  width: "250px",
                  height: "40px"
                }}
                onChange={getPhone}
                onBlur={handleBlur}
              />
            </Box>
            {error && selected ? (
              <Typography sx={{ color: "red", textAlign: "center" }}>
                ce champ est requis
              </Typography>
            ) : null}
          </Box>
        </CardContent>
      </Card>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          textAlign: "center"
        }}
      >
        <BaseBtn
          theme={theme}
          variant="contained"
          onClick={(e) => navigate(-1)}
        >
          précedent
        </BaseBtn>
        <BaseBtn theme={theme} variant="contained" onClick={handleClick}>
          réserver
        </BaseBtn>
      </Box>
    </>
  )
}
