import { Navigate } from "react-router-dom"
import { useApplicationContext, UserAuthStateEnum } from "../../hooks"

export const NotFound = (): JSX.Element => {
  const { userAuthStatus } = useApplicationContext()

  if (userAuthStatus === UserAuthStateEnum.UNAUTHENTICATED) {
    return <Navigate to="/login" />
  }

  return <Navigate to="/tag" />
}
