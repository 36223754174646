import { AccessTime, CalendarMonth, Groups } from "@mui/icons-material"

export interface statusBar {
  id: number
  icon: JSX.Element
  path: string
  status: boolean
}

export const dataBar: statusBar[] = [
  {
    id: 1,
    icon: <Groups sx={{ width: 30, height: 30 }} />,
    path: "",
    status: false
  },
  {
    id: 2,
    icon: <CalendarMonth sx={{ width: 30, height: 30 }} />,
    path: "date",
    status: false
  },
  {
    id: 3,
    icon: <AccessTime sx={{ width: 30, height: 30 }} />,
    path: "hours",
    status: false
  }
]
