
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Container,
  Grid,
  Typography
} from "@mui/material"
import moment from "moment"
import { SyntheticEvent, useEffect, useState } from "react"
import { ContainerWithMenu, HeaderPage, QueryResult } from "../../components"
import { useApplicationContext } from "../../hooks"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { BaseTypography } from "../../components/Typography/BaseTypography"
import { BaseBtn } from "../../components/Button/BaseBtn"
import { useNavigate } from "react-router-dom"

export const BookingWizitag = () => {
  const navigate = useNavigate()
  const [expanded, setExpanded] = useState<number | false>(false)
  const { business, businessLoading, theme } = useApplicationContext()
  const Status = (status: string) => {
    switch (status) {
      case "PENDING":
        return <Chip color="warning" label="En cours" />
      case "VALID":
        return <Chip color="success" label="Validé" />
      case "DONE":
        return <Chip color="primary" label="Fini" />
      case "REJECT":
        return <Chip color="error" label="Réjété" />

      default:
        return null
    }
  }
  const handleChange =
    (panel: number, item: any) =>
    (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }


  return (
    <ContainerWithMenu
      theme={theme}
      noTopNav
      padding={1}
      loading={businessLoading}
    >
      {business?.id && <HeaderPage business={business} theme={theme} />}
      <BaseTypography
        theme={theme}
        variant="h1"
        textAlign="center"
        sx={{ mb: 4, fontWeight: 400 }}
      >
        Liste des reservations sur WIZITAG </BaseTypography>
      <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
        <BaseBtn
          theme={theme}
          variant="outlined"
          sx={{
            borderRadius: "5px",
            borderWidth: "0px",
            borderColor: "rgb(0, 0, 0)",
            width: "300px",
            height: "60px",
            "&:hover": {
              borderColor: "rgb(0, 0, 0)"
            }
          }}
          onClick={() => navigate("../booking-list")}
        >
          Mes reservations sur {business?.businessName}
        </BaseBtn>
      </Box>
      <Container>
      </Container>
    </ContainerWithMenu>
  )
}
