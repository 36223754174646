import { Box, Card, Grid } from "@mui/material"
import { FC } from "react"
import { useApplicationContext } from "../../../../hooks"
import { statusBar } from "../dataBar"

interface ProgressBarReservationProps {
  data: statusBar[]
}

export const ProgressBarReservation: FC<ProgressBarReservationProps> = ({
  data
}) => {
  const { theme } = useApplicationContext()
  const pathUrl = window.location.pathname
  const arrayPath = pathUrl.split("/")
  let detection = arrayPath[arrayPath.length - 1]

  return (
    <Card sx={{ my: 2, height: 70 }}>
      <Grid container sx={{ height: "100%" }}>
        {data.map((value, index) => {
          if (detection !== "date" && detection !== "hours") {
            detection = ""
          }
          if (detection === value.path) {
            value.status = true
            for (let i = index + 1; i < data.length; i++) {
              data[i].status = false
            }
            for (let j = index - 1; j >= 0; j--) {
              data[j].status = true
            }
          }
          return (
            <Grid
              item
              xs={12 / data.length}
              key={value.id}
              sx={{ height: "100%" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  "& svg": {
                    color: value.status ? theme?.colorBtn : "black"
                  },
                  borderBottom: value.status
                    ? `5px solid ${theme?.colorBtn}`
                    : null
                }}
              >
                {value.icon}
              </Box>
            </Grid>
          )
        })}
      </Grid>
    </Card>
  )
}
