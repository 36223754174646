export const getCurrentLoction = (): string | undefined => {
  if (typeof window !== undefined) {
    const {
      location: { host }
    } = window
    const formatted = host.replace("www.", "").replace("/", "")
    const noLocal = formatted.replace("localhost:3050", "wizitag.com")
    return noLocal
  }
  return undefined
}
