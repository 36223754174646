import { styled, Typography, TypographyProps } from "@mui/material"
import { FC } from "react"

import { ThemeType } from "../../types/ThemeType"

interface StyledTypographyProps extends TypographyProps {
  colorText?: string
  fontSize?: string
  police?: string
}

interface BaseTypographyProps {
  theme?: ThemeType
  textAlign?: any
  fontWeight?: any
  variant?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2"
    | "caption"
    | "inherit"
    | "button"
    | "overline"
  sx?: any
  children: React.ReactNode
}

const StyledTypography = styled(Typography, {
  shouldForwardProp: (props) =>
    props !== "fontSize" && props !== "police" && props !== "colorText"
})<StyledTypographyProps>(({ fontSize, police, colorText }) => ({
  fontSize: fontSize,
  fontFamily: police,
  color: colorText
}))

export const BaseTypography: FC<BaseTypographyProps> = ({
  variant,
  theme,
  textAlign,
  fontWeight,
  sx,
  children
}) => {
  return (
    <StyledTypography
      variant={variant}
      sx={sx}
      fontWeight={fontWeight}
      textAlign={textAlign}
      fontSize={theme?.fontSize}
      colorText={theme?.colorText}
      police={theme?.police}
    >
      {children}
    </StyledTypography>
  )
}
