import { HeaderPage } from "../../components"
import { ContainerWithMenu } from "../../components"
import { useApplicationContext } from "../../hooks"
import { BaseTypography } from "../../components/Typography/BaseTypography"
import { Box } from "@mui/system"
import { Card, CardContent } from "@mui/material"

export const Subscribe = () => {
  const { business, businessLoading, theme } = useApplicationContext()
  return (
    <ContainerWithMenu
      theme={theme}
      noTopNav
      loading={businessLoading}
    >
      {business?.id && <HeaderPage business={business} theme={theme} />}
      <BaseTypography
        theme={theme}
        variant="h1"
        textAlign="center"
        sx={{ mb: 4, fontWeight: 400 }}
      >
        Vos informations
      </BaseTypography>
      <Box>
        <Card>
          <CardContent>
            TEst
          </CardContent>
        </Card>
      </Box>
    </ContainerWithMenu>
  )
}
