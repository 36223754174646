import { makeStyles } from "@mui/styles"
import mask from "../../../assets/Mask.png"

export const useStyles = makeStyles({
  bg: {
    background: `url(${mask})`
  },
  container: {
    minHeight: "100vh",
    padding: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 800,
    margin: "auto"
  },
  welcome: {},
  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20
  },
  connecter: {
    display: "flex",
    alignItems: "center"
  }
})
