import { ReactNode } from "react"
import { ThemeType } from "../../types/ThemeType"
import { BaseBtn } from "./BaseBtn"

interface ButtonShopProps {
  theme?: ThemeType
  children: ReactNode
  onClick: () => void
}
export const ButtonShop = ({
  children,
  theme,
  onClick
}: ButtonShopProps): JSX.Element => {
  return (
    <BaseBtn
      theme={theme}
      sx={{ p: 1, minWidth: 36 }}
      variant="contained"
      children={children}
      onClick={onClick}
    />
  )
}
