import { gql } from "@apollo/client"

export const TABLES = gql`
  query Tables($businessId: Float!) {
    tables(businessId: $businessId) {
      id
      photo
      name
      place
      description
      disponible
      createdAt
    }
  }
`
