/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { Close } from "@mui/icons-material"
import {
  Box,
  Button,
  Grid,
  IconButton,
  styled,
  Typography
} from "@mui/material"
import { useState } from "react"
import { useLocation } from "react-router-dom"
import { useApplicationContext } from "../../hooks"
import { SourceEnumType } from "../../types"
import { FacebookButton, GoogleButton } from "../Button"
import { LoginForm } from "../forms"
import { InscriptionEmailForm } from "../InscriptionEmailForm"
import { ModalOverlay } from "../modals"

type LoginProps = {
  open: boolean
  onClose: () => void
  onLogStatus: (item: boolean) => void
}

export const LoginModal = ({ open, onClose, onLogStatus }: LoginProps) => {
  const { signIn, dispatchSnack, business, theme } = useApplicationContext()
  const [openSignin, setOpenSignin] = useState(false)
  const location = useLocation()

  const handleClick = (): void => {
    setOpenSignin(true)
  }
  const StyledButton = styled(Button)(() => ({
    backgroundColor: "white",
    color: "inherit",
    borderRadius: "50px !important",
    height: 40,
    marginTop: 12,
    textTransform: "none"
  }))

  const handleLogin = async (data: any): Promise<void> => {
    const login = await signIn(data)
    if (login.success) {
      dispatchSnack({
        open: true,
        severity: "success",
        message: "Authentification réussi !"
      })
      onLogStatus(true)
      window.location.reload()
    }
    if (login.error) {
      dispatchSnack({
        open: true,
        severity: "error",
        message: "Action refusée . Veuillez rééssayer !"
      })
      onLogStatus(false)
    }

    onClose()
  }

  // Those function is used only to maintain stability

  const handleLoginGoogle = async (): Promise<void> => {
    // nothing to do here
  }
  const handleLoginFacebook = async (): Promise<void> => {
    // nothing to do here
  }
  const handleGoogleError = (): void => {
    // nothing to do here
  }

  const onClickBtnGoogle = () => {
    const data = {
      name: business?.businessName,
      logo: business?.logo,
      address: business?.address,
      photo: business?.coverPhoto
    }
    localStorage.setItem("source", SourceEnumType.GOOGLE)
    const businessData = encodeURIComponent(JSON.stringify(data))
    window.location.replace(
      `https://login.wizitag.com/callback/google/${business?.url}/${businessData}`
    )
  }
  const onClickBtnFb = () => {
    const data = {
      name: business?.businessName,
      logo: business?.logo,
      address: business?.address,
      photo: business?.coverPhoto
    }
    localStorage.setItem("source", SourceEnumType.FACEBOOK)
    const businessData = encodeURIComponent(JSON.stringify(data))
    window.location.replace(
      `https://login.wizitag.com/callback/facebook/${business?.url}/${businessData}`
    )
  }

  localStorage.setItem(
    "redirection_path",
    JSON.stringify({ path: location.pathname, auth: true })
  )

  return (
    <ModalOverlay open={open} setOpen={onClose} withoutButton>
      <Box
        sx={{
          position: "absolute" as const,
          top: 10,
          right: 10,
          backgroundColor: theme?.colorCardProduct,
          borderRadius: "50%"
        }}
      >
        <Button
          sx={{ minWidth: 36, p: 1, border: `1px solid ${theme?.colorBtn}` }}
          variant="outlined"
          size="medium"
          onClick={onClose}
        >
          <Close sx={{ color: theme?.colorBtn }} />
        </Button>
      </Box>
      <Box textAlign="center" marginY={3}>
        <Typography variant="h4" component="div">
          Pour continuer cette action, veuillez vous identifier, merci.
        </Typography>
      </Box>
      {openSignin ? (
        <Box sx={{ py: 2 }}>
          <InscriptionEmailForm onClickHasAccount={handleClick} />
        </Box>
      ) : (
        <Grid container spacing={3} justifyContent="center">
          <Grid item md={6}>
            <Box sx={{ py: 2 }}>
              <Box sx={{ mb: 2 }}>
                <FacebookButton
                  onSigninFb={handleLoginFacebook}
                  onClickBtn={onClickBtnFb}
                />
              </Box>
              <Box>
                <GoogleButton
                  onSigninSuccess={handleLoginGoogle}
                  onSigninError={handleGoogleError}
                  onClickBtn={onClickBtnGoogle}
                />
              </Box>
              <Box>
                <StyledButton
                  fullWidth
                  variant="outlined"
                  color="inherit"
                  onClick={handleClick}
                >
                  S&apos;inscrire avec Email
                </StyledButton>
              </Box>
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box sx={{ py: 2 }}>
              <LoginForm onLogin={handleLogin} />
            </Box>
          </Grid>
        </Grid>
      )}
    </ModalOverlay>
  )
}
