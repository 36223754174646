import { ApolloError } from "@apollo/client"
import { LoginClientInput } from "../types"

export type AuthStatusType = {
  success: string
  error: string
}

export type StatusFetch = {
  success?: string
  error?: string
}
export enum UserAuthStateEnum {
  WAITING = "waiting",
  AUTHENTICATED = "authenticated",
  UNAUTHENTICATED = "unauthenticated"
}

export enum MenuStatusEnum {
  ALL = "all",
  CONNECTED = "connected",
  NOTCONNECTED = "notConnected",
  NOTALLOWED = "notAllowed"
}

export interface UseApplicationContextType {
  userAuthStatus: UserAuthStateEnum
  loginLoading?: boolean
  loginError?: ApolloError
  signIn: (dataInput: LoginClientInput) => Promise<StatusFetch>
  // new app
  businessLoading?: boolean
  logout: () => void
}
