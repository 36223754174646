import { gql } from "@apollo/client"

export const LISTEVENT = gql`
query ListEventParty($businessId: Float!, $active: Boolean) {
  listEventParty(businessId: $businessId, active: $active) {
      title
      status
      img
      id
      hourStart
      hourEnd
      description
      dateStart
      dateEnd
      businessId
      createdAt
      price
    }
  }
`

export const DETAILSEVENT = gql`
  query DetailEventParty($businessId: Float!, $detailEventPartyId: Float!) {
    detailEventParty(businessId: $businessId, id: $detailEventPartyId) {
      businessId
      createdAt
      dateEnd
      dateStart
      description
      hourEnd
      hourStart
      id
      img
      status
      title
      price
      guestCount
      guest
    }
  }
`
